// AR设备地址池
export default {
  title: 'ARデバイスアドレスプールテーブル',
  arType: 'タイプ',
  code: 'ARコード',
  notes: '備考',
  nameOrNotes: 'ARコード/備考',
  eqCode: 'デバイスコード',
  eqCodeList: 'デバイスコード表',
  addArPool: 'ARデバイスアドレスプールに追加',
  code64: 'コード(64ビット)',
  code32: 'コード(32ビット)',
  writeEqCode: 'デバイスコードを入力してください',
}
