// 账单查询
export default {
  title: '密钥账单查询表',
  typeHolder: '请选择资源类型',
  createTime: '使用时间',
  type: '资源类型',
  recordNumber: '使用量',
  surplusNumber: '剩余数量',
  userAccount: '使用账号',
  secretKeyType: '密钥类型',
  content: '分配内容',
  code: '密钥激活码',
  expirationDate: '有效期',
  status: '使用状态',
  assignAccount: '分配账号',
  assignAccountHolder: '请选择分配账号',
}
