export default {
    affiliateSignup:'填写注册信息',
    selectproductmodule:'选择产品模块',
    selectserviceprovider:'选择服务商',
    authorizationDown:'授权书模板下载',
    licenseDown:'*请将营业执照盖章授权后再上传',
    goback:'返回',
    pleaseBrief:'请简述',
    pleaseAddress: '请填写详细',
    pleaseFillin:'请填写',
    pleaseCreate:'请创建',
    pleaseConfirm:'请确认',
    company: '企业名称',
    companycode: '统一社会信用代码',
    industry: '所属行业',
    customIndustry: '自定义行业',
    business: '企业业务',
    address: '地址',
    province: '省',
    city: '市',
    area: '区',
    contacts: '联系人',
    contactUs: '联系方式',
    pleaseMobile: '联系手机号',
    mobile: '手机号码',
    mobileBind:'请绑定手机号',
    bindingmobile:'绑定手机号码',
    email: '电子邮箱',
    popemail:'邮箱地址',
    sms: '认证方式',
    code: '短信认证',
    popcode: '验证码',
    contactadministrator: '请联系管理员获取手机',
    emailCheck:'邮箱认证',
    emailCode:'邮箱认证码',
    getCode:'获取验证码',
    loginName: '登录账号',
    loginPass: '登录密码',
    confirmpass: '确认密码',
    manageName: '管理员姓名',
    passWord: '密码',
    newPass:'新密码',
    confirmCheck:"两次密码不一致",
    pleaseinputpass:"请输入确认密码",
    billingtype: '开票类型',
    invoicingname: '开票企业开户行',
    account: '企业开户账号',
    licenseUpload: '营业执照盖章上传',
    businesslicense: '营业执照',
    authorizationUpload: '授权书上传',
    letterofauthorization: '授权书',
    serviceproviderName: '服务商名称',
    serviceproviderCode: '服务商代码',
    remoteGuidanceProductModule:'远程指导产品模块',
    knowledgeBaseProductModule: '知识库产品模块',
    workOrderProductModule: '工单产品模块',
    workflowProductModule: '工作流产品模块',
    next:'下一步',
    previous:'上一步',
    cancel:'取消',
    referto:'提交',
    Agreeandregister:'同意并注册',
    stepTitle:'TMVA现有以下种类产品，请选择需要产品（可多选）：',
    agreeTextLeft:'创建网站账号的同时：我同意遵守',
    agreeTextCenter:'TMVA用户协议',
    agreeTextRight: '- 愿意接收相关来自TMVA的用户及服务邮件',
    regSuccessTxt:'感谢您对我司的信任！我们尽快安排服务商与您联系，请注意手机电话',
    selectTmva:'TMVA产品至少选一个',
    agreeTxtID:'请选中TMVA用户协议',
    authorUploadPic:'营业执照大小限定5MB'
}
