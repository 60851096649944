// 消息管理
export default {
  pageTitle: 'メッセージ管理テーブル',
  createTime: 'プッシュ時間',
  target: 'ターゲット',
  userAccountIdSet: 'プッシュ用のアカウント',
  title: 'メッセージタイトル',
  content: 'メッセージ内容',
  seeAccount: 'プッシュユーザーをチェック',
  rollbackStatus: '取り消したかどうか',
  searchTitle: 'メッセージタイトル',
}
