import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { getSessionStorage } from './../utils'

Vue.use(VueRouter)
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base:  process.env.BASE_URL,
    routes,
    scrollBehavior: () => ({ y: 0 }),
  })

const router = createRouter()
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}


// 白名单
const whiteList = ['/login', '/pcEquipmentManage', '/inviteEquipment', '/reg', '/forgot', '/account-reg', '/secret-key']

// 登录拦截
router.beforeEach((to, from, next) => {
  let operator = getSessionStorage('operator')
  // 已登录
  if (operator) {
    if (to.path === '/login') {
      next({ path: '/' })
      return
    }
    next()
    return
  }
  // 未登录
  if (whiteList.indexOf(to.path) >= 0) {
    next()
    return
  }
  next({ path: '/login' })
})

export default router
