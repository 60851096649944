export default {
    title: '用户权限',
    name: '姓名',
    department: '部门',
    account: '账号',
    productKey: '产品密钥',
    thermalImagingFunction: '热成像功能',
    remoteControlFunction: '远程控制功能',
    sensorAcquisitionFunction: '传感器采集功能',
    endoscopicFunction: '内窥镜功能',
    contactVisibleRange: '联系人可见范围',
    addContact: '添加联系人',
}
