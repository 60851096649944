// 账号管理
export default {
  title: '管理者アカウントテーブル',
  title_user: '会社ユーザーアカウントテーブル',
  title_platform: 'プラットフォームユーザーアカウントテーブル',
  exportUsers: '会社ユーザーアカウント',
  exportPlatfromUsers: 'プラットフォームユーザーアカウント',
  name: '名前',
  mobile: 'フォンの電話番号',
  loginName: 'ログイン用の名前',
  password: 'パスワード',
  type: 'アカウントタイプ',
  company: '所属会社',
  nickname: 'ID',
  accountGroup: '所属部署',
  subscribeType: '購読モード',
  serviceProvide: 'オンラインサポート担当者かどうか',
  scope: '可視範囲',
  role: 'ロール',
  canAddOutsideUserAccount: '外部連絡先を追加できるかどうか',
  shouldRemoveOutsideUserAccount: '外部連絡先を全部削除するかどうか',
  nameOrNotes: 'ID/名前/フォンの電話番号/ニックネーム',
  renew: '購読',
  renewConfirm: '購読しますか',
  renewSuccess: '購読しました',
  changeTips: 'このアカウントは当社のプラットフォームアカウントではなく、操作できません',
  track: '軌跡図',
  companyInfo: '会社情報',
  renewNumber: '購読年数',
  normal: '正常',
  pastDue: '期限切れ',
  inteirm: 'もうすぐに期限切れ',
  nextSubscribeTime: '有効期限',
}
