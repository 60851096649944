var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mainLeftNavs",class:{ isFold: _vm.$store.state.isFold }},[_c('div',{staticClass:"logoWrap",on:{"click":_vm.getMain}},[_c('div',{staticClass:"iconImg logo",style:({
        backgroundImage:
          'url(' +
          (_vm.customCompanyInfo
            ? _vm.customCompanyInfo.logo.fileHost +
              '/' +
              _vm.customCompanyInfo.logo.objectName
            : require('./../img/logo.png')) +
          ')',
      })})]),_c('div',{staticClass:"leftMenus"},[_c('el-menu',{attrs:{"background-color":"transparent","text-color":"#676971","active-text-color":"#ffffff","default-active":_vm.activeTag,"collapse":_vm.$store.state.isFold},on:{"select":_vm.select}},[_vm._l((_vm.permission),function(item){return [(_vm.$util.getLocalStorage('apiprefix') === 'tmva')?[(
              item.children &&
              item.children.length &&
              item.children[0].permissionType === 'TWO_LIST'
            )?[_c('el-submenu',{key:item.permissionEnum,attrs:{"index":`${item.permissionEnum}`}},[_c('template',{slot:"title"},[_c('i',{class:`iconfont ${item.icon || 'icon-set'}`}),_c('span',[_vm._v(_vm._s(item.name))])]),_c('el-menu-item-group',_vm._l((item.children),function(list){return _c('el-menu-item',{key:list.permissionEnum,attrs:{"index":`${list.permissionEnum}`}},[_vm._v(_vm._s(list.name))])}),1)],2)]:[_c('el-menu-item',{key:item.permissionEnum,attrs:{"index":`${item.permissionEnum}`}},[_c('i',{class:`iconfont ${item.icon || 'icon-set'}`}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.name))])])]]:_vm._e(),(_vm.$util.getLocalStorage('apiprefix') === 'core')?[(
              item.children &&
              item.children.length &&
              item.children[0].permissionType === 'TWO_LIST'
            )?[_c('el-submenu',{key:item.permissionEnum,attrs:{"index":`${item.permissionEnum}`}},[_c('template',{slot:"title"},[_c('i',{class:`iconfont ${item.icon || 'icon-set'}`}),_c('span',[_vm._v(_vm._s(item.name))])]),_c('el-menu-item-group',_vm._l((item.children),function(list){return _c('el-menu-item',{key:list.permissionEnum,attrs:{"index":`${list.permissionEnum}`}},[_vm._v(_vm._s(list.name))])}),1)],2)]:[_c('el-menu-item',{key:item.permissionEnum,attrs:{"index":`${item.permissionEnum}`}},[_c('i',{class:`iconfont ${item.icon || 'icon-set'}`}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.name))])])]]:_vm._e(),(_vm.$util.getLocalStorage('apiprefix') === 'workFlow')?[(_vm.isShow(item))?[(
                item.children &&
                item.children.length &&
                item.children[0].permissionType === 'TWO_LIST'
              )?[_c('el-submenu',{key:item.permissionEnum,attrs:{"index":`${item.permissionEnum}`}},[_c('template',{slot:"title"},[_c('i',{class:`iconfont ${item.icon || 'icon-set'}`}),_c('span',[_vm._v(_vm._s(item.name))])]),_c('el-menu-item-group',_vm._l((item.children),function(list){return _c('el-menu-item',{key:list.permissionEnum,attrs:{"index":`${list.permissionEnum}`}},[_vm._v(_vm._s(list.name))])}),1)],2)]:[_c('el-menu-item',{key:item.permissionEnum,attrs:{"index":`${item.permissionEnum}`}},[_c('i',{class:`iconfont ${item.icon || 'icon-set'}`}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.name))])])]]:_vm._e()]:_vm._e(),(_vm.$util.getLocalStorage('apiprefix') === 'idc')?[(
              item.children &&
              item.children.length &&
              item.children[0].permissionType === 'TWO_LIST'
            )?[_c('el-submenu',{key:item.permissionEnum,attrs:{"index":`${item.permissionEnum}`}},[_c('template',{slot:"title"},[_c('i',{class:`iconfont ${item.icon || 'icon-set'}`}),_c('span',[_vm._v(_vm._s(item.name))])]),_c('el-menu-item-group',_vm._l((item.children),function(list){return _c('el-menu-item',{key:list.permissionEnum,attrs:{"index":`${list.permissionEnum}`}},[_vm._v(_vm._s(list.name))])}),1)],2)]:[_c('el-menu-item',{key:item.permissionEnum,attrs:{"index":`${item.permissionEnum}`}},[_c('i',{class:`iconfont ${item.icon || 'icon-set'}`}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.name))])])]]:_vm._e(),(_vm.$util.getLocalStorage('apiprefix') === 'device')?[(
              item.children &&
              item.children.length &&
              item.children[0].permissionType === 'TWO_LIST'
            )?[_c('el-submenu',{key:item.permissionEnum,attrs:{"index":`${item.permissionEnum}`}},[_c('template',{slot:"title"},[_c('i',{class:`iconfont ${item.icon || 'icon-set'}`}),_c('span',[_vm._v(_vm._s(item.name))])]),_c('el-menu-item-group',_vm._l((item.children),function(list){return _c('el-menu-item',{key:list.permissionEnum,attrs:{"index":`${list.permissionEnum}`}},[_vm._v(_vm._s(list.name))])}),1)],2)]:[_c('el-menu-item',{key:item.permissionEnum,attrs:{"index":`${item.permissionEnum}`}},[_c('i',{class:`iconfont ${item.icon || 'icon-set'}`}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.name))])])]]:_vm._e()]})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }