// 业务枚举
export default {
  // enableVale-状态
  NORMAL: 'Normal',
  DISABLE: 'Disable',
  DELETE: 'Delete',
  // yn-是非
  YES: 'Yes',
  NO: 'No',
  // ContextFrom-会话来源
  REQUEST: 'External request',
  SCHEDULE: 'Timing task',
  WEB_SOCKET: 'WebSocket',
  // level-等级
  ONE: 'Level 1',
  TWO: 'Level 2',
  THREE: 'Level 3',
  FOUR: 'Level 4',
  FIVE: 'Level 5',
  SIX: 'Level 6',
  SEVEN: 'Level 7',
  EIGHT: 'Level 8',
  NINE: 'Level 9',
  TEN: 'Level 10',
  // DictTypeEnum-字典类型
  ADDRESS: 'Address',
  COMPANY_INDUSTRY: 'Industry',
  VERSION_CATEGORY: 'Version classification',
  CLIENT_LOG_TYPE: 'Terminal Log Type',
  DOCUMENT_TYPE: 'Document type',
  // permissionType-功能类型
  ROOT: 'Root',
  ONE_LIST: 'Level 1 function',
  TWO_LIST: 'Level 2 function',
  BUTTON: 'Button function',
  // SubjectTypeEnum-主体类型
  PC_MANAGE: 'System management',
  // RoleTypeEnum-角色类型
  COMPANY: 'Company administrator',
  SUB: 'Sub administrator',
  // AuthType-鉴权类型
  ANONYMOUS: 'Anonymous access',
  LOGIN: 'Access after login',
  AUTHORIZED: 'Post-authorization access',
  // FileFrom-文件来源
  WEB_ADMIN: 'Management background',
  // LanguageEnum-语言类型
  en: 'English',
  zhCN: 'Chinese',
  // invoiceType-发票类型
  GENERAL: 'Normal invoice',
  VAT: 'VAT invoice',
  // ServiceResourceAssignStatusEnum-授权状态
  ASSIGNED: 'Authorized',
  ROLLBACK: 'Revoked',
  ASSIGNED_1: 'Assigned',
  ROLLBACK_1:'Revoked',
  // ServiceResourceTypeEnum-资源类型
  ACCOUNT_NUMBER: 'Number of accounts (per)',
  ACCOUNT_SUBSCRIPTIONS_NUMBER: 'Number of account subscriptions (per)',
  EQUIPMENT_NUMBER: 'Number of device codes (per)',
  EQUIPMENT_COMPONENTS_NUMBER: 'Number of spare parts (per)',
  AR_DIGITAL_DISPLAY_NUMBER: 'Number of AR digital display(per)',
  VIDEO_CALL_DURATION: 'Length of call (minutes)',
  CLOUD_RECORDING_STORAGE_SPACE: 'Cloud recording space(G)',
  OSS_STORAGE_SPACE: 'Cloud storage space(G)',
  // ServiceResourceFeeModeEnum-资源计费模式
  METERING: 'Pay by amount',
  ACCOUNT_SUBSCRIPTIONS: 'Account subscription',
  // ARTypeEnum -类型池枚举
  MAC: 'MAC address',
  OAID: 'OAID address',
  // ScopeEnum -范围枚举
  GLOBAL: 'Global company visible',
  scope_COMPANY: 'Current company visible',
  CHANNEL_LINE: 'Channel line company visible',
  // UserAccountTypeEnum -用户账号类型枚举
  SEATS: 'Expert side',
  AR: 'AR side',
  MOBILE: 'Mobile side',
  // UserAccountSubscribeTypeEnum -用户账号订阅方式枚举
  AUTO: 'Auto',
  MANUAL: 'Manual',
  // messageType-消息类型
  SYSTEM_NOTICE: 'System notifications',
  // messageTarget-消息群体
  ALL: 'Global',
  CHANNEL: 'Channel line',
  USER_ACCOUNT: 'Specify the account',
  // osType-终端类型
  APK: 'Android APK',
  PC: 'PC application',
  // versionType-版本类型
  MASTER: 'The main version',
  HISTORY: 'History',
  BETA: 'beta',
  // 网关操作日志类型
  QUERY: 'Inquire',
  RESET: 'Reset password',
  // Http前缀
  Http:'http',
  Https:'https',
}
