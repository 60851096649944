// 版本管理
export default {
  title_1: 'PC version management table',
  title_2: 'Android version management table',
  forceUpdate: 'Whether to force an update',
  category: 'Type',
  osType: 'The terminal type',
  versionType: 'The version type',
  title: 'Title',
  version: 'Version description',
  versionNumber: 'Version number',
  notes: 'Version description',
  appFile: 'Related documents',
  instructions: 'Instructions for use',
  // 用户相关
  addUser: 'Add user',
  delUser: 'Delete user',
  viewUser: 'View the user',
}
