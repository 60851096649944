<template>
  <div class="loginPage">
    <div class="loginBox">
      <div class="leftImg">
        <div class="iconImg leftShowImg"></div>
        <div class="expired" v-if="! isPermanent">{{$t('base.licenceTip')}}:{{expiredTime}}<a v-if="showUpdateLicence" href="javascript:void(0);" @click="$refs.uploadLicence.click()">{{$t('base.updateLicence')}}</a></div>
        <input type="file" style="display: none;" :ref="'uploadLicence'" @change="uploadLicence" />
      </div>
      <div class="rightContent">
        <div class="language">
           <el-radio-group v-model="lang" size="mini" @change="changeLanguage" fill="#525F6B">
            <el-radio-button label="zhCN">{{ $t('base.zhCN') }}</el-radio-button>
            <el-radio-button label="en">{{ $t('base.en') }}</el-radio-button>
            <el-radio-button label="ja">{{ $t('base.ja') }}</el-radio-button>
          </el-radio-group>
        </div>
        <div class="innerContent">
          <div
            class="iconImg logoIcon"
            :style="{
              backgroundImage:
                'url(' +
                (customCompanyInfo
                  ? customCompanyInfo.logo.fileHost +
                    '/' +
                    customCompanyInfo.logo.objectName
                  : require('./img/logo.png')) +
                ')',
            }"
          />
          <el-form ref="form" :model="formData" size="medium">
            <!-- lang:账户 -->
            <el-form-item
              prop="loginName"
              class="inputBottom"
              :rules="
                $validate({
                  noNull: true,
                  label: $t('base.loginName'),
                  tips: $t('base.pleaseEnter'),
                })
              "
            >
              <!-- lang:请输入您的账号 -->
              <el-input
                v-model.trim="formData.loginName"
                :placeholder="$t('base.loginNameHolder')"
                clearable
              >
                <i slot="prefix" class="iconfont iconMargin"> <img :src="require('./img/ico-yhm.png')" /></i>
              </el-input>
            </el-form-item>
            <!-- lang:密码 -->
            <el-form-item
              prop="password"
              :rules="
                $validate({
                  noNull: true,
                  label: $t('base.password'),
                  tips: $t('base.pleaseEnter'),
                })
              "
            >
              <!-- lang:请输入您的密码 -->
              <el-input
                v-model.trim="formData.password"
                :placeholder="$t('base.passwordHolder')"
                :type="showPass ? 'text': 'password'"
              >
                <i slot="prefix" class="iconfont iconMargin">
                  <img :src="require('./img/ico-mima.png')" />
                </i>
                <i slot="suffix" :class="[showPass ? 'showpass' : 'hidepass']" autocomplete="auto" @click="showPass =! showPass">
                    <template v-if="showPass">
                       <img src="./img/passShow.png" />
                    </template>
                    <template v-else>
                      <img src="./img/passHide.png" />
                    </template>
                  </i>
              </el-input>
            </el-form-item>
            <!-- lang:忘记密码 -->
            <div class="forgotpassword">
              <router-link :to="{name:'forgot'}" style="color:#0B81E0;text-decoration: none;"> {{$t('base.forgotpassword')}}</router-link>
            </div>
            <el-form-item>
              <!-- lang:登录 -->
              <el-button type="primary" @click="login" class="logonColor">
                {{ $t('base.login') }}
              </el-button>
              <div class="homeFoot">
                <!-- lang:联系我们-->
                <a
                  class="homeJump"
                  target="_blank"
                  :href="
                    customCompanyInfo
                      ? customCompanyInfo.contactLink
                      : $path.contactUsUrl
                  "
                >
                  <i class="iconfont" />{{
                    customCompanyInfo
                      ? customCompanyInfo.contactWord
                      : $t('base.contactUs')
                  }}
                </a>
                <!-- lang:立即注册 -->
                <div style="display: flex;flex-direction: row">
                  <router-link class="homeReg" :to="{name: 'accountReg' }"><i class="iconfont" />{{$t('base.accountRegister')}}</router-link>
                  <router-link class="homeReg" :to="{name: 'reg' }"><i class="iconfont" />{{$t('base.companyRegister')}}</router-link>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="footInfo">
          <!-- lang:Copyright © 2020 蒂姆维澳(上海)网络技术有限公司. All rights reserved. -->
          <p class="copyright">
            {{
              customCompanyInfo
                ? customCompanyInfo.copyright
                : $t('base.copyright')
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>
