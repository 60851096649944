// 消息管理
export default {
  pageTitle: 'Message management table',
  createTime: 'Push time',
  target: 'The message community',
  userAccountIdSet: 'Push account number',
  title: 'The message title',
  content: 'The message content',
  seeAccount: 'Viewing Push Users',
  rollbackStatus: 'Whether it has been withdrawn',
  searchTitle: 'The message title',
  type:'Product type'
}
