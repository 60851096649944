export default {
  goodday:'早上好',
  goodmorning:'上午好',
  goodnoon:'中午好',
  goodafternoon:'下午好',
  goodevening:'晚上好',
  lasttime:'您上次登录的时间是',
  productsCount:'各类产品使用次数总计',
  productsTrend:'各类产品使用次数趋势',
  productUsagetrend:'产品使用趋势图',
  TMVAResources:'远程指导资源剩余',
  XR_WORKResources:'工作流资源剩余',
  remoteguidance:'远程指导',
  workorder:'工单',
  knowledgebase:'知识库',
  workflow:'工作流',
  changeLogo:'更换头像',
  changepassword:'更改密码',
  modifypassword:'修改密码',
  newpassword:'新密码',
  confirmpass:'确认密码',
  logout:'注销',
}
