// 功能管理
export default {
  title: 'Function management table',
  moveUp: 'Move up',
  moveDown: 'Move down',
  permissionEnum: 'Name enumeration',
  permissionType: 'Function type',
  method: 'Method name',
  apiSet: 'Release interface',
  icon: 'Icon',
  url: 'Page path',
  addButtonError: 'Button function cannot add subordinate',
  shouldBeBasic: 'Whether to hide the permission button in the upper right corner',
}
