// 产品服务开通
export default {
  title:'产品服务开通',
  openTime:'开通时间',
  openCompany: '开通企业',
  openDetails: '开通详情',
  empowerCpmpany: '授权企业',
  status:'状态',
  productModule: '产品模块',
  openingStatus: '开通状态',
  billingMode:'计费模式',
  openProduct:'产品开通',
  modifyProduct: '修改产品开通',
  selectOpenProduct: '请选择开通企业',
  selectProductModel: '请选择产品模块',
  selectBillingMode: '请选择计费模式',
  opened:"已开通",
  closed:'已关闭',
  pleaseSelect: '请选择'
}
