<template>
  <el-dialog
    class="baseDialog"
    :center="center"
    :width="width"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="showClose"
    :before-close="beforeClose"
    :visible="visible"
  >
    <template v-slot:title>
      <!-- lang:提示 -->
      <slot name="title">{{ title || $store.state.dialogTitle }}</slot>
    </template>
    <div :style="{ height: height }">
      <slot />
    </div>
    <template v-slot:footer>
      <slot name="footer">
        <!-- lang:取消 -->
        <el-button @click="cancel">{{ $t('base.cancel') }}</el-button>
        <el-button type="primary" @click="confirm" v-if="showConfirm">
          <!-- lang:确定 -->
          {{ $t('base.confirm') }}
        </el-button>
      </slot>
    </template>
  </el-dialog>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>