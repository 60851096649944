// 日志管理
export default {
  title: 'Log management table',
  createBy: 'Founder',
  loginName: 'Account',
  recordStartTime: 'Start recording time',
  recordEndTime: 'End recording time',
  recordSpentTime: 'Used time(ms)',
  contextFrom: 'Source of the session',
  seq: 'Session id',
  subjectToken: 'The session token',
  apiModule: 'Interface module',
  apiModuleDescription: 'Interface module description',
  api: 'Interface',
  apiDescription: 'Interface specification',
  hasException: 'Whether abnormalities occur',
  exceptionName: 'The full path of the exception name',
  exceptionSimpleName: 'Exception name',
  exception: 'Occurr exception message ',
  requestParameters: 'Request parameter json',
  responseParameters: 'Response parameter json',
  jdbcEventDocuments: 'Request sql execution information for the design',
  slowQuery: 'Whether there is slow sql',
  recordSpentTimeGte: 'Session time is greater than or equal to(ms)',
}
