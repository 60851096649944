// 版本管理
export default {
  title_1: 'PCバージョン管理テーブル',
  title_2: 'アンドロイドバージョン管理テーブル',
  forceUpdate: 'フォースアップデートかどうか',
  category: 'タイプ',
  osType: 'OSタイプ',
  versionType: 'バージョンタイプ',
  title: 'タイトル',
  version: 'バージョン説明',
  versionNumber: 'バージョンナンバー',
  notes: 'Notes',
  appFile: '関連ファイル',
  // 用户相关
  addUser: 'ユーザーを追加',
  delUser: 'ユーザーを削除',
  viewUser: 'ユーザーをチェック',
}
