<template>
  <div class="homePage">
    <left-navs />
    <div class="mainWrap" :class="{ isFold: $store.state.isFold }">
      <top-navs />
      <page-tabs />
      <div class="mainContent">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>