// 页面公用操作方法
import { getSessionStorage, getLocalStorage } from '@/utils'
import store from '@/store'
import * as path from '@/path'
import router from '@/router'

export const jump = (url, query) => {
  if (!url) return
  const permission = getSessionStorage('permission')
  // 递归获取跳转枚举
  let permissionEnum
  const getPermissionEnum = (data) => {
    if (!data || !data.length) return
    for (let i = 0; i < data.length; i++) {
      const item = data[i]
      if (item.url === url) {
        permissionEnum = item.permissionEnum
        return
      }
      if (item.children && item.children.length) getPermissionEnum(item.children)
    }
  }
  getPermissionEnum(permission)
  if (permissionEnum) {
    store.dispatch('tags/addTags', { permissionEnum, query })
  } else {
    router.push({ path: url, query })
  }
}

// 是否显示操作按钮
export const showBtn = (permissionEnum) => {
  const { targetPermission } = store.getters
  if (!targetPermission) return false
  const children = targetPermission.children || []
  return children.some((item) => item.permissionEnum === permissionEnum)
}

/**
 * 获取请求头
 */
export const getBaseUrl = () => {
  const operator = getSessionStorage('operator')
  if (operator.company.beCustom === 'YES') {
    return path.baseUrlCustom
  }
}
