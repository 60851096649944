// 业务枚举
export default {
  // enableVale-状态
  NORMAL: '正常',
  DISABLE: '無効',
  DELETE: '削除',
  // yn-是非
  YES: 'はい',
  NO: 'いいえ',
  // ContextFrom-会话来源
  REQUEST: '外部依頼',
  SCHEDULE: 'タスクの予定',
  WEB_SOCKET: 'webSocket',
  // level-等级
  ONE: 'レベル1',
  TWO: 'レベル2',
  THREE: 'レベル3',
  FOUR: 'レベル4',
  FIVE: 'レベル5',
  SIX: 'レベル6',
  SEVEN: 'レベル7',
  EIGHT: 'レベル8',
  NINE: 'レベル9',
  TEN: 'レベル10',
  // DictTypeEnum-字典类型
  ADDRESS: 'アドレス',
  COMPANY_INDUSTRY: '業界',
  VERSION_CATEGORY: 'カテゴリー分類',
  CLIENT_LOG_TYPE: 'クライアントログタイプ',
  // permissionType-功能类型
  ROOT: 'ルート',
  ONE_LIST: 'レベル1機能',
  TWO_LIST: 'レベル2機能',
  BUTTON: 'ボタン機能',
  // SubjectTypeEnum-主体类型
  PC_MANAGE: 'システム管理',
  // RoleTypeEnum-角色类型
  COMPANY: '会社管理者',
  SUB: 'サブ管理者',
  // AuthType-鉴权类型
  ANONYMOUS: '匿名アクセス',
  LOGIN: 'ロゴイン後アクセス',
  AUTHORIZED: '授権後アクセス',
  // FileFrom-文件来源
  WEB_ADMIN: 'バックグラウンドプラットフォームを管理',
  // LanguageEnum-语言类型
  en: '英語',
  zhCN: '中国語',
  // invoiceType-发票类型
  GENERAL: '領収書',
  VAT: '増値税専用領収書',
  // ServiceResourceAssignStatusEnum-授权状态
  ASSIGNED: '権限付与済み',
  ROLLBACK: '権限取消し',
  ASSIGNED_1: '割り当て済み',
  ROLLBACK_1: '取消し',
  // ServiceResourceTypeEnum-资源类型
  ACCOUNT_NUMBER: 'アカウント数量(個)',
  ACCOUNT_SUBSCRIPTIONS_NUMBER: 'アカウント購読数量(個)',
  EQUIPMENT_NUMBER: 'デバイス数量(個)',
  EQUIPMENT_COMPONENTS_NUMBER: 'デバイススペアパーツ数量(個)',
  AR_DIGITAL_DISPLAY_NUMBER: 'ARディジタルディスプレイ数量(個)',
  VIDEO_CALL_DURATION: '通話時間(分間)',
  CLOUD_RECORDING_STORAGE_SPACE: 'クラウドレコーディング容量(G)',
  OSS_STORAGE_SPACE: 'クラウドストレージ空間(G)',
  // ServiceResourceFeeModeEnum-资源计费模式
  METERING: '従量制課金',
  ACCOUNT_SUBSCRIPTIONS: 'アカウント購読',
  // ARTypeEnum -类型池枚举
  MAC: 'MACアドレス',
  OAID: 'OAID地址',
  // ScopeEnum -范围枚举
  GLOBAL: '全部の会社に公開',
  scope_COMPANY: '社内のみ共有',
  CHANNEL_LINE: 'チャネル会社向け公開',
  // UserAccountTypeEnum -用户账号类型枚举
  SEATS: 'テレフォンオペレーター側',
  AR: 'AR側',
  MOBILE: 'モバイル側',
  // UserAccountSubscribeTypeEnum -用户账号订阅方式枚举
  AUTO: '自動',
  MANUAL: '手動',
  // messageType-消息类型
  SYSTEM_NOTICE: 'システム通知',
  // messageTarget-消息群体
  ALL: '全部',
  CHANNEL: 'チャンネル',
  USER_ACCOUNT: '指定アカウント',
  // osType-终端类型
  APK: 'アンドロイドAPK',
  PC: 'PCアプリ',
  // versionType-版本类型
  MASTER: 'マスターバージョン',
  HISTORY: 'ヒストリー',
  BETA: 'beta',
  // 网关操作日志类型
  QUERY: 'クエリ',
  RESET: 'パスワードリセット',
}
