import axios from 'axios'
import { Loading } from 'element-ui'
import * as path from '@/path'
import { showAlert } from '@/utils/element'
import { getSessionStorage, getLocalStorage} from '@/utils'
import i18n from '@/lang'
import router from '@/router'
import store from '@/store'
import md5 from 'js-md5'

/**
 * 合并请求头
 * @param {object} headers //请求头
 * */
const getHeader = (headers, tag1) => {
  console.log('tag1', tag1)
  const oemName = process.env.VUE_APP_OEM_NAME
  const defaultHeader = {
    'software-version': 'WEB_ADMIN',
    'oem-name': oemName,
    'device-type': 'WEB',
    'Content-Encoding':'gzip',
    'api_version':'V3'
  }
  const token = getSessionStorage('token')
  const dateDiff = new Date().getTime()
  const signStr = `sign=&timestamp=${dateDiff}&tag=${tag1}&deviceCode=${dateDiff}&device-type=WEB&software-version=WEB_ADMIN&oem-name=${oemName}`
  console.log(signStr)
  const sign = md5(signStr)
  const deviceCode  = dateDiff
  const timestamp = dateDiff
  if (token) defaultHeader.subject_token = token
  defaultHeader.deviceCode = deviceCode
  defaultHeader.sign = sign
  defaultHeader.timestamp = timestamp
  return Object.assign(defaultHeader, headers)
}

/**
 * 处理请求错误信息
 * @param {string} msg 错误信息
 * */
const showErrorMsg = (msg) => {
  showAlert({
    content: msg,
  })
}

/**
 * 公共网络请求
 * @param {string} requestParams.url 请求路径
 * @param {string} requestParams.method 请求类型、默认post
 * @param {object} requestParams.headers 请求头、默认{}
 * @param {object} requestParams.data 请求参数post、默认{}
 * @param {object} requestParams.params 请求参数get、默认{}
 * @param {number} requestParams.timeout 请求超时时间、默认60000毫秒
 * @param {string} requestParams.responseType 响应数据类型
 * @param {boolean} requestParams.defineDeal 自己处理请求结果
 * @param {boolean} requestParams.defineError 自己处理错误信息
 * @param {boolean} requestParams.showLoading 是否显示加载中动画
 * @param {string} requestParams.baseUrl 自定义请求根路径
 * */
const ajax = (requestParams) => {
  return new Promise((resolve, reject) => {
    const { url,
      data = {},
      params = {},
      method = 'POST',
      headers = {},
      timeout = 60000,
      responseType = 'json',
      defineDeal, defineError,
      showLoading = true,
      baseUrl
    } = requestParams
    let isLoading
    if (showLoading) {
      isLoading = Loading.service({
        background: 'rgba(0, 0, 0, 0)',
      })
    }
    // axios.interceptors.request.use(config => {
    //   console.log(diffTokenTime())
    //   return Promise.reject(error)
    //   return config
    // },
    // error => {
    //   console.log(error)
    //   return Promise.reject(error)
    // })

    let uri = url.split('/')
    let tag = uri[0]
    if(uri[0] === '') {
      tag = uri[1]
    }
    if(tag === 'core') {
      tag = 'tmva'
    }
    if(tag === 'workFlow') {
      tag = 'xrwork'
    }
    if(tag === 'idc') {
      tag = 'tmvaIdc'
    }
    console.log('tag', tag)
    axios({
      baseURL: baseUrl || path.baseUrl,
      url,
      data,
      params,
      method,
      timeout,
      responseType,
      headers: getHeader(headers, tag),
    })
      .then((result) => {
        if (isLoading) isLoading.close()
        const { data } = result
        if (defineDeal) {
          resolve(data)
          return
        }
        const { state, msg } = data
        // 未登录和被踢出
        if (state === 'NO_LOGIN' || state === 'KICK_OUT') {
          // 清除本地缓存数据
          store.dispatch('clearSessionInfo').then(() => {
            router.replace({ path: '/login' })
          })
          return
        }
        if (state !== 'SUCCESS') {
          if(msg !== 'ACCOUNT_000016' && msg !== 'SEND_MESSAGE_AUTH_CODE_000002' && msg !== 'REGISTER_ACCOUNT_000002'){
            showErrorMsg(i18n.t(`serverCode.${msg}`))
          }
          // showErrorMsg(i18n.t(`serverCode.${msg}`))
          reject(data)
          return
        }
        resolve(data.data)
      })
      .catch((error) => {
        if (isLoading) isLoading.close()
        if (defineError) {
          reject(error)
          return
        }
        reject(error)
        //lang:服务器异常
        if (error.request.status === 500 || error.request.status === 0) {
          const img = new Image()
          img.src = location.origin + '/favicon.ico?' + Math.random()
          img.onload = () => {
            showErrorMsg(i18n.t('base.serverException'))
          }
          img.onerror = () => {
            showErrorMsg(i18n.t('base.netWorkError'))
          }
        } else {
          showErrorMsg(i18n.t('base.serverException'))
        }
      })
  })
}

/**
 *导出文件
 * type参考：developer.mozilla.org/zh-CN/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export const importFile = ({ baseUrl, url, data = {}, name, type = '.xlsx' }) => {
  const isLoading = Loading.service({
    background: 'rgba(0, 0, 0, 0.4)',
    text: i18n.t('base.isImporting'),
  })
  ajax({
    baseUrl,
    url,
    data,
    responseType: 'blob',
    defineDeal: true,
  })
    .then((result) => {
      isLoading.close()
      let newType
      switch (type) {
        case '.xlsx':
          newType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          break
        case '.doc':
          newType = 'application/msword'
          break
        case '.docx':
          newType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          break
        case '.zip':
          newType = 'application/zip'
          break
        default: // do something
      }
      const url = window.URL.createObjectURL(
        new Blob([result], {
          type: newType,
        })
      )
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch(() => {
      isLoading.close()
    })
}

export default ajax
