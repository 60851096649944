import {mapGetters} from "vuex";
import {workflowPermissionEnum} from "@/dict";

export default {
  data() {
    return {
      showPermissionList: []
    }
  },
  computed: {
    ...mapGetters([
        'company'
    ]),
    // 数据
    state() {
      return this.$store.state
    },
    // 权限菜单
    permission() {
      const { permission } = this.state.permission
      console.log(permission)
      return permission
    },
    // 当前激活菜单项
    activeTag() {
      const { activeTag } = this.state.tags
      return activeTag
    },
    // 私有化企业信息
    customCompanyInfo() {
      return this.$store.state.customCompanyInfo
    },
  },
  mounted() {
    console.log(this.company)
    if(this.$util.getLocalStorage('apiprefix') === 'workFlow') {
      this.$ajax({
        url: this.$api.findCompanyProductVersion,
        data: {
          companyUid: this.company.uid
        }
      }).then(res => {
        res.forEach(item => {
          if(item.isGrant === 'YES') {
            this.showPermissionList.push(this.$dict.workflowPermissionEnumMap()[item.versionType])
          }
        })
        console.log('aaaa', res)
      })
    }
  },
  methods: {
    isShow(data) {
      if(this.$dict.workflowPermissionEnum().includes(data.permissionEnum)) {
        if (this.showPermissionList.includes(data.permissionEnum)) {
          return true
        } else {
          return false
        }
      }
      return true
    },
    getMain() {
      this.$router.replace({ path: '/main' })
    },
    // 选择菜单
    select(permissionEnum) {
      console.log(222, permissionEnum)
      this.$store.dispatch('tags/addTags', { permissionEnum })
    },
    routerInit() {
      console.log(this.$util.getLocalStorage('apiprefix'))
    }
  },
}
