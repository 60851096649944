// 消息管理
export default {
  pageTitle: '消息管理表',
  createTime: '推送时间',
  target: '消息群体',
  userAccountIdSet: '推送账号',
  title: '消息标题',
  content: '消息内容',
  seeAccount: '查看推送用户',
  rollbackStatus: '是否已撤回',
  searchTitle: '消息标题',
  type:'产品类型'
}
