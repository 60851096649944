// 全局公用
export default {
  // element.js
  title: 'Title',
  content: 'Content',
  // ajax.js
  serverException: 'Server error',
  netWorkError: 'The network connection is down, please try again later',
  // validate.js
  pleaseEnter: 'Please enter',
  incorrectFormat: 'Incorrect format',
  numOrLetter: 'Should be letter or number',
  letter: 'Should be letter',
  chinese: 'Should be Chinese',
  positiveInt0: 'Should be an integer greater than or equal to 0',
  positiveInt: 'Should be an integer greater than 0',
  int: 'Should be an integer',
  positive: 'Should be a number greater than 0',
  positive0: 'Should be a number greater than or equal to 0',
  len: 'The length should be',
  min: 'The minimum length should be',
  max: 'The maximum length should be',
  validatePassword: 'Capitalized, at least 1 number, at least one special character, limited to 8-15 characters',
  // 登录
  welcome: 'Welcome to use AR remote after-sales service system',
  loginName: 'Account',
  password: 'Password',
  loginNameHolder: 'Please enter your account',
  passwordHolder: 'Please enter your password',
  login: 'Login',
  copyright: 'Copyright © 2021 TMVA (Shanghai) Network Technology Co., Ltd. All rights reserved.',
  // 图片预览
  rotateLeft: 'Rotate counterclockwise',
  rotateRight: 'Rotate clockwise',
  shrink: 'Narrow',
  restore: 'Restores',
  amplification: 'Amplification',
  download: 'Download',
  close: 'Close',
  // 主页
  homePage: 'Home page',
  set: 'Set',
  help: 'Help',
  quit: 'Quit',
  quitConfirm: 'Quit confirm',
  contactUs: 'Contact us',
  cancel: 'Cancel',
  confirm: 'Confirm',
  tip: 'Tip',
  name: 'Name',
  value: 'Value',
  enter: 'Please enter',
  select: 'Please select',
  selectTreeNode: 'Please select tree node',
  selectTableRow: 'Please select data',
  addSuccess: 'Add success',
  editSuccess: 'Edit success',
  deleteSuccess: 'Delete success',
  sortSuccess: 'Sort success',
  distributionSuccess: 'Distribution success',
  copySuccess: 'The copy was successful',
  moveSuccess: 'The move was successful',
  deleteConfirm: 'Delete confirm',
  currentSelect: 'Current select',
  notes: 'Notes',
  serialNumber: 'Number',
  sort: 'Sort',
  option: 'Operate',
  searchSuccess: 'Search data success',
  resetTips: 'The search form has been reset',
  search: 'Search',
  reset: 'Reset',
  all: 'All',
  canUse: 'Whether the feature can use',
  searchStr: 'Keyword',
  startDate: 'Start date',
  endDate: 'End date',
  createTime: 'Time',
  disableConfirm: 'Are you sure you want to disable it?',
  recoverConfirm: 'Are you sure you want to recover it？',
  disableSuccess: 'Disable success',
  recoverSuccess: 'Recover success',
  hasDisabled: 'The data has disabled',
  hasRecover: 'The data has enabled',
  enable: 'State',
  resetConfirm: 'Are you sure you want to reset it?',
  resetSuccess: 'Reset success',
  setRoleSuccess: 'Set role success',
  noData: 'No data',
  hasSelect: 'Selected item：',
  totalSelect: 'Total select',
  item: 'Item',
  add: 'Add',
  edit: 'Edit',
  del: 'Delete',
  view: 'Details',
  countLimit: 'Upload at most',
  count: 'Count',
  sizeLimit: 'Size out of limit, current image size is',
  fileSizeLimit: 'Size out of limit, current file size is',
  maxSize: 'Maximum uploadable',
  uploadError: 'Image upload failed',
  fileUploadError: 'File upload failed',
  uploadFile: 'Upload file',
  fileTypeTitle: 'Support',
  fileTypeEnd: 'Format file',
  back: 'Back',
  nameOrNotes: 'Name or note',
  drawFile: 'Drag the file here, or',
  clickUpload: 'Click on the upload',
  importSize: 'Support only .xlsx format spare parts templates provided by this system, and no more than 5M, please',
  downloadTemplate: 'Click here to download the template',
  uploadFileTips: 'Please upload the file',
  excelImport: 'EXCEL import',
  importSuccess: 'Import success',
  isImporting: 'Exporting file, please wait...',
  export: 'Export',
  customField: 'Custom field',
  setCustomField: 'Set a custom field',
  writeTips: 'Please check that the content is entered in full',
  confirmRollback: 'Are you sure you want to withdraw it?',
  rollbackSuccess: 'Withdraw success',
  mapStart: 'Start',
  mapEnd: 'End',
  canAddFirstCate: 'Version classifications can only create new level 1 classifications',
  zhCN: 'Chinese',
  en: 'English',
  ja: 'Japanese',
  justSelectOne: '只能选择一条数据',
  register:'Register',
  forgotpassword:'Forgot password',
  codeErrText: '验证码输入错误',
  validateCode: '请输入',
  companyRegister:'企业注册',
  accountRegister:'用户注册',
  revocationConfirm: '确定要撤回吗',
  revocationSuccess: '撤回成功',
  renewalSuccess: '续期成功',
  bindMobile:'请输入绑定手机号码',
  bindSuccess:'手机绑定成功',
  closeRegister:'确定关闭注册吗',
  closeRegisterSuccess:'关闭注册成功',
  maxlength:'必须为18位',
  applyTime: '申请时间',
  refuseConfirm: '确定要拒绝吗?',
  pleaseSelect: '请勾选',
  pleaseSelect1: '请选择',
  deactivateConfirm: '确定要停用吗',
  refuseSuccess: '拒绝成功',
  deactivateSuccess: '停用成功',
}
