<template>
  <base-page
    :title="$t('adminAccount.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.operatorFind"
      :defaultParams="{
        scopeCompanyId: $store.getters.company.id,
      }"
      :columns="columns"
      v-model="tableRow"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 状态 -->
      <template v-slot:enable="{ row }">
        {{ enableObj[row.enable] }}
      </template>
      <!-- 角色 -->
      <template v-slot:roles="{ row }">
        {{ (row.roles && row.roles.length && row.roles[0].name) || '' }}
      </template>
      <!-- 账号类型 -->
      <template v-slot:roleType="{ row }">
        {{ roleTypeObj[row.type] }}
      </template>
      <!-- 所属企业 -->
      <template v-slot:company="{ row }">
        {{ row.company.name }}
      </template>
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    <dialog-table-list
      ref="dialogTableList"
      v-if="showDialogTableList"
      v-model="dialogTableRow"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.roleFind"
      :defaultParams="defaultParams"
      :columns="dialogColumns"
      @cancel="showDialogTableList = false"
      @confirm="setRoleMethod"
      @search="dialogSearch"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 角色类型 -->
      <template v-slot:type="{ row }">
        {{ roleTypeObj[row.type] }}
      </template>
    </dialog-table-list>
    <password-detail
      v-if="showPasswordDetail"
      :tableRow="tableRow"
      @closePage="closePasswordPage"
    />
  </base-page>
</template>

<script>
import Detail from './detail'
import PasswordDetail from './passworDetail'

export default {
  components: {
    Detail,
    PasswordDetail,
  },
  data() {
    return {
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 7,
          tagProps: {
            placeholder: this.$t('adminAccount.nameOrNotes'),
          },
        },
        // lang:账号类型
        // {
        //   tag: 'el-select',
        //   label: this.$t('adminAccount.type'),
        //   prop: 'type',
        //   span: 6,
        //   tagProps: {
        //     options: this.$dict.roleType(),
        //   },
        // },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 7,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      roleTypeObj: this.$util.listToObj(this.$dict.roleType()),
      enableObj: this.$util.listToObj(this.$dict.enable()),
      tableRow: null,
      columns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:角色
        {
          label: this.$t('adminAccount.role'),
          prop: 'operator',
          callback(row) {
            if(row.operator.roles.length) return row.operator.roles[0].name
          }
        },
        // lang:状态
        {
          label: this.$t('base.enable'),
          prop: 'enable',
        },
        // lang:姓名
        {
          label: this.$t('adminAccount.name'),
          prop: 'name',
        },
        // lang:手机号
        {
          label: this.$t('adminAccount.mobile'),
          prop: 'mobile',
        },
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'accountId',
          width: '100px',
        },
        // lang:账号类型
        {
          label: this.$t('adminAccount.type'),
          prop: 'operator',
          callback(row) {
            if(row.operator.operatorType === 'COMPANY_MANAGER') return '企业管理员'
            if(row.operator.operatorType === 'SUB_MANAGER') return '子管理员'
          }
        },
        // lang:所属企业
        {
          label: this.$t('adminAccount.company'),
          prop: 'company',
        },
        // lang:昵称
        {
          label: this.$t('adminAccount.nickname'),
          prop: 'nickName',
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
        },
      ],
      // 表单页
      showDetail: false,
      // 分配角色
      showDialogTableList: false,
      dialogColumns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:角色名称
        {
          label: this.$t('roleManage.name'),
          prop: 'name',
        },
        // lang:角色类型
        {
          label: this.$t('roleManage.type'),
          prop: 'type',
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
        },
      ],
      defaultParams: {},
      dialogTableRow: null,
      // 重置密码
      showPasswordDetail: false,
    }
  },
  methods: {
    // 关闭重置密码详情回掉
    closePasswordPage() {
      this.showPasswordDetail = false
    },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 弹出表格查询
    dialogSearch(searchParams) {
      this.$refs.dialogTableList.searchList(searchParams)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.optionsMethod(
            '/' + this.$util.getLocalStorage('apiprefix') + this.$api.operatorDelete,
            this.$t('base.deleteConfirm'),
            this.$t('base.deleteSuccess')
          )
          break
        case 'disable':
          if (this.tableRow.enable === 'DISABLE') {
            this.$element.showMsg(this.$t('base.hasDisabled'))
            return
          }
          this.optionsMethod(
            '/' + this.$util.getLocalStorage('apiprefix') + this.$api.operatorDisabled,
            this.$t('base.disableConfirm'),
            this.$t('base.disableSuccess')
          )
          break
        case 'recover':
          if (this.tableRow.enable === 'NORMAL') {
            this.$element.showMsg(this.$t('base.hasRecover'))
            return
          }
          this.optionsMethod(
            '/' + this.$util.getLocalStorage('apiprefix') + this.$api.operatorRecover,
            this.$t('base.recoverConfirm'),
            this.$t('base.recoverSuccess')
          )
          break
        case 'resetPassword':
          this.showPasswordDetail = true
          break
        case 'grantRole':
          {
            const { roleType, roles } = this.tableRow
            this.defaultParams.roleType = roleType
            if (roles && roles.length) {
              this.dialogTableRow = roles[0]
            } else {
              this.dialogTableRow = null
            }
            this.showDialogTableList = true
          }
          break
        default: // do something
      }
    },
    // 删除，禁用，启用
    optionsMethod(url, confirmTips, successTips) {
      this.$element
        .showConfirm({
          content: confirmTips,
        })
        .then(() => {
          this.$ajax({
            url: url,
            data: this.tableRow,
          }).then(() => {
            // lang:删除成功或禁用成功或启用成功或重置成功
            this.$element.showMsg(successTips, 'success')
            this.search()
          })
        })
    },
    // 分配角色
    setRoleMethod() {
      if (!this.dialogTableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.operatorSetRole,
        data: {
          id: this.tableRow.id,
          subjectType: 'PC_MANAGE',
          roleIdSet: [this.dialogTableRow.id],
        },
      }).then(() => {
        this.showDialogTableList = false
        // lang:角色分配成功
        this.$element.showMsg(this.$t('base.setRoleSuccess'), 'success')
        this.search()
      })
    },
  },
}
</script>