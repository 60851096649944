export default {
  name: 'dialogFormList',
  props: {
    // 弹出框配置项-------------
    title: {
      // 显示标题
      type: String,
      default: '',
    },
    width: {
      // 宽度
      type: String,
      default: '500px',
    },
    height: {
      // 高度
      type: String,
      default: 'auto',
    },
    center: {
      // 底部操作按钮是否左右居中
      type: Boolean,
      default: true,
    },
    showClose: {
      // 是否显示右上角关闭按钮
      type: Boolean,
      default: true,
    },
    // 弹出表单配置项------------
    config: {
      // 表单配置项
      type: Array,
      default: () => [],
    },
    formData: {
      // 绑定数据
      type: Object,
      default: () => ({}),
    },
    labelWidth: {
      // 文本标题宽度
      type: String,
      default: '150px',
    },
    gutter: {
      // 表单间隔距离
      type: Number,
      default: 10,
    },
    disabled: {
      // 是否禁用表单
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // 是否显示弹框确定按钮
    showConfirm() {
      if (this.$store.state.cmd === 'view') return false
      return true
    },
  },
  methods: {
    // 弹框取消
    cancel() {
      this.$emit('cancel')
    },
    // 弹框确定
    confirm() {
      this.$refs.formList.$refs.formList.validate((valid) => {
        if (!valid) return
        this.$emit('confirm')
      })
    },
    // 自定义弹框点击回调
    openDialog(item) {
      this.$emit('openDialog', item)
    },
  },
}
