<template>
  <el-row class="detailList" v-if="formData">
    <template v-for="(item, index) in config">
      <el-col :key="item.prop" :span="item.span || 24" v-if="!item.hidden">
        <template v-if="item.tag === 'slot'">
          <slot :name="item.prop" :data="formData" :index="index" />
        </template>
        <div v-else>
          <div>{{ item.label }}</div>
          <div>
            <template v-if="item.callback">
              {{ item.callback(formData) }}
            </template>
            <slot v-else :name="item.prop" :data="formData" :index="index">
              {{ formData[item.prop] }}
            </slot>
          </div>
        </div>
      </el-col>
    </template>
  </el-row>
</template>

<script>
export default {
  name: 'detailList',
  props: {
    config: {
      // 表单配置项
      type: Array,
      default: () => [],
    },
    formData: {
      // 绑定数据
      type: Object,
      default: () => null,
    },
  },
}
</script>

<style lang="scss">
// 详情页
.detailList {
  padding-top: 1px;
  > div {
    > div {
      display: flex;
      border: 1px solid #dcdfe6;
      margin-left: -1px;
      margin-top: -1px;
      > div {
        &:first-child {
          flex: none;
          width: 150px;
          background: #f5f7fa;
          padding: 7px 15px;
          text-align: right;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #666;
        }
        &:last-child {
          flex: 1;
          padding: 7px 15px;
          word-break: break-all;
        }
      }
    }
  }

  .el-textarea__inner {
    padding: 0;
    border: none;
    background: transparent;
  }
}
</style>