// 分配账单
export default {
  title: '料金明細書割当テーブル',
  createTime: '割当時間',
  company: '上級会社',
  assignDetailSet: '詳細',
  status: '状態',
  assignName: '授権内容',
  assignNumber: 'ライセンス数量',
}
