// 服务返回code
export default {
  // 请求响应状态-state
  SUCCESS: '成功',
  ERROR: 'エラー',
  SYSTEM_ERROR: 'システムエラー',
  NO_PERMISSION: '権限なし',
  NO_LOGIN: 'ログインされていません',
  KICK_OUT: 'キックアウトされました',
  // 请求响应错误码-msg-账户相关
  ACCOUNT_000001: 'アカウントとパスワードを確認してください',
  ACCOUNT_000002: 'アカウント異常',
  ACCOUNT_000003: 'プラットフォームアカウントは連絡先を追加、もしくは追加されることはできません。',
  ACCOUNT_000004: 'アカウント更新失敗',
  ACCOUNT_000005: 'このアカウントは既にログインされています',
  ACCOUNT_000006: '同じ会社のアカウントなので、追加はできません',
  ACCOUNT_000007: '既に連絡先に入っていて、追加できません',
  ACCOUNT_000008: '既に申請しました',
  ACCOUNT_000009: 'この申請は処理済みです。（同意か拒否かはしました）',
  ACCOUNT_000010: 'デバイスのユニークコードは無効です',
  ACCOUNT_000011: '本アカウント以外の外部連絡先',
  ACCOUNT_000012: 'パスワードのフォーマットは正しくありません',
  ACCOUNT_000013: 'アカウントはデバイスタイプに対応していません',
  ACCOUNT_000014: '今のアカウントには外部連絡先を追加できません',
  ACCOUNT_000015: '相手のアカウントは外部連絡先として追加できません',
  ACCOUNT_000016: 'この名前は他の人が利用しています',
  ACCOUNT_000017: 'この会社に既にスーパー管理者がいます',
  ACCOUNT_000018: 'プライベートクラウドはパブリッククラウドにログインできません',
  // 请求响应错误码-msg-接口请求
  API_000001: '未ログイン',
  API_000002: '権限なし',
  API_000003: '不明なインターフェイス',
  API_000004: '不正なパラメータ',
  API_000005: 'サポートされていないリクエスト方法',
  API_000006: 'httpボリュームの読み書きに失敗',
  // 请求响应错误码-msg-数据访问
  DATA_000001: 'データアクセス異常',
  DATA_000002: 'データなし',
  DATA_000003: 'データは既にあります',
  // 请求响应错误码-msg-文件操作
  FILE_000001: 'ファイズサイズは制限を超えています',
  FILE_000002: 'ファルダ作成失敗',
  FILE_000003: 'アップロード失敗',
  FILE_000004: 'ファイルのアップロード署名の取得に失敗',
  FILE_000005: 'ファイルのアップロードコールバックチェックに失敗',
  FILE_000006: 'ファイルのコールバック解析パラメータのアップロードに失敗',
  // 请求响应错误码-msg-其它错误
  OTHER_000001: '不明な異常',
  OTHER_000002: '操作失敗',
  // 请求响应错误码-msg-设备相关
  EQUIPMENT_000001: '他社の共有データは編集できません',
  EQUIPMENT_000002: 'デバイスグループの下に削除できないデバイスがあります',
  EQUIPMENT_000003: 'デバイス/パーツは販売されており、削除できません',
  // 请求响应错误码-msg-销售客户相关
  CUSTOMER_000001: '削除に失敗しました。このお客様は販売情報を関連付けています',
  // 请求响应错误码-msg-地址池信息
  POOL_000001: 'アドレスプール情報の割り当て数が足りません',
  POOL_000002: 'プールのタイプは存在しません',
  POOL_000003: 'デバイスのプールは足りません',
  POOL_000004: '部品のプールは足りません',
  POOL_000005: '会社は販売リソース/資格はありません',
  POOL_000006: '会社のARデジタルディスプレ情報リソースが不足',
  // 请求响应错误码-msg-EXCEL相关
  EXCEL_000001: 'excelファイル読取失敗',
  EXCEL_000002: 'excelデータ読取失敗',
  // 请求响应错误码-msg-部门相关
  USER_ACCOUNT_GROUP_000001: '部署にサブ部署が含まれている場合は削除できません',
  USER_ACCOUNT_GROUP_000002: '部署に含まれるアカウントは削除できません',
  // 请求响应错误码-msg-授权资源
  SERVICE_RESOURCE_000001: 'ライセンスの残り数量不足',
  SERVICE_RESOURCE_000002: 'サポートされていないリソース課金モード',
  SERVICE_RESOURCE_000003: 'ライセンスタイプに対応するコードプールタイプが見つかりませんでした',
  SERVICE_RESOURCE_000004: 'ターゲット企業のライセンスは不足',
  // 请求响应错误码-msg-网关设备
  GATEWAY_EQUIPMENT_000001: 'ゲートウェイデバイスは存在しません',
  GATEWAY_EQUIPMENT_000002: 'ゲートウェイファイルの読み取りエラー',
  // 请求响应错误码-msg-好视通
  HST_ERROR_000001: '好視通インターフェイスの呼び出しに失敗',
  // 消息通知错误码-msg-消息通知
  MESSAGE_000001: 'メッセージは撤回されたため、再び撤回できません',
  // 请求响应错误码-msg-版本信息
  VERSION_000001: 'マスターバージョンは削除できません',
  // 请求响应错误码-msg-角色相关
  ROLE_000001: 'ロールに操作データが含まれて、削除できません',
  // 请求响应错误码-msg-企业相关
  COMPANY_000001: 'この会社の下にまた下級の会社があるので、削除できません',
  COMPANY_000002: 'この会社の下にバックグラウンドアカウントがあるので、削除できません',
  COMPANY_000003: 'この会社の下にフロントアカウントがあるので、削除できません',
  COMPANY_000004: '会社データは存在していません',
  COMPANY_000005: 'カスタマイ会社のコードは重複しています',
}
