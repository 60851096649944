// AR数显管理
export default {
  title: 'AR digital display management table',
  name: 'AR digital display code name',
  code: 'Url Address',
  notes: 'Notes',
  prefixHttp:"HTTP",
  nameOrNotes: 'Name/url address/Notes',
  arName: 'AR number display code',
  exportCode: 'Export QR codes',
}
