<template>
  <div class="functionButton">
    <template v-for="item in buttons">
      <el-button
        v-if="item.shouldBeBasic === 'NO'"
        :key="item.id"
        :method="item.method"
        @click="functionMethod(item)"
        size="mini"
      >
        <i v-if="item.icon" :class="`iconfont ${item.icon}`" /> {{ item.name }}
      </el-button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'functionButton',
  computed: {
    // 当前页面权限
    targetPermission() {
      return this.$store.getters.targetPermission
    },
    // 权限按钮
    buttons() {
      if (!this.targetPermission) return []
      const { children } = this.targetPermission
      return children || []
    },
  },
  methods: {
    // 功能按钮点击回调
    functionMethod(item) {
      this.$store.commit('updateCmd', item.method)
      this.$store.commit(
        'updateDialogTitle',
        this.$t(`functionEnum.${item.permissionEnum}`)
      )
      this.$emit('functionMethod', item)
    },
  },
}
</script>
