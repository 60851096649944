export default {
  title: '销售信息表',
  equipment: '设备信息',
  customer: '客户信息',
  manufactureTime: '出厂日期',
  equipmentNos: '出厂编号',
  warrantyTime: '质保日期',
  quantity: '销售数量',
  equipmentName: '设备名称',
  inconformity: '销售数量和出厂编号数量不一致',
  sn: '销售单号',
  nameOrNotes: '销售单号/设备名称/设备编号/客户名称',
  addEquipmentNos: '请输入内容并按英文逗号进行分割',
  saleCode: '销售二维码',
  useDept: '使用部门',
  deleteTime: '删除时间',
}
