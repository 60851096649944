// 角色管理
export default {
  title: '角色管理表',
  name: '角色名称',
  type: '角色类型',
  accountAuthorization:'账号管理授权',
  guidanceAuthorization:'远程指导授权',
  workflowAuthorization:'点巡检授权',
  tmvaIdcAuthorization:'传感及数据接口管理授权',
}
