<template>
  <base-dialog
    ref="baseDialog"
    :title="title"
    :width="width"
    :height="height"
    :showConfirm="showConfirm"
    :center="center"
    :showClose="showClose"
    @cancel="cancel"
    @confirm="confirm"
  >
    <base-page
      class="dialogBasePage"
      :config="config"
      :formData="formData"
      :search-span="4"
      @search="search"
    >
      <table-list
        ref="tableList"
        v-model="tableData"
        :baseUrl="baseUrl"
        :url="url"
        :defaultParams="defaultParams"
        :isDefine="isDefine"
        :defaultContent="defaultContent"
        :defaultPage="defaultPage"
        :defaultSize="defaultSize"
        :pagination="pagination"
        :rowKey="rowKey"
        :columns="columns"
        :multiple="multiple"
        :highlightCurrentRow="highlightCurrentRow"
        :align="align"
        :tree-props="treeProps"
        :defaultExpandAll="defaultExpandAll"
        :expandRowKeys="expandRowKeys"
        :showOverflowTooltip="showOverflowTooltip"
        @rowClick="rowClick"
        @select="select"
        @selectAll="selectAll"
      >
        <template
          v-for="item in columns"
          v-slot:[item.prop]="{ row, index, content }"
        >
          <slot
            :name="item.prop"
            :row="row"
            :index="index"
            :content="content"
          />
        </template>
        <template
          v-for="item in columns"
          v-slot:[`${item.prop}-header`]="{ column, index, content }"
        >
          <slot
            :name="`${item.prop}-header`"
            :column="column"
            :index="index"
            :content="content"
          />
        </template>
      </table-list>
    </base-page>
  </base-dialog>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
.dialogBasePage {
  .searchBox {
    padding: 0;
    margin-bottom: 0;
  }
  .el-table--small th,
  .el-table--small td {
    padding: 5px 0;
  }
  .tableContent {
    padding: 0;
  }
  .el-dialog__footer {
    padding: 10px;
  }
}
</style>