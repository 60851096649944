export default {
  data() {
    let checkPwd = (rule,value,callback) => {
      if(value.trim().length==0){
        callback(new Error(`${this.$t('reg.pleaseinputpass')}`))
      }else if(value !=this.ruleForm.password){
        callback(new Error(`${this.$t('reg.confirmCheck')}`))
      }else{
        callback()
      }
    }
    return {
      eye1Show: false,
      eye2Show: false,
      langData: [
        {
          value: 'zhCN',
          label: this.$t('base.zhCN')
        },
        {
          value: 'en',
          label: this.$t('base.en')
        },
        {
          value: 'ja',
          label: this.$t('base.ja')
        }],
      lang: this.$util.getLocalStorage('lang') || 'zhCN',
      // 认真方式
      contactType:[
        {
          value: 1,
          label: this.$t('reg.code')
        },
        {
          value: 2,
          label: this.$t('reg.emailCheck')
        },
      ],
      rules: {
        confirmpass: [{validator: checkPwd,required: true,trigger: "blur",}],
      },
      ruleForm:{
        loginName:'',
        sms:1,
        mobile:'',
        code:'',
        seqNo:'',
        password:'',
        confirmpass:'',
        email:'',
        emailCheckCode:''
      },
      isShowType: 1,
      bindMobileShow:false,
      bindForm: {
        code:'',
        seqNo:'',
        mobile:'',
      },
      count: '', // 验证码倒计时
      timer:null ,
      codeShow: true,
    }
  },
  watch: {
    'ruleForm.mobile'(val) {
      this.ruleForm.mobile = val.replace(/[^0-9.]/g,'')
    },
  },
  methods: {
    triggerEye(index) {
      if(index === 1) {
        this.eye1Show = !this.eye1Show
      }
      if(index === 2) {
        this.eye2Show = !this.eye2Show
      }
    },
    changeLang(lang) {
      this.lang = lang
      this.$i18n.locale = lang
      this.$util.setLocalStorage('lang', lang)
    },
    getBack() {
      this.$router.push({ path: '/login'})
    },
    changeType(val) {
      this.isShowType = val
      this.$refs.dataForm.clearValidate()
      if(this.isShowType === 1) {
        this.bindForm.code = ''
      }
    },
    // 取消
    cancel() {
      this.bindMobileShow = false
    },
    forgotConfirm() {
      const ruleForm = this.$util.copyData(this.ruleForm)
      console.log(ruleForm)
      this.$refs.dataForm.validate(valid => {
        if (valid) {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.changePasswordForForget,
            data: ruleForm,
          }).then((data) => {
            console.log(data)
            // lang:修改成功
            this.$element.showMsg(this.$t('base.editSuccess'), 'success')
            this.$router.push({ path: '/login'})
          }).catch(err=>{
            if(err.msg === 'ACCOUNT_MOBILE_NULL') {
              this.bindMobileShow = true
              console.log(err)
            }
            if(err.msg === 'SEND_MESSAGE_AUTH_CODE_000002') {
              this.$refs['dataForm'].fields[3].validateMessage = this.$t(`serverCode.${err.msg}`)
              this.$refs['dataForm'].fields[3].validateState = 'error'
            }
          })
          this.codeShow = true
          clearInterval(this.timer)
          this.timer = null
          // setTimeout(() => {

          // }, 400)
        } else {

          return false
        }
      })
    },
    forgotCancel() {
      this.$refs.dataForm.clearValidate()
    },
    // 手机验证
    confirmBind() {
      this.$refs.bindForm.validate(valid => {
        console.log(valid)
        console.log(this.bindForm)
        if (valid) {
          const bindForm = this.$util.copyData(this.bindForm)
          bindForm.loginName = this.ruleForm.loginName
          // 绑定手机
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.bindMobileForget,
            data: bindForm,
          }).then((data) => {
            this.ruleForm.seqNo = data
            this.$element.showMsg(this.$t('base.bindSuccess'), 'success')
            this.bindMobileShow = false
            this.codeShow = true
            clearInterval(this.timer)
            this.timer = null
          })
          // setTimeout(() => {

          // }, 400)
        } else {
          return false
        }
      })
    },
    // 向管理员发送验证码
    sendBindCode() {
      console.log(this.ruleForm.loginName)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.sendCompanyAdminCodeNoLogin,
        data:{
          loginName: this.ruleForm.loginName
        }
      }).then((data) => {
        console.log(data)
        this.bindForm.seqNo = data
      })
      const TIME_COUNT = 30   //倒计时60秒
      if(!this.timer) {
        this.count = TIME_COUNT
        this.codeShow = false
        this.timer = setInterval(() => {
          if(this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.codeShow = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    // 获取验证码
    sendcode() {
      if(!this.ruleForm.mobile){
        this.$element.showMsg(this.$t('base.bindMobile'), 'warning')
        return false
      }
      // 验证码
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.sendCode,
        data:{
          mobile: this.ruleForm.mobile,
        }
      }).then((data) => {
        console.log(data)
        this.ruleForm.seqNo = data
      })
      const TIME_COUNT = 30   //倒计时60秒
      if(!this.timer) {
        this.count = TIME_COUNT
        this.codeShow = false
        this.timer = setInterval(() => {
          if(this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.codeShow = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
  }
}
