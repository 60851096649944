<template>
  <json-views
    :data="json || {}"
    :closed="closed"
    :deep="deep"
    :iconStyle="iconStyle"
    :theme="theme"
    :iconColor="iconColor"
    :fontSize="fontSize"
    :lineHeight="lineHeight"
  />
</template>

<script>
import Index from './_index.js'

export default Index
</script>