// AR数显管理
export default {
  title: 'ARデジタルディスプレイ管理テーブル',
  name: 'ARデジタルディスプレイコード名称',
  code: 'urlアドレス',
  notes: '備考',
  nameOrNotes: '名称/urlアドレス/備考',
  arName: 'ARデジタルディスプレイコード',
  exportCode: 'QRコードをエクスポート',
}
