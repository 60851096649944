<template>
  <div class="container" ref="app">
    <div class="topBox">
        <div class="loginuser">
        <div class="logo"><img :src="operator.headImg ? operator.headImg : require('./img/icoyuln.png')" /> </div>
        <div class="iconame" @click="isFold = !isFold">{{operator.name}}</div>
        <div class="icoxiala" ><img src="./img/icoxiala.png" class="iconjiantou" @click="isFold = !isFold"/> </div>
      </div>
        <div class="drawer" :class="{ active: isFold }">
          <el-row>
            <el-col :span="6" :offset="2"><div class="drawerLogo">
              <img :src="headImg ? headImg : require('./img/icoyuln.png')" /></div>
            </el-col>
            <el-col :span="12" >
               <div class="drawerUserTop">{{operator.name}}</div>
               <div class="drawerUserBtn">
                <el-upload
                  ref="upload"
                  :auto-upload="true"
                  action="#"
                  :http-request="changeLogin"
                  :show-file-list="false"
                  accept="image/*"
                  >
                  <span>{{$t('main.changeLogo')}}</span>
                </el-upload>
                </div>
          </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" :offset="2" class="drawerLang drawerColor">
               <el-select
                  v-model="lang"
                  placeholder="请选择"
                  size="mini"
                  style="width: 220px"
                  @change="changeLang"
                  :popper-append-to-body="false"
                >
                <el-option
                  v-for="item in langData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
                </el-select>
              </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" :offset="2" class="drawerColor"><div @click="changepassword">{{$t('main.changepassword')}}</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="24" :offset="2" class="drawerColor">Documentation</el-col>
          </el-row>
          <el-row>
            <el-col :span="24" :offset="2" class="drawerColor">Login Code</el-col>
          </el-row>
          <el-row>
            <el-col :span="5" :offset="2" class="drawerColor"><div class="item" @click="logout">{{$t('main.logout')}}</div></el-col>
          </el-row>
          <div v-if="operator.type === 'SYSTEM_ADMINISTRATOR' && !isPermanent" class="update-licence" @click="$refs.uploadLicence.click()">{{$t('base.updateLicence')}}</div>
          <div v-if="operator.type === 'SYSTEM_ADMINISTRATOR'" class="sql-adjust" @click="sqlAdjust">{{$t('base.sqlAdjust')}}</div>
          <input type="file" style="display: none;" :ref="'uploadLicence'" @change="uploadLicence" />
      </div>
    </div>
    <el-dialog
      :title="$t('main.modifypassword')"
      ref="baseDialog"
      :visible.sync="bindMobileShow"
      v-if="bindMobileShow"
      class="passBox"
      width="476px"
      :modal="false"
      :close-on-click-modal="false"
      :modal-append-to-body='false'
    >
    <el-form ref="bindForm" :model="bindForm" label-width="110px" >
      <el-row>
        <el-col :span="23">
          <el-form-item :label="$t('main.modifypassword')" prop="password"
            :rules="
                $validate({
                  noNull: true,
                  label: $t('main.newpassword'),
                  tips: $t('base.pleaseEnter'),
                })">
              <el-input  size="mini" v-model="bindForm.password"  :placeholder="$t('base.pleaseEnter') + $t('main.newpassword')" maxlength="6"></el-input>
          </el-form-item>
          <el-form-item :label="$t('main.confirmpass')" prop="confirmpass"
            :rules="
                $validate({
                  noNull: true,
                  label: $t('main.confirmpass'),
                  tips: $t('base.pleaseEnter'),
                })">
              <el-input  size="mini" v-model="bindForm.confirmpass" :placeholder="$t('base.pleaseEnter') + $t('main.confirmpass')"></el-input>
          </el-form-item>
          </el-col>
      </el-row>
      <el-row>
        <el-col :span="18" :offset="3" class="footBtn">
            <el-button @click="cancel" class="cancelPop">{{ $t('base.cancel') }}</el-button>
            <el-button type="primary" @click="confirmPassword" class="confirmPop">
              {{ $t('base.confirm') }}
            </el-button>
          </el-col>
      </el-row>
    </el-form>
    </el-dialog>

    <div class="mainBox">
      <div class="main">
      <div class="title">
        <div class="username">{{ WelcomeText }}，{{operator.name}}</div>
        <div class="usertime">{{ $t('main.lasttime') }}<span> {{ $util.format(operator.lastLoginTime, 'yyyy-MM-dd hh:mm:ss') }}</span></div>
      </div>
      <div class="bannerPic">
        <template v-if="operator.type === 'SYSTEM_ADMINISTRATOR'">
          <div class="item" @click="getModel('CORE')"><img src="./img/CORE.png" /></div>
          <div class="item" @click="getModel('TMVA_IDC')"><img src="./img/TMVA_IDC.png" /></div>
        </template>
         <template v-else>
           <div class="item" @click="getModel('CORE')"><img src="./img/CORE.png" /></div>
           <div class="item" v-if="productConfigEntities.filter(item=>item.type === 'TMVA_IDC').length > 0" @click="getModel('TMVA_IDC')"><img src="./img/TMVA_IDC.png" /></div>
<!--           <template v-for="(item,index) in productConfigEntities">-->
<!--             <div class="item" :key="index" @click="getModel(item.type)"><img :src="require('./img/'+ item.type +'.png')" /></div>-->
<!--           </template>-->
         </template>
      </div>
      <div class="bannerPic">
        <template v-if="operator.type === 'SYSTEM_ADMINISTRATOR'">
          <div class="item" @click="getModel('TMVA')"><img src="./img/TMVA.png" /></div>
          <div class="item"><img src="./img/zhishiku.png" /></div>
          <div class="item" @click="getModel('XR_WORK')"><img src="./img/XR_WORK.png" /></div>
        </template>
        <template v-else>
          <div class="item" v-if="productConfigEntities.filter(item=>item.type === 'TMVA').length > 0" @click="getModel('TMVA')"><img src="./img/TMVA.png" /></div>
          <div class="item" v-if="productConfigEntities.filter(item=>item.type === 'XR_WORK').length > 0" @click="getModel('XR_WORK')"><img src="./img/XR_WORK.png" /></div>
<!--          <div class="item" @click="getModel('CORE')"><img src="./img/CORE.png" /></div>-->
<!--          <template v-for="(item,index) in productConfigEntities">-->
<!--            <div class="item" :key="index" @click="getModel(item.type)"><img :src="require('./img/'+ item.type +'.png')" /></div>-->
<!--          </template>-->
        </template>
      </div>
      <template v-if="operator.type !== 'SYSTEM_ADMINISTRATOR'">
        <div class="center">
          <div class="centerLeft">
            <div class="border"><span>{{ $t('main.productsCount') }}</span></div>
            <div class="borderBox flexBox">
              <div class="itemBox">
                <div class="borderTitle">{{ $t('main.remoteguidance') }}</div>
                <div class="borderNum">{{TMVA}}</div>
              </div>
              <div class="itemBox">
                <div class="borderTitle">{{ $t('main.workorder') }}</div>
                <div class="borderNum">{{WORK_ORDER}}</div>
              </div>
              <div class="itemBox">
                <div class="borderTitle">{{ $t('main.knowledgebase') }}</div>
                <div class="borderNum">{{KNOWLEDGE}}</div>
              </div>
              <div class="itemBox">
                <div class="borderTitle">{{ $t('main.workflow') }}</div>
                <div class="borderNum">{{XR_WORK}}</div>
              </div>
            </div>
          </div>
          <div class="centerRight">
            <div class="border"><span>{{ $t('main.productsTrend') }}</span></div>
            <div class="borderBox" id="productBox" :style="`width: 806px; height:280px;zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`">
            </div>
          </div>
        </div>
        <div v-for="item in productConfigEntities" class="footer" :key="item.type">
          <div class="borderBox"><span>{{ $t(`main.${item.type}Resources`) }}</span></div>
          <div class="borderBotmBox">
            <div v-for="item in getRes(item.type)" class="borderItem" :key="item.container">
              <div class="borderItemDraw" :id="item.container"></div>
              <div class="borderItemText">{{item.name}}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
    </div>
  </div>
</template>
<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>
