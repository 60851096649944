<template>
  <el-form
    class="formList"
    ref="formList"
    :model="formData"
    :labelWidth="labelWidth"
    :disabled="isDisabled"
    size="small"
    :class="{
      isDisabled: isDisabled,
    }"
  >
    <el-row :gutter="gutter">
      <!-- 表单 -->
      <template v-for="(item, index) in newConfig">
        <el-col
          :key="item.prop"
          :span="item.span || 24"
          :class="[item.className, item.tag + '_className']"
          v-if="item.show ? item.show(formData) : !item.hidden"
        >
          <div v-if="item.tag === 'slot'" style="margin-top: -18px">
            <slot :name="item.prop" :item="item" :index="index" />
          </div>
          <template v-else>
            <el-form-item
              :prop="item.prop"
              :rules="
                $validate({
                  label: item.label,
                  tips: item.tagProps.placeholder,
                  ...(item.rules || {}),
                })
              "
            >
              <!-- 标题 -->
              <template slot="label">
                <slot :name="`${item.prop}-label`" :item="item" :index="index">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.label"
                    placement="top-start"
                  >
                    <span>{{ item.label }}：</span>
                  </el-tooltip>
                </slot>
              </template>

              <template v-if="item.tag === 'slot-content'">
                <slot :name="item.prop" :item="item" :index="index" />
              </template>
              <template v-else-if="item.tag === 'show-image'">
                <el-image
                  style="width:100px"
                  :src="formData[item.prop]"
                />
              </template>
              <template v-else>
                <!-- 弹出表格和弹出树点击表单 -->
                <template
                  v-if="
                    item.tag === 'dialog-tree' || item.tag === 'dialog-define'
                  "
                >
                  <div
                    class="select-item-btn"
                    :class="{
                      hasValue:
                        (!item.tagProps.multiple && formData[item.prop]) ||
                        (item.tagProps.multiple &&
                          formData[item.prop] &&
                          formData[item.prop].length),
                      isDisabled: item.tagProps.disabled,
                    }"
                    @click="dialogTableListOpen(item)"
                  >
                    <template
                      v-if="
                        (!item.tagProps.multiple && !formData[item.prop]) ||
                        (item.tagProps.multiple &&
                          (!formData[item.prop] || !formData[item.prop].length))
                      "
                    >
                      {{ $t('base.select') }}
                    </template>
                    <template v-else>
                      {{
                        item.tagEvents && item.tagEvents.showName
                          ? item.tagEvents.showName(formData[item.prop])
                          : formData[item.prop]
                      }}
                    </template>
                    <i class="el-icon-arrow-down" />
                    <i
                      class="el-icon-circle-close"
                      @click.stop="clearDialogValue(item)"
                    />
                  </div>
                  <!-- 弹出树 -->
                  <dialog-tree
                    :ref="`dialogTree-${item.prop}`"
                    v-if="showDialogTree"
                    v-bind="targetItem.tagProps"
                    @cancel="dialogTableListCancel"
                    @confirm="dialogTableListConfirm"
                  />
                </template>
                <!-- 表单 -->
                <component
                  v-else
                  :ref="item.prop"
                  :is="item.tag"
                  v-bind="item.tagProps"
                  v-on="item.tagEvents"
                  v-model="formData[item.prop]"
                >
                  <!-- el-radio-group特有 -->
                  <template v-if="item.tag === 'el-radio-group'">
                    <el-radio
                      v-for="list in item.tagProps.options"
                      :key="list.value"
                      :label="list.value"
                      :disabled="list.disabled"
                    >
                      {{ list.label }}
                    </el-radio>
                  </template>
                  <!-- el-checkbox-group特有 -->
                  <template v-if="item.tag === 'el-checkbox-group'">
                    <el-checkbox
                      v-for="list in item.tagProps.options"
                      :key="list.value"
                      :label="list.value"
                      :disabled="list.disabled"
                    >
                      {{ list.label }}
                    </el-checkbox>
                  </template>
                  <!-- el-select特有 -->
                  <template v-else-if="item.tag === 'el-select'">
                    <el-option
                      v-for="list in item.tagProps.options"
                      :key="list.value"
                      :label="list.label"
                      :value="list.value"
                      :disabled="list.disabled"
                    />
                  </template>
                </component>
              </template>
            </el-form-item>
          </template>
        </el-col>
      </template>
      <!-- 查询和重置按钮 -->
      <el-col
        v-if="isSearch"
        style="padding-top: 2px; min-width: 160px"
        :style="{
          paddingTop: '2px',
          minWidth: '200px',
          textAlign: searchAlign,
        }"
        :span="searchSpan"
      >
        <el-form-item label-width="0px">
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="mini"
            @click="search"
          >
            {{ $t('base.search') }}
          </el-button>
          <el-button class="restBtn" size="mini" plain @click="resetForm">
            {{ $t('base.reset') }}
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>
