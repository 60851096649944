// element ui封装
import { Message, MessageBox } from 'element-ui'
import i18n from '@/lang'

/**
 * 轻提示
 * @param {string} msg 提示信息
 * @param {string} type 提示类型success、warning、error、info
 */
export const showMsg = (msg, type = 'info', duration = 3000) => {
  Message({
    dangerouslyUseHTMLString: true,
    message: `<strong>${msg}</strong>`,
    customClass: 'tipsMsg',
    type,
    offset: 15,
    duration,
  })
}

/**
 * 弹框提示
 * @param {string} title 标题
 * @param {string} content 提示内容
 * @param {string} type 提示类型success、warning、error、info
 */
export const showAlert = ({ title = i18n.t('base.tip'), content = i18n.t('base.content'), type }) => {
  return new Promise((resolve) => {
    MessageBox({
      title,
      message: content,
      showClose: false,
      type,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      center: true,
      callback() {
        resolve()
      },
    })
  })
}

/**
 * 确认弹框
 * @param {string} title 标题
 * @param {string} content 提示内容
 * @param {string} type 提示类型success、warning、error、info
 */
export const showConfirm = ({ title = i18n.t('base.tip'), content = i18n.t('base.content'), type }) => {
  return new Promise((resolve, reject) => {
    MessageBox({
      title,
      message: content,
      showClose: false,
      type,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      center: true,
      showCancelButton: true,
      callback(action) {
        if (action === 'confirm') {
          resolve()
        } else {
          reject()
        }
      },
    })
  })
}
