<template>
<div class="page">
  <div class="pageBox">
    <el-row class="pageTop">
      <el-col :span="2">
          <el-select
            v-model="lang"
            placeholder="请选择"
            size="mini"
            style="width: 100px"
            @change="changeLang"
          >
          <el-option
            v-for="item in langData"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
          </el-select>
        </el-col>
      <el-col :span="12" :offset="4" v-if="stepTop">
        <div class="stepBox">
          <div class="item" :class="[stepNum < 4 ? 'active' : 'grey']"><span>1</span>{{$t('accountReg.verifyMobile')}}</div>
          <div class="item"><img src="../../assets/jiantou.png" /></div>
          <div class="item" :class="[stepNum > 1 || stepNum === 2 ? 'active' : 'grey']"><span>2</span>{{$t('accountReg.affiliateSignup')}}</div>
          <div class="item"><img src="../../assets/jiantou.png" /></div>
          <div class="item" :class="[stepNum === 3 ? 'active' : 'grey']"><span>3</span>{{$t('accountReg.joinCompany')}}</div>
        </div>
      </el-col>
      <el-col :span="1" :offset="3">
        <div class="back" @click="getBack">{{$t('accountReg.goback')}}</div>
      </el-col>
    </el-row>
    <template v-if="stepOne">
      <el-form ref="dataForm" :model="ruleForm" label-width="140px" >
        <el-row :gutter="20">
          <el-col :span="10" :offset="7">
            <div class="formBox">
              <el-form-item :label="$t('accountReg.mobile')" prop="phone"
                            :rules="
                  $validate({
                    noNull: true,
                    type: 'mobile',
                    label: $t('accountReg.mobile'),
                    tips: $t('base.pleaseEnter'),
                  })">
                <el-input v-model="ruleForm.phone" size="mini" :placeholder="$t('base.pleaseEnter') + $t('accountReg.pleaseMobile')" maxlength="11"></el-input>
              </el-form-item>
              <el-form-item :label="$t('accountReg.code')" prop="authCode"
                            :rules="
                    $validate({
                      noNull: true,
                      label: $t('accountReg.code'),
                      tips: $t('base.pleaseEnter'),
                    })">
                <el-input v-model="ruleForm.authCode" size="mini" :placeholder="$t('base.pleaseEnter') + $t('reg.code')" style="width:160px;" maxlength="6"></el-input>
                <el-button type="plain" class="getCode" size="mini" @click="getCode" v-if="codeShow">{{$t('reg.getCode')}}<span id="count"></span></el-button>
                <el-button class="getCodeSend" size="mini" disabled v-if="!codeShow">{{$t('reg.getCode')}}{{count}}s</el-button>
              </el-form-item>
              <el-form-item :rules="
                    $validate({
                      noNull: true,
                    })" prop="isAgree">
                <el-checkbox v-model="ruleForm.isAgree">{{$t('accountReg.isAgree')+ '《' + $t('accountReg.serviceAgreement') +'》' + '《' + $t('accountReg.privacyPolicy') +'》'}}</el-checkbox>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="4" class="twoButRight">
            <el-button type="primary" @click="getRegOne" class="downButtom buttonFoot">{{$t('accountReg.next')}}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-if="stepTwo">
      <el-form ref="dataFormTwo" :model="ruleForm" :rules="rules" label-width="140px" >
        <el-row :gutter="20">
          <el-col :span="10" :offset="7">
            <div class="formBox">
              <el-form-item :label="$t('accountReg.loginName')" prop="loginName"
                            :rules="
                  $validate({
                    noNull: true,
                    min: 5,
                    max: 10,
                    label: $t('accountReg.loginName'),
                    tips: $t('base.pleaseEnter'),
                  })">
                <el-input v-model="ruleForm.loginName" size="mini" :placeholder="$t('base.pleaseEnter') + $t('accountReg.loginName')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('accountReg.name')" prop="name"
                            :rules="
                  $validate({
                    noNull: true,
                    min: 2,
                    max: 10,
                    label: $t('accountReg.name'),
                    tips: $t('base.pleaseEnter'),
                  })">
                <el-input v-model="ruleForm.name" size="mini" :placeholder="$t('base.pleaseEnter') + $t('accountReg.name')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('accountReg.loginPass')" prop="password"
                            :rules="
              $validate({
              noNull: true,
              type: 'password',
              label: $t('reg.passWord'),
              tips: $t('base.pleaseEnter'),
            })">
                <el-input v-model="ruleForm.password" size="mini" :placeholder="$t('base.pleaseEnter') + $t('reg.passWord')" :type="flag ? 'text' : 'password'" maxlength="15">
                  <i slot="suffix"
                     :class="[flag?'el-icon-minus':'el-icon-view']"
                     style="margin-top:8px;font-size:18px;"
                     autocomplete="auto"
                     @click="flag=!flag" />
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('accountReg.confirmpass')"
                            prop="confirmpass"
              >
                <el-input v-model="ruleForm.confirmpass" :placeholder="$t('accountReg.pleaseConfirm') + $t('accountReg.passWord')" :type="flagconfirm ? 'text' : 'password'" maxlength="15">
                  <i slot="suffix"
                     :class="[flagconfirm ? 'el-icon-minus': 'el-icon-view']"
                     style="margin-top:8px;font-size:18px;"
                     autocomplete="auto"
                     @click="flagconfirm=!flagconfirm" />
                </el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="4" class="twoButRight">
            <el-button type="primary" @click="goReg" class="downButtom buttonFoot">{{$t('accountReg.reg')}}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-if="stepThree">
      <el-form ref="dataFormThree" :model="ruleForm" label-width="140px" >
        <el-row :gutter="20">
          <el-col :span="10" :offset="7">
            <div class="formBox">
              <el-form-item :label="$t('accountReg.company')" prop="companyName"
                            :rules="
                  $validate({
                    noNull: true,
                    label: $t('accountReg.companyUid'),
                    tips: $t('base.pleaseSelect1'),
                  })">
                <el-autocomplete
                    ref="autocomplete"
                    v-model="ruleForm.companyName"
                    :fetch-suggestions="findServiceName"
                    :placeholder="$t('base.pleaseEnter') + $t('reg.serviceproviderName')"
                    :trigger-on-focus="false"
                    @select="handleSelectName"
                    :debounce='0'
                    size="mini"
                    style="width:260px"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('accountReg.inviteCode')" prop="inviteCode"
                            :rules="
                  $validate({
                    noNull: true,
                    label: $t('accountReg.inviteCode'),
                    tips: $t('base.pleaseEnter'),
                  })">
                <el-input v-model="ruleForm.inviteCode" size="mini" :placeholder="$t('base.pleaseEnter') + $t('accountReg.inviteCode')"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="4" class="twoButRight">
            <el-button type="primary" @click="joinCompany" class="downButtom buttonFoot">{{$t('accountReg.confirm')}}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </template>
  </div>
</div>
</template>
<script>
import Index from "./_index.js"
export default Index
</script>

<style lang="scss">
@import "./_index.scss";
</style>
