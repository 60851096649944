// 注册用户管理表
export default {
  title: '企业注册管理表',
  registerTime: '注册时间',
  companyName: '企业名称',
  address: '联系地址',
  contacts: '联系人',
  information: '联系方式',
  email: '联系邮箱',
  description: '业务描述',
  serviceprovider: '所属服务商',
  status:'企业注册状态',
  keyword: '关键字',
  creationtime: '创建时间',
  serverName:'服务商名称',
  serverCode:'服务商代码',
  companycode: '统一社会信用代码',
  business: '企业业务',
  addressDetail: '详细地址',
  invoicingname: '开票企业开户行',
  account: '企业开户账号',
  industry: '所属行业',
  authorizationUpload: '授权书上传',
  licenseUpload: '营业执照盖章上传',
  invoiceType:'开票类型',
  GENERAL:'普票',
  VAT:'增值税发票',
  remarks:'跟踪备注',
  setRemarksSuccess:'跟踪备注添加成功',
  registered:'已注册',
  registerNew:'新注册',
  manageName:'管理员姓名',
  manageMobile:'管理员手机号码',
  manageNickName:'管理员昵称',
  manageNote:'管理员备注',
  proceed:'继续注册',
  setRegisterSuccess:'服务商分配成功',
  noRegister:'未分配服务商'
}
