<template>
  <div class="uploadImage">
    <ul>
      <li>
        <el-upload
          ref="upload"
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.4)"
          :action="action"
          :accept="accept"
          :show-file-list="false"
          :http-request="httpRequest"
          :before-upload="beforeUpload"
          :on-error="onError"
          :disabled="disabled"
        >
          <div
            class="uploadButton"
            :class="{ isDisabled: disabled }"
            :style="{ width: width, height: height, lineHeight: height }"
          >
          <template v-if="showImage">
            <template v-if="showImageUrl">
              <img :src="showImageUrl" class="avatar" >
            </template>
            <template v-else>
              <img :src="sendImg" class="avatar" >
            </template>
          </template> 
            <template v-if="formReg">
              <i v-if="!showImage" class="yunupload"><img src="../../assets/yunUpload.png" ></i>
            </template>
            <template v-else>
              <i class="el-icon-plus"/>
            </template>
          </div>
        </el-upload>
      </li>
      <li v-for="(item, index) in value" :key="item.fileName">
        <el-image
          class="imageList"
          :style="{ width: width, height: height }"
          :src="item.fileHost + '/' + item.objectName"
        />
        <div class="imageOption">
          <i class="el-icon-zoom-in" @click="previewMethod(item)" />
          <i
            class="el-icon-delete"
            v-if="!disabled"
            @click="deleteMethod(index)"
          />
        </div>
      </li>
    </ul>
    <preview-image v-if="showPreview" :src="previewUrl" @close="closePreview" />
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>