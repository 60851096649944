<template>
  <base-dialog
    :showConfirm="false"
    :showClose="false"
    width="810px"
    @cancel="cancel"
    :class="{ pcEqFullscreen: fullscreen }"
  >
    <!-- 标题 -->
    <el-row slot="title">
      <el-col :span="12">{{ $t('equipmentManage.equipmentDetail_1') }}</el-col>
      <el-col :span="12" class="pcEqDetailIocns">
        <i
          class="icon iconfont icon-24gl-minimize2"
          v-if="fullscreen"
          @click="fullscreen = false"
        ></i>
        <i class="el-icon-full-screen" v-else @click="fullscreen = true"></i>
        <i class="el-icon-close" @click="cancel"></i>
      </el-col>
    </el-row>
    <el-row
      class="pcEquipmentDetail"
      v-if="tableRow"
      :style="{ fontFamily: fontFamily }"
    >
      <!-- lang:设备名称 -->
      <el-col :span="12">
        {{ $t('equipmentManage.equipmentName') }}：
        <span>{{ tableRow.name }}</span>
      </el-col>
      <!-- lang:所属分组 -->
      <el-col :span="12">
        {{ $t('equipmentManage.equipmentGroup') }}：<span>{{
          tableRow.equipmentGroup.name
        }}</span>
      </el-col>
      <!-- lang:所属部门 -->
      <el-col :span="12">
        {{ $t('equipmentManage.equipmentUserAccountGroup') }}：<span>{{
          tableRow.userAccountGroup.name
        }}</span>
      </el-col>
      <!-- lang:出厂编号 -->
      <el-col :span="12" v-if="tableRow.number">
        {{ $t('equipmentManage.equipmentNumber') }}：<span>{{
          tableRow.number
        }}</span>
      </el-col>
      <!-- lang:设备型号 -->
      <el-col :span="12" v-if="tableRow.model">
        {{ $t('equipmentManage.equipmentModel') }}：<span>{{
          tableRow.model
        }}</span>
      </el-col>
      <!-- lang:设备规格 -->
      <el-col :span="12" v-if="tableRow.specifications">
        {{ $t('equipmentManage.equipmentSpecifications') }}：<span>{{
          tableRow.specifications
        }}</span>
      </el-col>
      <!-- lang:自定义字段 -->
      <el-col :span="12" v-for="(item, index) in customFieldJson" :key="index">
        {{ item.label }}：<span>{{ item.value }}</span>
      </el-col>
      <!-- lang:设备描述 -->
      <el-col v-if="tableRow.detail">
        <div>{{ $t('equipmentManage.equipmentDetail') }}：</div>
        <div class="detailBox" v-html="tableRow.detail"></div>
      </el-col>
      <!-- lang:设备图片 -->
      <el-col v-if="tableRow.imageSet.length">
        <div>{{ $t('equipmentManage.equipmentImageSet') }}：</div>
        <div>
          <ul class="imgList">
            <li
              v-for="item in tableRow.imageSet"
              :key="item.fileName"
              @click="previewMethod(item)"
            >
              <img
                :src="(host || '') + item.fileHost + '/' + item.objectName"
              />
            </li>
          </ul>
        </div>
      </el-col>
      <!-- lang:相关附件 -->
      <el-col v-if="tableRow.fileSet.length">
        <div>{{ $t('equipmentManage.equipmentFileSet') }}：</div>
        <div>
          <ul class="fileList">
            <li
              v-for="item in tableRow.fileSet"
              :key="item.fileName"
              @click="downLoad(item)"
            >
              <el-link :icon="getIcon(item)" :underline="false">
                {{ item.originalName }}
              </el-link>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
    <preview-image v-if="showPreview" :src="previewUrl" :show-name="previewName" @close="closePreview" />
  </base-dialog>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
    // 字体
    fontFamily: {
      type: String,
      default: '',
    },
    // 私有化域名
    host: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customFieldJson: [], // 自定义字段
      showPreview: false,
      previewUrl: '',
      fullscreen: false,
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        if (this.tableRow.customFieldJson) {
          this.customFieldJson = JSON.parse(this.tableRow.customFieldJson)
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 预览
    previewMethod(data) {
      this.previewUrl = (this.host || '') + data.fileHost + '/' + data.objectName
      this.previewName = data.originalName
      this.showPreview = true
    },
    // 关闭预览
    closePreview() {
      this.showPreview = false
    },
    // 获取文件展示图标
    getIcon(item) {
      let icon = 'el-icon-document'
      const { fileName } = item
      if (fileName.indexOf('.mp4') >= 0) icon = 'icon-mp4'
      else if (fileName.indexOf('.pdf') >= 0) icon = 'icon-pdf'
      else if (fileName.indexOf('.npt') >= 0) icon = 'icon-npt'
      return `iconfont ${icon}`
    },
    // 下载
    downLoad(item) {
      const url = (this.host || '') + item.fileHost + '/' + item.objectName
      // windows下载
      if (window.tmvaweb_channel && window.tmvaweb_channel.JSSendNameAndUrl) {
        window.tmvaweb_channel.JSSendNameAndUrl(item.originalName, url)
        return
      }
      const a = document.createElement('a')
      a.download = item.originalName
      a.target = '_blank'
      a.href = url
      a.click()
    },
  },
}
</script>