<template>
  <div class="mainTopNavs">
    <ul class="leftList">
      <li>
        <i
          class="iconfont icon-zhankai"
          :class="{ isFold: $store.state.isFold }"
          @click="updateIsFold"
        />
      </li>
      <li><i class="iconfont icon-quanping" @click="fullscreen" /></li>
      <li v-if="companyName">
        <i style="padding-right: 5px" class="iconfont icon-shenfen" />
        <span class="companyName">{{ companyName }}</span>
      </li>
    </ul>
    <ul class="rightList">
      <li>
        <router-link :to="'/main'" class="back-home">
          <div class="el-dropdown-link">
            <i style="padding-right: 5px" class="iconfont icon-home" />
            {{ $t('base.homePage') }}
          </div>
        </router-link>
      </li>
      <li>
        <el-dropdown placement="bottom" @command="setCommand">
          <div class="el-dropdown-link">
            <i style="padding-right: 5px" class="iconfont icon-set" />
            {{ $t('base.set') }}
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">
              {{ $t('base.quit') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
      <li>
        <el-dropdown placement="bottom" @command="helpCommand">
          <div class="el-dropdown-link">
            <i style="padding-right: 5px" class="iconfont icon-help" />
            {{ $t('base.help') }}
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="contactUs">{{
              $t('base.contactUs')
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
      <li class="borderLine" />
      <li>
        <span class="iconImg faceIcon" />
        <span class="userName">{{ operator.name }}</span>
        <span class="userMobile">（{{ operator.mobile }}）</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>
