// 企业管理
export default {
  title: '会社管理テーブル',
  customTitle: 'カスタムサービス会社配置テーブル',
  name: '会社名称',
  feeMode: '課金モード', // 当前计费模式
  feeModeStr: '利用できる課金モード', // 当前可用计费模式
  industry: '業界',
  customIndustry: '他の業界',
  businessScope: '業務紹介',
  address: '住所',
  addressDetail: '住所詳細',
  contacts: '連絡先',
  contactsInformation: '電話番号',
  email: 'メールアドレス',
  invoice: '請求書発行用情報',
  setInvoiceSuccess: '請求書発行用情報を設定しました',
  invoiceType: '請求書タイプ',
  companyNameForInvoice: '会社名称',
  creditCodeForInvoice: 'creditCode',
  openAccountBankNameForInvoice: '取引銀行',
  openAccountBankAccountForInvoice: '口座',
  openAccountTelNumForInvoice: '銀行電話',
  openAccountBankAddressAddressForInvoice: '取引銀行支店',
  openAccountBankAddressDetailForInvoice: '銀行住所',
  nameOrNotes: '名称/連絡先/電話番号/メールアドレス',
  excelImport: '会社をインポート',
  remainAmount: '残りの数量',
  remainAmountSee: '残高チェック',
  feeModeChangeTips: '課金モードが変更された場合、変更した会社と下の会社は同時に更新され、その上残りの量を全てクリアされます。課金モードを変更しますか？',
  canAddSubCompany: '下級の会社を作成しますか',
  // 私有化企业
  syNameOrNotes: '会社名',
  host: 'ホスト',
  code: '会社番号',
  company: '会社',
  companyPlaceholder: '会社名を入力してください',
  welcomeWord: 'ウェルカムワード',
  contactWord: 'お問合せのテキスト',
  contactLink: 'お問合せのリンク',
  copyright: '著作権情報',
}
