import { resetRouter } from '@/router'
import { EleResize } from '../../utils/esresize'
import bus from '@/utils/bus'
import _, { flatMap } from 'lodash'
import {findProductUsedTimesStatistics, sqlAdjust} from "@/api";
export default {
  data() {
    return {
      isFold: true,
      langData: [
        {
          value: 'zhCN',
          label: 'Chinese(China)-中文(中国)'
        },
        {
          value: 'en',
          label: this.$t('base.en')
        },
        {
          value: 'ja',
          label: this.$t('base.ja')
      }],
      lang:'zhCN',
      headImg:'',
      bindMobileShow:false,
      bindForm: {
        password:'',
        confirmpass:''
      },
      WelcomeText:'',
      zoom:1,
      reconnection: true,
      XR_WORK: 0,
      TMVA: 0,
      KNOWLEDGE: 0,
      WORK_ORDER: 0,
      tmvaRes: null,
      xrWorkRes: null,
      isPermanent: false,
      expiredTime: null,
      showUpdateLicence: false,
    }
  },
  computed: {
    // 操作员信息
    operator() {
       const { operator } = this.$store.state.operator
       return operator || {}
    },
    // 企业名称
    companyName() {
      return this.operator.company && this.operator.company.name
    },
    productConfigEntities() {
      const { productConfigEntities } = this.$store.getters
      return productConfigEntities
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log('to.path', to.path)
    if (
      to.path === '/login' ||
      to.path === '/core' ||
      to.path === '/tmva' ||
      to.path === '/workFlow' ||
      to.path === '/idc'
    ) {
      this.reconnection = false
      this.clearTimer()
      this.closeWs()
    }
    bus.$emit('closeHomeWs')
    next()
  },
  destroyed () {
    this.closeWs()
  },
  mounted() {
    this.initWelcome()
    this.initWebSocket()
    document.addEventListener('click', (e)=> {
      if (e.target.className !== 'drawer' && e.target.className !== 'drawerUserBtn' && e.target.className !== 'el-row' && e.target.className !== 'item' && e.target.className !== 'iconjiantou' && e.target.className !== 'iconame'  && e.target.className !== 'drawerLang' ) {
       //  this.isFold= true
      }
    })
    this.$nextTick(() => {
      const $app = this.$refs.app
      window.addEventListener(
        'resize',
        _.debounce(() => {
          const docHeight = document.body.clientHeight // BODY对象高度
          const docWidth = document.body.clientWidth // BODY对象宽度
          let standarWidth
          if (docHeight / 1920 !== 0 && ((docWidth / 1920) / (docHeight / 1080)) >= 1) {
            standarWidth = docHeight / 1080
          } else {
            standarWidth = docWidth / 1920
          }
          $app.style.zoom = standarWidth
          this.zoom = 1 / standarWidth

          // if (docHeight < 1080) {
          //   const standarWidth = docWidth / 1920
          //   const standarHeight = docHeight / 1080
          //   console.log(standarWidth, standarHeight)
          //   $app.style.zoom = standarHeight
          // }
        })
      );
      if (document.createEvent) {
        var event = document.createEvent("HTMLEvents")
        event.initEvent('resize', true, true)
        window.dispatchEvent(event)
      } else if (document.createEventObject) {
        window.fireEvent("onresize")
      }
    })
    this.initEchartsProduct()
    // this.initEchartsGuie()
    this.findProductUsedTimesStatistics()
    this.serviceResourceFindByCompany()
    this.getLicenceExpiryTime()
  },
  methods: {
    sqlAdjust() {
      this.$ajax({
        url: this.$api.sqlAdjust,
        data: {},
        defineDeal: true
      }).then((data) => {

      })
    },
    uploadLicence() {
      const file = this.$refs.uploadLicence.files[0]
      console.log(file)
      const fd = new FormData()
      fd.append('file', file)
      fd.append('fileName', file.name)
      fd.append('fileFrom', 'SERVER_LICENCE')
      this.$ajax({
        url: this.$api.updateLicence,
        data: fd,
      }).then((data) => {
        this.getLicenceExpiryTime()
      })
    },
    getLicenceExpiryTime() {
      this.$ajax({
        url: this.$api.findLicenceExpiryTime,
        data: {},
      }).then((data) => {
        if (data) {
          this.isPermanent = false
          this.expiredTime = data.expiryDate
          const newTime = (new Date()).getTime()
          const oneFiveDayTime = 60 * 60 * 24 * 15 * 1000
          if (newTime + oneFiveDayTime > data.expiryTime) {
            this.showUpdateLicence = true
          } else {
            this.showUpdateLicence = false
          }
        } else {
          this.isPermanent = true
        }
      })
    },
    getRes(type) {
      let res
      if (type === 'TMVA') {
        res = this.tmvaRes
      }
      if (type === 'XR_WORK') {
        res = this.xrWorkRes
      }
      return res
    },
    serviceResourceFindByCompany() {
      const {company} = this.$store.getters
      const {uid} = company
      console.log('productConfigEntities', this.productConfigEntities)
      if (this.productConfigEntities.filter(item => item.type === 'TMVA').length > 0) {
        this.$ajax({
          url: '/tmva' + this.$api.serviceResourceFindByCompany,
          data: {
            companyUid: uid,
          },
        }).then(res => {
          console.log('res', res)
          const {
            remainCloudRecordingStorageSpace,
            cloudRecordingStorageSpace,
            remainOssStorageSpace,
            ossStorageSpace,
            productKeyNumber,
            remainProductKeyNumber,
            thermalImageryFunctionKeyNumber,
            remainThermalImageryFunctionKeyNumber,
            teamViewerFunctionKeyNumber,
            remainTeamViewerFunctionKeyNumber,
            sensorCollectFunctionKeyNumber,
            remainSensorCollectFunctionKeyNumber,
            endoscopeFunctionKeyNumber,
            remainEndoscopeFunctionKeyNumber,
            remainEquipmentComponentsNumber,
            remainEquipmentNumber,
            equipmentNumber,
            equipmentComponentsNumber,
          } = res
          let tmvaRes = [
            {
              remain: parseFloat(remainCloudRecordingStorageSpace / 1024 / 1024 / 1024).toFixed(
                  4
              ),
              total: parseFloat(cloudRecordingStorageSpace / 1024 / 1024 / 1024).toFixed(
                  4
              ),
              name: this.$t('businessEnum.CLOUD_RECORDING_STORAGE_SPACE'),
              container: 'CLOUD_RECORDING_STORAGE_SPACE',
            },
            {
              remain: parseFloat(remainOssStorageSpace / 1024 / 1024 / 1024).toFixed(
                  4
              ),
              total: parseFloat(ossStorageSpace / 1024 / 1024 / 1024).toFixed(
                  4
              ),
              name: this.$t('businessEnum.OSS_STORAGE_SPACE'),
              container: 'OSS_STORAGE_SPACE',
            },
            {
              remain: remainProductKeyNumber,
              total: productKeyNumber,
              name: this.$t('businessEnum.PRODUCT_KEY_NUMBER'),
              container: 'PRODUCT_KEY_NUMBER',
            },
            {
              remain: remainEquipmentNumber,
              total: equipmentNumber,
              name: this.$t('businessEnum.EQUIPMENT_NUMBER'),
              container: 'EQUIPMENT_NUMBER',
            },
            {
              remain: remainEquipmentComponentsNumber,
              total: equipmentComponentsNumber,
              name: this.$t('businessEnum.EQUIPMENT_COMPONENTS_NUMBER'),
              container: 'EQUIPMENT_COMPONENTS_NUMBER',
            },
            {
              remain: remainThermalImageryFunctionKeyNumber,
              total: thermalImageryFunctionKeyNumber,
              name: this.$t('businessEnum.THERMAL_IMAGERY_FUNCTION_KEY_NUMBER'),
              container: 'THERMAL_IMAGERY_FUNCTION_KEY_NUMBER',
            },
            {
              remain: remainTeamViewerFunctionKeyNumber,
              total: teamViewerFunctionKeyNumber,
              name: this.$t('businessEnum.TEAM_VIEWER_FUNCTION_KEY_NUMBER'),
              container: 'TEAM_VIEWER_FUNCTION_KEY_NUMBER',
            },
            {
              remain: remainSensorCollectFunctionKeyNumber,
              total: sensorCollectFunctionKeyNumber,
              name: this.$t('businessEnum.SENSOR_COLLECT_FUNCTION_KEY_NUMBER'),
              container: 'SENSOR_COLLECT_FUNCTION_KEY_NUMBER',
            },
            {
              remain: remainEndoscopeFunctionKeyNumber,
              total: endoscopeFunctionKeyNumber,
              name: this.$t('businessEnum.ENDOSCOPE_FUNCTION_KEY_NUMBER'),
              container: 'ENDOSCOPE_FUNCTION_KEY_NUMBER',
            }
          ]

          this.tmvaRes = tmvaRes

          this.$nextTick(() => {
            this.tmvaRes.forEach(item => {
              this.drawPieChart(item)
            })
          })
        })
      }
      if (this.productConfigEntities.filter(item => item.type === 'XR_WORK').length > 0) {
        this.$ajax({
          url: '/workFlow' + this.$api.serviceResourceFindByCompany,
          data: {
            companyUid: uid,
          },
        }).then(res => {
          console.log('res', res)
          const {
            remainOssStorageSpace,
            ossStorageSpace,
            productKeyNumber,
            remainProductKeyNumber,
            webEditProductKeyNumber,
            remainWebEditProductKeyNumber,
          } = res
          let xrWorkRes = [
            {
              remain: parseFloat(remainOssStorageSpace / 1024 / 1024 / 1024).toFixed(
                  4
              ),
              total: parseFloat(ossStorageSpace / 1024 / 1024 / 1024).toFixed(
                  4
              ),
              name: this.$t('businessEnum.OSS_STORAGE_SPACE'),
              container: 'XR_OSS_STORAGE_SPACE',
            },
            {
              remain: remainProductKeyNumber,
              total: productKeyNumber,
              name: this.$t('businessEnum.PRODUCT_KEY_NUMBER'),
              container: 'XR_PRODUCT_KEY_NUMBER',
            },
            {
              remain: remainWebEditProductKeyNumber,
              total: webEditProductKeyNumber,
              name: this.$t('businessEnum.WEB_EDIT_PRODUCT_KEY_NUMBER'),
              container: 'XR_WEB_EDIT_PRODUCT_KEY_NUMBER',
            }
          ]

          this.xrWorkRes = xrWorkRes

          this.$nextTick(() => {
            this.xrWorkRes.forEach(item => {
              this.drawPieChart(item)
            })
          })
        })
      }
    },
    findProductUsedTimesStatistics() {
      this.$ajax({
        url: this.$api.findProductUsedTimesStatistics,
        data: {
          isCompany: 'YES',
        },
      }).then(res => {
        this.XR_WORK = res.XR_WORK ? res.XR_WORK : 0
        this.KNOWLEDGE = res.KNOWLEDGE ? res.KNOWLEDGE : 0
        this.TMVA = res.TMVA ? res.TMVA : 0
        this.WORK_ORDER = res.WORK_ORDER ? res.WORK_ORDER : 0
      })
    },
    // 连接心跳
    initWebSocket() {
      const subject_token = this.$util.getSessionStorage('token')
      // 连接socket
      if (!subject_token) return false
      this.ws = new WebSocket(this.$path.websocektHead + this.$api.websocketOperator + '?subject_token=' + subject_token)
      // 连接成功
      this.ws.onopen = () => {
        this.clearTimer()
        this.timer = setInterval(() => {
          this.ws.send(
              JSON.stringify({
                type: 'OPERATOR_HEART_BEAT',
                body: {},
              })
          )
        }, 5000)
      }

      // // 连接关闭
      this.ws.onclose = () => {
        if (!this.reconnection) return
        this.clearTimer()
        this.timer = setTimeout(() => {
          this.initWebSocket()
          this.clearTimer()
        }, 5000)
      }

      bus.$on('closeWs', this.closeWs)
    },
    // 清除定时器
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer)
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    // 清除心跳上报
    closeWs() {
      if (this.ws) {
        this.ws.close()
        this.ws = null
      }
    },
    initWelcome() {
      // 根据时间显示不同问候语
      let timenow = new Date()
      let hours = timenow.getHours()
      let state = ''
      if (hours >= 0 && hours <= 10) {
        state = this.$t('main.goodday')
      } else if (hours > 10 && hours <= 11) {
        state = this.$t('main.goodmorning')
      } else if (hours > 11 && hours <= 13) {
        state = this.$t('main.goodnoon')
      } else if (hours > 13 && hours <= 18) {
        state = this.$t('main.goodafternoon')
      } else if (hours > 18 && hours <= 24) {
        state = this.$t('main.goodevening')
      }
      this.WelcomeText = state
    },
    changeLang(lang) {
      console.log(lang)
      this.lang = lang
      this.$i18n.locale = lang
      this.$util.setLocalStorage('lang', lang)
    },
    showdrawer() {
      this.isFold = false
    },
    getModel(val) {
      let apiprefix = val.toLowerCase()
      console.log(val, apiprefix)
      if (val === 'XR_WORK') {
        apiprefix = 'workFlow'
      }
      if (val === 'TMVA_IDC') {
        apiprefix = 'idc'
      }
      if (apiprefix === 'workFlow') {
        this.$util.setLocalStorage('productType', 'XR_WORK')
      } else if (apiprefix === 'tmva') {
        this.$util.setLocalStorage('productType', 'TMVA')
      } else if (apiprefix === 'core') {
        this.$util.setLocalStorage('productType', 'CORE')
      } else {
        this.$util.setLocalStorage('productType', 'TMVA_IDC')
      }
      this.$util.setLocalStorage('apiprefix', apiprefix)
      this.isFold = true
      // 清空路由
      resetRouter()
      this.$store.dispatch('permission/getPermission', true).then(() => {
        this.$router.push({path: '/' + apiprefix})
        this.$store.commit('tags/updateTags')
      }).catch((e) => {
      })
    },
    logout() {
      this.$element
          .showConfirm({
            content: this.$t('base.quitConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: this.$api.logout,
            })
                .then((data) => {
                  resetRouter()
                  this.$util.deleteLocalStorage('apiprefix')
                  // 清除本地缓存数据
                  this.$store.dispatch('clearSessionInfo').then(() => {
                    this.closeWs()
                    this.$router.replace({path: '/login'})
                  })
                })
          })
      return
    },
    changeLogin({file}) {
      console.log('换头像')
      const formData = new FormData()
      formData.append('file', file)
      formData.append('fileFrom', 'COMPANY_INFO')
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.coreFileupload,
        data: formData,
        // headers,
        showLoading: false,
      })
          .then((data) => {
            console.log(data)
            this.headImg = data.fileHost + '/' + data.objectName
          })
          .catch(() => {
            this.loading = false
          })
    },
    // 图表自动缩放
    drawLine(dom, option) {
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById(dom))
      const resizeDiv = document.getElementById(dom)
      // 绘制图表
      myChart.clear()
      myChart.setOption(option)
      const listener = function () {
        myChart.resize()
      }
      EleResize.on(resizeDiv, listener)
    },
    // 各类产品使用次数趋势
    initEchartsProduct() {
      const myEcharts = 'productBox'
      const option = {
        title: {
          text: this.$t('main.productUsagetrend'),
          x: '40%'
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          x: 'center',
          top: '35px',
          data: ['远程指导', '知识库', '工单', '工作流']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '远程指导',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: '知识库',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: '工单',
            type: 'line',
            stack: 'Total',
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: '工作流',
            type: 'line',
            stack: 'Total',
            data: [320, 332, 301, 334, 390, 330, 320]
          }
        ]
      }
      this.drawLine(myEcharts, option)
    },
    //远程指导资源剩余
    initEchartsGuie() {
      const tt = 70
      const a = 24
      const b = 70
      const myEcharts = 'guideBox'
      const option = {
        title: {
          text: `${tt}%`,
          subtext: `${a}/${b}`,
          textStyle: {
            fonstSize: 12
          },
          subtextStyle: {
            fonstSize: 14
          },
          left: 'center',
          top: 'center',
        },
        tooltip: { // 提示框
          show: false,
          trigger: 'item',
        },
        gtaphic: {},
        series: [
          {
            // name: '账号个数',
            type: 'pie',
            radius: ['90%', '100%'], // 调整环形宽度
            avoidLabelOverlap: false, // 是否启用防止标签重叠策略
            hoverAnimation: false, //移入图形是否放大
            center: ['50%', '50%'],//控制圆位置
            label: {
              show: false,
              position: 'inside'
            },
            labelLine: {
              show: false
            },
            data: [
              {value: a, name: '', itemStyle: {color: '#76B0FE'}},
              {value: b, name: '', itemStyle: {color: '#DCDFE7 '}},
            ]
          }
        ],
      }
      this.drawLine(myEcharts, option)
    },
    //远程指导资源剩余
    drawPieChart(params) {
      let percentage
      if (params.total === 0) {
        percentage = '未授权'
      } else {
        percentage = Math.floor((params.remain / params.total) * 100) + '%'
      }
      const option = {
        title: {
          text: percentage,
          subtext: `${params.remain}/${params.total}`,
          textStyle: {
            fonstSize: 12
          },
          subtextStyle: {
            fonstSize: 14
          },
          left: 'center',
          top: 'center',
        },
        tooltip: { // 提示框
          show: false,
          trigger: 'item',
        },
        gtaphic: {},
        series: [
          {
            // name: '账号个数',
            type: 'pie',
            radius: ['90%', '100%'], // 调整环形宽度
            avoidLabelOverlap: false, // 是否启用防止标签重叠策略
            hoverAnimation: false, //移入图形是否放大
            center: ['50%', '50%'],//控制圆位置
            label: {
              show: false,
              position: 'inside'
            },
            labelLine: {
              show: false
            },
            data: [
              {value: params.remain, name: '', itemStyle: {color: '#76B0FE'}},
              {value: params.total === 0 ? 1 : params.total - params.remain, name: '', itemStyle: {color: '#DCDFE7 '}},
            ]
          }
        ],
      }
      this.drawLine(params.container, option)
    },

    changepassword() {
      this.bindMobileShow = true
    },
    cancel() {
      this.bindMobileShow = false
    },
    confirmPassword() {

    }
  }
}
