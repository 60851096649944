// 版本管理
export default {
  title_1: 'PC版本管理表',
  title_2: '安卓版本管理表',
  forceUpdate: '是否强制更新',
  category: '类型',
  osType: '终端类型',
  versionType: '版本类型',
  title: '标题',
  version: '版本说明',
  versionNumber: '版本号',
  notes: '版本描述',
  appFile: '相关文件',
  instructions: '使用说明文档',
  // 用户相关
  addUser: '添加用户',
  delUser: '删除用户',
  viewUser: '查看用户',
}
