// 全局公用
export default {
  // element.js
  title: '标题',
  content: '内容',
  // ajax.js
  serverException: '服务器异常',
  netWorkError: '网络连接中断，请稍后重试',
  // validate.js
  pleaseEnter: '请输入',
  pleaseSelect: '请勾选',
  pleaseSelect1: '请选择',
  incorrectFormat: '格式不正确',
  numOrLetter: '应为字母或数字',
  letter: '应为字母',
  chinese: '应为中文',
  positiveInt0: '应为大于等于0的整数',
  positiveInt: '应为大于0的整数',
  int: '应为整数',
  positive: '应为大于0的数字',
  positive0: '应为大于等于0的数字',
  len: '长度应为',
  min: '最小长度应为',
  max: '最大长度应为',
  validatePassword: '首字母大写，至少包含数字和特殊字符，8-15个字符',
  // 登录
  welcome: '欢迎使用AR远程售后服务系统-管理后台',
  loginName: '账号',
  password: '密码',
  loginNameHolder: '请输入您的账号',
  passwordHolder: '请输入您的密码',
  login: '登录',
  copyright: 'Copyright © 2021 蒂姆维澳(上海)网络技术有限公司. All rights reserved.',
  // 图片预览
  rotateLeft: '逆时针旋转',
  rotateRight: '顺时针旋转',
  shrink: '缩小',
  restore: '还原',
  amplification: '放大',
  download: '下载',
  close: '关闭',
  // 主页
  homePage: '主页',
  set: '设置',
  help: '帮助',
  quit: '退出',
  quitConfirm: '确定要退出吗',
  contactUs: '联系我们',
  cancel: '取消',
  confirm: '确定',
  tip: '提示',
  name: '名称',
  value: '值',
  enter: '请输入',
  select: '请选择',
  selectTreeNode: '请选择树节点数据',
  selectTableRow: '请选择数据',
  addSuccess: '新增成功',
  editSuccess: '修改成功',
  deleteSuccess: '删除成功',
  sortSuccess: '排序成功',
  distributionSuccess: '分配成功',
  copySuccess: '复制成功',
  moveSuccess: '移动成功',
  deleteConfirm: '确定要删除吗',
  currentSelect: '当前已选择',
  notes: '备注',
  serialNumber: '序号',
  sort: '排序',
  option: '操作',
  searchSuccess: '数据查询成功',
  resetTips: '搜索表单已重置',
  search: '查询',
  reset: '重置',
  all: '全部',
  canUse: '功能是否可用',
  searchStr: '关键字',
  startDate: '开始日期',
  endDate: '结束日期',
  createTime: '创建时间',
  disableConfirm: '确定要禁用吗',
  deactivateConfirm: '确定要停用吗',
  recoverConfirm: '确定要启用吗',
  disableSuccess: '禁用成功',
  refuseSuccess: '拒绝成功',
  deactivateSuccess: '停用成功',
  recoverSuccess: '启用成功',
  hasDisabled: '该数据已禁用',
  hasRecover: '该数据已启用',
  enable: '状态',
  resetConfirm: '确定要重置吗',
  resetSuccess: '重置成功',
  setRoleSuccess: '角色分配成功',
  noData: '暂无数据',
  hasSelect: '已选择项：',
  totalSelect: '共选择',
  item: '条',
  add: '新增',
  edit: '修改',
  del: '删除',
  view: '详情',
  countLimit: '最多只能上传',
  count: '张',
  sizeLimit: '大小超过限制，当前图片大小为',
  fileSizeLimit: '大小超过限制，当前文件大小为',
  maxSize: '最大可上传',
  uploadError: '图片上传失败',
  fileUploadError: '文件上传失败',
  uploadFile: '上传文件',
  fileTypeTitle: '支持',
  fileTypeEnd: '格式文件',
  back: '返回',
  nameOrNotes: '名称/备注',
  drawFile: '将文件拖到此处，或',
  clickUpload: '点击上传',
  importSize: '仅支持本系统提供的.xlsx格式备件模板，且不能超过5M，请',
  downloadTemplate: '点击这里下载模板',
  uploadFileTips: '请上传文件',
  excelImport: 'EXCEL导入',
  importSuccess: '导入成功',
  isImporting: '正在导出文件，请稍后...',
  export: '导出',
  customField: '自定义字段',
  setCustomField: '设置自定义字段',
  writeTips: '请检查内容是否输入完整',
  confirmRollback: '确定要撤回吗',
  rollbackSuccess: '撤回成功',
  mapStart: '起始',
  mapEnd: '结束',
  canAddFirstCate: '版本分类只能新建一级分类',
  zhCN: '简体中文',
  en: '英语',
  ja: '日语',
  justSelectOne: '只能选择一条数据',
  codeErrText: '验证码输入错误',
  validateCode: '请输入',
  companyRegister:'企业注册',
  accountRegister:'用户注册',
  forgotpassword: '忘记密码',
  revocationConfirm: '确定要撤回吗',
  revocationSuccess: '撤回成功',
  renewalSuccess: '续期成功',
  bindMobile:'请输入绑定手机号码',
  bindSuccess:'手机绑定成功',
  closeRegister:'确定关闭注册吗',
  closeRegisterSuccess:'关闭注册成功',
  maxlength:'必须为18位',
  applyTime: '申请时间',
  refuseConfirm: '确定要拒绝吗?',
  url: '格式不正确',
  licenceTip: '许可证有效期至',
  updateLicence: '更新许可证',
  sqlAdjust: '数据库优化',
  nil: '无',
}
