<template>
  <div class="setting">
      <el-button type="primary">下载注册信息</el-button>
      <el-button type="primary">下载迁移信息</el-button>
      <el-button type="primary">更新许可证</el-button>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .setting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .el-button {
      width: 300px;
      margin-bottom: 20px;
    }
  }
</style>
