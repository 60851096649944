export default {
  title: 'Sales information sheet',
  equipment: 'Device information',
  customer: 'Customer information',
  manufactureTime: 'Manufacture date',
  equipmentNos: 'Factory number',
  warrantyTime: 'Quality assurance date',
  quantity: 'Sales amount',
  equipmentName: 'Device name',
  inconformity: 'The sales quantity is inconsistent with the factory numbe',
  sn: 'Sales order',
  nameOrNotes: 'Sales order number/device name/device number/customer name',
  addEquipmentNos: 'Please enter the content and separate it by commas',
  saleCode: 'Sell QR codes',
  useDept: 'Use the department',
  deleteTime: 'Delete time',
}
