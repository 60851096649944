// 终端日志
export default {
  title: 'Terminal log table',
  createTime: 'Creation time',
  type: 'Type',
  company: 'Affiliated companies',
  userAccount: 'Subordinate to the account',
  device: 'Device information',
  file: 'The log file',
}
