<template>
  <div class="basePage">
    <el-container style="height: 100%">
      <!-- 搜索区域 -->
      <slot name="search">
        <el-header height="auto" class="searchBox" v-if="config.length">
          <form-list
            ref="formList"
            :config="config"
            :formData="formData"
            :labelWidth="labelWidth"
            :isSearch="true"
            :gutter="10"
            :search-span="searchSpan"
            :search-align="searchAlign"
            @openDialog="openDialog"
            @search="search"
          />
        </el-header>
      </slot>
      <!-- 表格标题和权限按钮 -->
      <el-header
        v-if="title"
        height="auto"
        class="tableTitle clearfix"
        style="min-height: 60px"
      >
        <h2 class="tableH2">{{ title }}</h2>
        <slot name="functionButton">
          <function-button @functionMethod="functionMethod" />
        </slot>
      </el-header>
      <!-- 表格列表 -->
      <el-main class="tableContent scollBar">
        <slot />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>
