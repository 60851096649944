// 网关管理
export default {
  title: 'ゲートウェイ管理テーブル',
  searchHolder: 'ゲートウェイIDを入力してください',
  account: 'ゲートウェイID',
  status: '現在のログイン状態',
  ipAddress: 'IPアドレス',
  macAddress: 'MACアドレス',
  password: '新しいパスワード',
  confirmPassword: 'パスワードを確認します',
  code: 'ゲートウェイQRコード',
  // 查看操作日志
  operateLog: '操作ログ',
  mac: 'macアドレス',
  type: 'タイプ',
  gatewayId: 'ゲートウェイID',
  companyName: '会社名',
  accountName: 'アカウント名',
  updateTime: '変更時間',
}
