<template>
  <div class="pcEquipmentManage" :style="{ fontFamily: fontFamily }">
    <div class="pcEquipmentManageTree">
      <ul class="treeTabsMk">
        <li
          v-if="permissions.CUSTOM_EQUIPMENT === 'YES'"
          :class="tabType === 'ziyongshebei' ? 'active' : ''"
          @click="changeType('ziyongshebei')"
        >
          {{ $t('equipmentManage.companyEqSelf') }}
        </li>
        <li
          v-if="permissions.EQUIPMENT_MANAGE === 'YES'"
          :class="tabType === 'qiyeshebei' ? 'active' : ''"
          @click="changeType('qiyeshebei')"
        >
          {{ $t('equipmentManage.companyEq') }}
        </li>
        <li
          v-if="permissions.SCOPE_EQUIPMENT_MANAGE === 'YES'"
          :class="tabType === 'pingtaishebei' ? 'active' : ''"
          @click="changeType('pingtaishebei')"
        >
          {{ $t('equipmentManage.platformEq') }}
        </li>
      </ul>
      <div class="treeContentMk">
        <base-tree
          v-if="showContent"
          ref="baseTree"
          :baseUrl="baseUrl"
          :url="treeUrl"
          :headers="headers"
          :defaultParams="treeParams"
          v-model="treeRow"
          @nodeClick="nodeClick"
        ></base-tree>
      </div>
    </div>
    <div class="pcEquipmentManageForm">
      <form-list
        :config="config"
        :formData="formData"
        :is-search="true"
        @search="search"
      />
      <!-- 设备 -->
      <table-list
        v-if="showContent"
        ref="tableList"
        class="eqTableList"
        :baseUrl="baseUrl"
        :url="tableUrl"
        :columns="columns"
        :showSelected="false"
        :headers="headers"
        :defaultParams="tableParams"
        :treeProps="{ children: 'children' }"
        v-model="tableRow"
      >
        <!-- 创建时间 -->
        <template v-slot:createTime="{ row }">
          {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
        </template>
        <!-- 类型 -->
        <template v-slot:level="{ row }">
          {{ levelNames[row.level] }}
        </template>
        <!-- 所属企业 -->
        <template v-slot:company="{ row }">
          {{ row.company && row.company.name }}
        </template>
        <!-- 所属分组 -->
        <template v-slot:equipmentGroup="{ row }">
          {{ row.equipmentGroup.name }}
        </template>
        <!-- 操作 -->
        <template v-slot:option="{ row }">
          <el-button type="text" @click="previewView(row)">
            {{ $t('base.view') }}
          </el-button>
        </template>
      </table-list>
      <!-- 设备 -->
      <equipment-detail
        v-if="showEquipmentDetail"
        :tableRow="tableRow"
        :host="host"
        :fontFamily="fontFamily"
        @closePage="closeEquipmentPage"
      />
      <!-- 部件 -->
      <spare-part-detail
        v-if="showSparePartDetail"
        :tableRow="tableRow"
        :host="host"
        :fontFamily="fontFamily"
        @closePage="closeSparePartPage"
      />
      <!-- 故障信息 -->
      <fault-detail
        v-if="showFaultDetail"
        :tableRow="tableRow"
        :host="host"
        :fontFamily="fontFamily"
        @closePage="closeFaultPage"
      />
    </div>
  </div>
</template>

<script>
import EquipmentDetail from './equipmentDetail'
import SparePartDetail from './sparePartDetail'
import FaultDetail from './faultDetail'

export default {
  components: {
    EquipmentDetail,
    SparePartDetail,
    FaultDetail,
  },
  data() {
    return {
      // 设备分组
      treeRow: null,
      // 搜索
      formData: {},
      config: [],
      // 表格页
      tableRow: null,
      levelNames: {
        ONE: this.$t('equipmentManage.equipmentInfo'),
        TWO: this.$t('equipmentManage.partInfo'),
        THREE: this.$t('equipmentManage.faultInfo'),
      },
      columns: [],
      headers: {
        subject_token: this.$util.getParams(location.href).token,
      },
      // 详情
      showEquipmentDetail: false,
      showSparePartDetail: false,
      showFaultDetail: false,
      // 字体
      fontFamily: 'Microsoft YaHei',
      // 类型
      showContent: false,
      configData: {},
      permissions: {},
      // 动态设置变量
      tabType: '',
      treeUrl: '',
      tableUrl: '',
      treeParams: {},
      tableParams: {},
      host: '',
      baseUrl: '',
    }
  },
  mounted() {
    console.log('--------mountedStart----------')
    this.$nextTick(() => {
      const params = this.$util.getParams(location.href)
      this.fontFamily = 'Microsoft YaHei'
      if (params.lang) this.fontFamily = params.lang
      if (params.language === 'Chinese') {
        this.$i18n.locale = 'zhCN'
        this.$util.setLocalStorage('lang', 'zhCN')
        this.$nextTick(() => {
          this.$i18n.locale = 'zhCN'
          this.setConfig()
        })
      } else if (params.language === 'Japanese') {
        this.$i18n.locale = 'ja'
        this.$util.setLocalStorage('lang', 'ja')
        this.$nextTick(() => {
          this.$i18n.locale = 'ja'
          this.setConfig()
        })
      } else {
        this.$i18n.locale = 'en'
        this.$util.setLocalStorage('lang', 'en')
        this.$nextTick(() => {
          this.$i18n.locale = 'en'
          this.setConfig()
        })
      }
    })
    console.log('--------mountedEnd----------')
  },
  methods: {
    // 设置配置项
    setConfig () {
      console.log('--------setConfigStart----------')
      // 设置搜索配置
      this.config = [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 8,
          tagProps: {
            placeholder: this.$t('equipmentManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 10,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ]
      // 设置表格配置
      this.columns = [
        // lang:类型
        {
          label: this.$t('equipmentManage.type'),
          prop: 'level',
          width: '140px',
        },
        // lang:所属企业
        {
          label: this.$t('equipmentManage.company'),
          prop: 'company',
        },
        // lang:所属分组
        {
          label: this.$t('equipmentManage.equipmentGroup'),
          prop: 'equipmentGroup',
        },
        // lang:所属部门
        {
          label: this.$t('equipmentManage.equipmentUserAccountGroup'),
          prop: 'userAccountGroup',
          callback(row) {
            return row.userAccountGroup?.name
          },
        },
        // lang:名称
        {
          label: this.$t('equipmentManage.name'),
          prop: 'name',
        },
        // lang:编号
        {
          label: this.$t('equipmentManage.number'),
          prop: 'number',
        },
        // lang:型号
        {
          label: this.$t('equipmentManage.model'),
          prop: 'model',
        },
        // lang:规格
        {
          label: this.$t('equipmentManage.specifications'),
          prop: 'specifications',
        },
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '80px',
        },
      ]
      console.log('--------setConfigEnd----------')
      this.getCompanyConfig()
    },
    // 动态获取配置
    setCurrentConfig(tabType) {
      let { company, host } = this.configData
      company = company || {}
      const { beCustom } = company
      // 初始化时，判断显示哪个tab标签项
      if (tabType === 'ziyongshebei') {
        this.tabType = 'ziyongshebei'
        this.treeUrl = '/tmva' + this.$api.customEquipmentGroupWebList
        this.tableUrl = '/tmva' + this.$api.customEquipmentWebFind
        this.treeParams = {}
        this.tableParams = {
          needParentUserAccountGroup: 'YES',
        }
        // 判断是否是私有化企业走oem
        if (beCustom === 'YES') {
          this.host = host
          this.baseUrl = host + this.$path.baseUrlCustom
        } else {
          this.host = ''
          this.baseUrl = ''
        }
      } else if (tabType === 'qiyeshebei') {
        this.tabType = 'qiyeshebei'
        this.treeUrl = '/tmva' + this.$api.equipmentGroupWebList
        this.tableUrl = '/tmva' + this.$api.equipmentWebFind
        this.treeParams = {
          isCompany: 'YES',
        }
        this.tableParams = {
          needParentUserAccountGroup: 'YES',
          isCompany: 'YES',
        }
        this.host = ''
        this.baseUrl = ''
      } else {
        this.tabType = 'pingtaishebei'
        this.treeUrl = '/tmva' + this.$api.equipmentGroupWebList
        this.tableUrl = '/tmva' + this.$api.equipmentWebFind
        this.treeParams = {
          isCompany: 'NO',
        }
        this.tableParams = {
          needParentUserAccountGroup: 'YES',
          isCompany: 'NO',
        }
        this.host = ''
        this.baseUrl = ''
      }
      this.showContent = false
      this.$nextTick(() => {
        this.showContent = true
      })
    },
    // 获取企业配置信息
    getCompanyConfig() {
      console.log('--------getCompanyConfigStart----------')
      console.log('--------parseParams----------')
      const params = this.$util.getParams(location.href)
      console.log('params', params)
      console.log('token', params.token)
      console.log('--------parseParamsComplete----------')
      this.$ajax({
        url: '/tmva' + this.$api.getCompanyConfig,
        headers: {
          subject_token: params.token,
        },
      }).then((data) => {
        const { permissions } = data
        this.permissions = data.permissions
        this.configData = data
        // CUSTOM_EQUIPMENT 自用设备、EQUIPMENT_MANAGE 销售设备、SCOPE_EQUIPMENT_MANAGE 平台设备、
        const {
          CUSTOM_EQUIPMENT,
          EQUIPMENT_MANAGE,
          // SCOPE_EQUIPMENT_MANAGE,
        } = permissions
        // 初始化时，判断显示哪个tab标签项
        if (CUSTOM_EQUIPMENT === 'YES') {
          this.setCurrentConfig('ziyongshebei')
        } else if (EQUIPMENT_MANAGE === 'YES') {
          this.setCurrentConfig('qiyeshebei')
        } else {
          this.setCurrentConfig('pingtaishebei')
        }
      })
    },
    // 切换类型
    changeType(tabType) {
      delete this.formData.groupId
      this.setCurrentConfig(tabType)
    },
    previewView(row) {
      this.tableRow = row
      this.$store.commit('updateCmd', 'view')
      if (row.level === 'ONE') {
        this.showEquipmentDetail = true
        this.$store.commit(
          'updateDialogTitle',
          this.$t('equipmentManage.equipmentDetail_1')
        )
      } else if (row.level === 'TWO') {
        this.showSparePartDetail = true
        this.$store.commit(
          'updateDialogTitle',
          this.$t('equipmentManage.partDetail_1')
        )
      } else {
        this.showFaultDetail = true
        this.$store.commit(
          'updateDialogTitle',
          this.$t('equipmentManage.faultDetail_1')
        )
      }
    },
    closeEquipmentPage() {
      this.showEquipmentDetail = false
    },
    closeSparePartPage() {
      this.showSparePartDetail = false
    },
    closeFaultPage() {
      this.showFaultDetail = false
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      if (isActiveQuery) {
        this.$refs.baseTree.reset()
        delete this.formData.groupId
      }
      const formData = this.$util.copyData(this.formData)
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 选择设备分组
    nodeClick() {
      if (!this.treeRow.children) {
        this.formData.groupId = this.treeRow.id
        this.search()
      }
    },
  },
}
</script>

<style lang="scss">
@import './_index.scss';
</style>
