export default {
    title: '标签列表',
    leftTitle: '标签类型',
    bleType: '蓝牙标签',
    RFIDType: 'RFID标签',
    labelName: '标签名称',
    area: '区域',
    installArea: '安装区域',
    labelType: '类型',
    labelID: '标签ID',
    installAddress: '安装位置',
    discoveredName: '被发现名称',
    RSSI0_5: 'RSSI:0.5m内',
    RSSI1_0: 'RSSI:1.0m内',
    RSSI1_5: 'RSSI:1.5m内',
    LOBAT: '电池余量',
    sitePhotos: '现场照片',
    remarks: '备注',
}
