// AR设备地址池
export default {
  title: 'AR device address pool table',
  arType: 'Type',
  code: 'AR code',
  notes: 'Notes',
  nameOrNotes: 'AR code/notes',
  eqCode: 'Equipment code',
  eqCodeList: 'Device code table',
  addArPool: 'Add the device to the AR address pool',
  code64: 'Code (64)',
  code32: 'Code (32)',
  writeEqCode: 'Fill in the device code',
}
