export default {
    title: '企业数据管理',
    name: '名称',
    mark: '描述',
    relationApi: '关联接口',
    fieldName: '字段名称',
    relationField: '关联字段',
    fieldType: '字段类型',
    fieldUnit: '字段单位',
    fieldUnitDes: '单位描述',
    createTime: '添加时间',
    updateTime: '修改时间',
}
