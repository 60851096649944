// 部门管理
export default {
  title: '企业用户账号表',
  title_platform: '平台用户账号表',
  groupTitle: '企业部门分组',
  groupTitle_platform: '平台部门分组',
  addFirstGroup: '新增',
  name: '部门名称',
  topName: '上级部门',
  level: '所属层级',
  accountGroup: '所属部门',
}
