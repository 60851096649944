export default {
  name: 'tableList',
  props: {
    url: {
      // 请求地址
      type: String,
      default: '',
    },
    defaultParams: {
      // 请求参数
      type: Object,
      default: () => null,
    },
    value: {
      // 默认值，单选为对象，多选为数组，例如：{id: 1, name: '姓名'}或者[{id: 1, name: '姓名'}]
      type: Object | Array,
      default: () => null,
    },
    isDefine: {
      // 当前是否是自定义数据
      type: Boolean,
      default: false,
    },
    defaultContent: {
      // 自定义数据
      type: Array,
      default: () => [],
    },
    defaultPage: {
      // 默认开始页数
      type: Number,
      default: 1,
    },
    defaultSize: {
      // 如果有分页，每页请求的条数
      type: Number,
      default: 10,
    },
    pagination: {
      // 是否显示分页
      type: Boolean,
      default: true,
    },
    rowKey: {
      // 表格行 key 的取值
      type: String,
      default: 'id',
    },
    rowName: {
      // 表格行展示的名称
      type: String,
      default: 'name',
    },
    columns: {
      // 表格展示字段
      type: Array,
      default: () => [],
    },
    multiple: {
      // 是否为多选树
      type: Boolean,
      default: false,
    },
    highlightCurrentRow: {
      // 是否高亮当前选中节点，如果multiple为true，此属性不起作用
      type: Boolean,
      default: true,
    },
    align: {
      // 表格底部分页对其方式
      type: String,
      default: 'right',
    },
    treeProps: {
      // 如果为树形表格，则树得配置项
      type: Object,
      default: () => ({
        children: 'nochildren',
      }),
    },
    defaultExpandAll: {
      // 是否默认展开所有行
      type: Boolean,
      default: false,
    },
    expandRowKeys: {
      // 可以通过该属性设置 Table 目前的展开行
      type: Array,
      default: () => [],
    },
    showOverflowTooltip: {
      // 表格超出是否显示省略号
      type: Boolean,
      default: true,
    },
    autoLoad: {
      // 是否自动加载
      type: Boolean,
      default: true,
    },
    showSort: {
      // 是否显示序号
      type: Boolean,
      default: true,
    },
    showSelected: {
      // 是否显示已选项
      type: Boolean,
      default: true,
    },
    headers: {
      // 请求头
      type: Object,
      default: () => ({}),
    },
    baseUrl: {
      // 定制化请求头
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      page: this.defaultPage,
      size: this.defaultSize,
      content: [],
      totalElements: 0,
      hasSelectValue: '',
      selectedRowId: '',
    }
  },
  computed: {
    // 已经选中的数据
    hasSelectRows() {
      this.hasSelectValue = ''
      console.log(this.value)
      if (this.multiple) {
        if (!this.value.length) return []
        this.hasSelectValue = this.value[0][this.rowKey]
        return this.value
      }
      if (!this.value) return []
      this.hasSelectValue = this.value[this.rowKey]
      return [this.value]
    },
  },
  watch: {
    // 数据源发生变化，重置选中数据
    defaultContent(val) {
      this.content = val
      this.setDefaultValue()
    },
  },
  mounted() {
    // 自定义数据
    if (this.isDefine) {
      this.content = this.defaultContent
      this.setDefaultValue()
      return
    }
    // 后台请求数据
    if (this.autoLoad) this.getList()
  },
  methods: {
    // 查询数据
    getList(searchParams = {}, isActiveQuery) {
      if (!this.url) return
      const params = {}
      // 分页参数
      if (this.pagination) {
        params.pageNumber = this.page
        params.pageSize = this.size
      }
      // 默认参数
      if (this.defaultParams) Object.assign(params, this.defaultParams)
      // 查询参数
      for (const key in searchParams) {
        let item = searchParams[key]
        if (item && item !== 'ALL') {
          if (key === 'createTime') {
            if (item.length && item[1]) {
              params.startCreateTime = new Date(item[0] + ' 00:00:00').getTime()
              params.endCreateTime = new Date(item[1] + ' 23:59:59').getTime()
            }
          } else {
            params[key] = item
          }
        }
      }
      // 请求数据
      this.$ajax({
        baseUrl: this.baseUrl,
        url: this.url,
        data: params,
        headers: this.headers,
      })
        .then((data) => {
          console.log(data)
          // 处理数据
          if (this.pagination) {
            const { content, totalElements } = data
            this.content = content
            this.totalElements = totalElements
          } else {
            this.content = data
          }
          this.setDefaultValue()
          this.loaded = true
          if (isActiveQuery) this.$element.showMsg(this.$t('base.searchSuccess'), 'success')
        })
        .catch(() => {
          this.loaded = true
        })
    },
    // 页码改变的回调
    currentChange(page) {
      this.page = page
      this.getList(this.cacheSearchParams || {})
    },
    // 条数改变的回调
    sizeChange(size) {
      this.size = size
      this.getList(this.cacheSearchParams || {})
    },
    // 根据条件查询数据
    searchList(searchParams, isActiveQuery) {
      if (!this.url) return
      if (this.multiple) this.$emit('input', [])
      else this.$emit('input', null)
      this.page = 1
      // 缓存查询参数
      this.cacheSearchParams = this.$util.copyData(searchParams || {})
      this.getList(searchParams, isActiveQuery)
    },
    // 清除选中数据
    reset() {
      const tableList = this.$refs.tableList
      if (this.multiple) {
        tableList.clearSelection()
        this.$emit('input', [])
      } else {
        tableList.setCurrentRow()
        this.$emit('input', null)
      }
    },
    // 自定义索引
    indexMethod(index) {
      return this.size * (this.page - 1) + index + 1
    },
    // 获取当前选中数据
    rowClick(row) {
      if (this.multiple || !this.highlightCurrentRow) return
      this.$emit('input', row)
      this.$emit('rowClick', row)
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(_, row) {
      if (!this.multiple) return
      const value = this.$util.copyData(this.value)
      const index = value.findIndex((item) => {
        return `${item[this.rowKey]}` === `${row[this.rowKey]}`
      })
      if (index >= 0) {
        value.splice(index, 1)
      } else {
        value.push(row)
      }
      this.$emit('input', value)
    },
    // 当用户手动勾选全选 Checkbox 时触发的事件
    selectAll(selection) {
      if (!this.multiple) return
      const value = this.$util.copyData(this.value)
      this.content.forEach((item) => {
        const index = value.findIndex((list) => {
          return `${list[this.rowKey]}` === `${item[this.rowKey]}`
        })
        if (selection.length) {
          if (index < 0) value.push(item)
        } else {
          if (index >= 0) value.splice(index, 1)
        }
      })
      this.$emit('input', value)
    },
    // 设置默认选行
    setDefaultValue() {
      if (this.multiple) {
        this.setMultipleDefault()
      } else {
        this.setSingleDefault()
      }
    },
    // 设置多选树默认值
    setMultipleDefault() {
      this.$nextTick(() => {
        const tableList = this.$refs.tableList
        tableList.clearSelection()
        // 清除选中数据
        if (!this.value.length) return
        // 设置选中数据
        this.content.forEach((item) => {
          this.value.forEach((list) => {
            if (`${list[this.rowKey]}` === `${item[this.rowKey]}`) {
              tableList.toggleRowSelection(item, true)
            }
          })
        })
      })
    },
    // 设置单选树默认值
    setSingleDefault() {
      this.$nextTick(() => {
        const tableList = this.$refs.tableList
        // 清除选中数据
        if (!this.value) {
          tableList && tableList.setCurrentRow()
          return
        }
        // 设置选中数据
        for (let i = 0; i < this.content.length; i++) {
          const row = this.content[i]
          if (`${row[this.rowKey]}` === `${this.value[this.rowKey]}`) {
            tableList.setCurrentRow(row)
            break
          }
        }
      })
    },
    // 或许展示名称
    getRowName(item) {
      const rowNames = this.rowName.split('.')
      if (rowNames.length > 1) {
        let showName = item
        rowNames.forEach((list) => {
          showName = showName[list]
        })
        return showName
      } else {
        return item[this.rowName]
      }
    },
  },
}
