// 功能管理
export default {
  title: '功能管理表',
  moveUp: '上移',
  moveDown: '下移',
  permissionEnum: '名称枚举',
  permissionType: '功能类型',
  method: '方法名',
  apiSet: '放行接口',
  icon: '图标',
  url: '页面路径',
  addButtonError: '按钮功能无法新增下级',
  shouldBeBasic: '是否隐藏右上角权限按钮',
}
