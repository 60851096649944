export default {
  title: '販売情報テーブル',
  equipment: 'デバイス情報',
  customer: 'お客様情報',
  manufactureTime: '出荷日',
  equipmentNos: '出荷番号',
  warrantyTime: '保証日',
  quantity: '販売数量',
  equipmentName: 'デバイス名称',
  inconformity: '販売数量と出荷番号の数量は一致していません',
  sn: 'セールス・ナンバー',
  nameOrNotes: 'セールス・ナンバー/デバイス名称/デバイス番号/お客様名称',
  addEquipmentNos: '内容を入力し、英語のコンマで内容を分割してください',
  saleCode: '販売QRコード',
  useDept: '使用部署',
  deleteTime: '削除時間',
}
