import { coreFileupload } from '@/api'

export default {
  name: 'documentUpload',
  props: {
    action: {
      // 上传地址
      type: String,
      default: coreFileupload,
    },
    // value: {
    //   // 绑定和提交内容
    //   type: Array,
    //   default: () => [],
    // },
    accept: {
      // 可上传的文件类型
      type: String,
      default: '.mp4,.pdf,.npt',
    },
    size: {
      // 文件最大尺寸，单位KB，0表示不限制
      type: Number,
      default: 0,
    },
    limit: {
      // 最大允许上传个数，0表示不限制
      type: Number,
      default: 1,
    },
    disabled: {
      // 是否禁用
      type: Boolean,
      default: false,
    },
    baseUrl: {
      // 定制化请求头
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      showPreview: false,
      previewUrl: '',
      previeName: '',
    }
  },
  methods: {
    // 获取文件展示图标
    getIcon(item) {
      let icon = 'el-icon-document'
      const { fileName } = item
      if (fileName.indexOf('.mp4') >= 0) icon = 'icon-mp4'
      else if (fileName.indexOf('.pdf') >= 0) icon = 'icon-pdf'
      else if (fileName.indexOf('.npt') >= 0) icon = 'icon-npt'
      return `iconfont ${icon}`
    },
    // 上传前
    beforeUpload(file) {
      // 判断最大格式
      // if (this.limit && this.value.length >= this.limit) {
      //   this.$element.showMsg(`${this.$t('base.countLimit')}${this.limit}${this.$t('base.count')}`, 'warning')
      //   return false
      // }
      // const size = +parseFloat(file.size / 1024).toFixed(2)
      // if (this.size && size >= this.size) {
      //   this.$element.showMsg(`${this.$t('base.fileSizeLimit')}${size}KB，${this.$t('base.maxSize')}${this.size}KB！`, 'warning', 5000)
      //   return false
      // }
      this.loading = true
    },
    // 处理请求错误信息
    showErrorMsg(msg) {
      this.$element.showAlert({
        content: msg,
      })
    },
    // 上传成功
    httpRequest({ file }) {
      const size = +parseFloat(file.size / 1024 / 1024).toFixed(2)
      const baseUrl = this.baseUrl
      const formData = new FormData()
      formData.append('file', file)
      const headers = {
        'Content-Type': 'multipart/form-data',
        subject_token: this.$util.getSessionStorage('token'),
      }
      formData.append('file', file)
      formData.append('fileFrom', 'DOCUMENT_MANAGEMENT')
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + coreFileupload,
        data: formData,
        showLoading: false,
      })
        .then((data) => {
          this.loading = false
          this.previewUrl = data.fileHost + '/' + data.objectName
          this.previeName = data.originalName
          this.$emit('input', data)
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 上传失败
    onError() {
      this.loading = false
      this.$element.showMsg(this.$t('base.fileUploadError'), 'warning')
    },
    // 删除
    deleteMethod(index) {
      const fileList = this.$util.copyData(this.value)
      fileList.splice(index, 1)
      this.$emit('input', fileList)
    },
  },
}
