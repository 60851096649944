<template>
  <base-dialog
    ref="baseDialog"
    :title="title"
    :width="width"
    :height="height"
    :showConfirm="showConfirm"
    :center="center"
    :showClose="showClose"
    @cancel="cancel"
    @confirm="confirm"
  >
    <slot name="top" />
    <form-list
      ref="formList"
      :config="config"
      :formData="formData"
      :labelWidth="labelWidth"
      :gutter="gutter"
      :disabled="disabled"
      @openDialog="openDialog"
    >
      <template v-for="(item, index) in config" v-slot:[item.prop]>
        <!-- 自定义表单 -->
        <slot :name="item.prop" :item="item" :index="index" />
      </template>
      <template v-for="(item, index) in config" v-slot:[`${item.prop}-label`]>
        <!-- 自定义标题 -->
        <slot :name="`${item.prop}-label`" :item="item" :index="index">
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.label"
            placement="top-start"
          >
            <span>{{ item.label }}：</span>
          </el-tooltip>
        </slot>
      </template>
    </form-list>
    <template v-slot:footer>
      <slot name="bottom" />
    </template>
  </base-dialog>
</template>>

<script>
import Index from './_index.js'
export default Index
</script>
