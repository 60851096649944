<template>
  <el-tree
    v-if="loaded"
    class="baseTree"
    ref="baseTree"
    :data="content"
    :node-key="nodeKey"
    :props="defaultProps"
    :highlight-current="highlightCurrentRow && !multiple"
    :expand-on-click-node="expandOnClickNode"
    :default-expand-all="defaultExpandAll"
    :default-expanded-keys="newDefaultExpandedKeys"
    :show-checkbox="multiple"
    @node-click="nodeClick"
    @check="check"
  >
    <template v-slot:default="{ node, data }">
      <slot :node="node" :data="data">
        {{ data[defaultProps.label] }}
      </slot>
    </template>
  </el-tree>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>