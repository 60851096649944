// 账号管理
export default {
  title: 'Administrator account table',
  title_user: 'Company user account table',
  title_platform: 'Platform user account table',
  exportUsers: 'Enterprise user account number',
  exportPlatfromUsers: 'Platform user account number',
  name: 'Name',
  mobile: 'Mobile phone number',
  loginName: 'Login name',
  password: 'Password',
  type: 'Account type',
  company: 'Affiliated company',
  nickname: 'Nickname',
  accountGroup: 'Subordinate department',
  subscribeType: 'Subscription mode',
  serviceProvide: 'Whether to support personnel online',
  scope: 'Visible range',
  role: 'Role',
  canAddOutsideUserAccount: 'Whether external contacts can be added',
  shouldRemoveOutsideUserAccount: 'Whether to empty external contacts',
  nameOrNotes: 'Login/name/mobile phone number/nickname',
  renew: 'Renew',
  renewConfirm: 'Are you sure you want to renew?',
  renewSuccess: 'The renewal was successful',
  changeTips: 'This account is not an enterprise platform account and cannot be operated',
  track: 'Track map',
  companyInfo: 'Company information',
  renewNumber: 'Subscribe to the number of years',
  normal: 'Normal',
  pastDue: 'Overdue',
  inteirm: 'In the period',
  nextSubscribeTime: 'Valid until',
}
