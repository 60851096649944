<template>
  <div class="pageTabs">
    <el-tabs :value="activeTag" @tab-click="tabClick" @tab-remove="deleteTags">
      <el-tab-pane name="INDEX">
        <template slot="label">
          <i class="iconfont icon-home" /> {{ $t('base.homePage') }}
        </template>
      </el-tab-pane>
      <el-tab-pane
        v-for="item in tags"
        :key="item.permissionEnum"
        :name="item.permissionEnum"
        closable
      >
        <template slot="label">{{ item.label }}</template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>
