// 日志管理
export default {
  title: 'ログ管理テーブル',
  createBy: '作成者',
  loginName: 'アカウント',
  recordStartTime: 'レコードの開始時間',
  recordEndTime: 'レコードの終了時間',
  recordSpentTime: 'レコード時間(ms)',
  contextFrom: 'コンテキストの発信者',
  seq: 'コンテキスト番号',
  subjectToken: '会話token',
  apiModule: 'APIモジュール',
  apiModuleDescription: 'APIモジュール説明',
  api: 'API',
  apiDescription: 'API説明',
  hasException: '異常があるかどうか',
  exceptionName: '異常名称のフルパス',
  exceptionSimpleName: '異常名称',
  exception: '異常が発生しました',
  requestParameters: 'パラメータjsonを要求',
  responseParameters: 'パラメータjsonへ応答',
  jdbcEventDocuments: '要求されたsql実行情報',
  slowQuery: 'slow sqlはあるかどうか',
  recordSpentTimeGte: 'コンテキスト時間≥(ms)',
}
