// 中文
import base from './base'
import serverCode from './serverCode'
import functionEnum from './functionEnum'
import businessEnum from './businessEnum'
// 注册页面
import reg from './reg'
import main from './main'
// 页面级
import index from './views/index'
import functionManage from './views/systemManage/functionManage'
import dictManage from './views/systemManage/dictManage'
import messageManage from './views/systemManage/messageManage'
import roleManage from './views/roleManage'
import adminAccount from './views/accountManage/adminAccount'
import companyManage from './views/companyManage'
import logManage from './views/systemManage/logManage'
import assignDistribute from './views/assignDistribute'
import distributeBills from './views/financeManage/distributeBills'
import serviceResourceRecord from './views/financeManage/serviceResourceRecord'
import equipmentManage from './views/equipmentManage/equipmentManage'
import saleManage from './views/equipmentManage/saleManage'
import customerManage from './views/customerManage'
import arPoolManage from './views/poolManage/arPoolManage'
import gatewayPoolManage from './views/poolManage/gatewayPoolManage'
import arDigitalManage from './views/arDigitalManage'
import userGroup from './views/divisionManage/userGroup'
import personalInfo from './views/infoManage/personalInfo'
import companyInfo from './views/infoManage/companyInfo'
import gatewayManage from './views/gatewayManage'
import versionManage from './views/systemManage/versionManage'
import terminalLog from './views/systemManage/terminalLog'
import terminalActiveCode from './views/terminalActiveCode'
import documentManage from './views/systemManage/documentManage'
import serverManage from './views/serverManage'
import registerManage from './views/registerManage'
import keyDistribution from './views/authorizationManage/keyDistribution'
import accountPermissionManage from './views/permissionManage/accountPermissionManage'

export default {
  base,
  serverCode,
  functionEnum,
  businessEnum,
  index,
  functionManage,
  dictManage,
  messageManage,
  roleManage,
  adminAccount,
  companyManage,
  logManage,
  assignDistribute,
  distributeBills,
  serviceResourceRecord,
  equipmentManage,
  saleManage,
  customerManage,
  arPoolManage,
  gatewayPoolManage,
  arDigitalManage,
  userGroup,
  personalInfo,
  companyInfo,
  gatewayManage,
  versionManage,
  terminalLog,
  terminalActiveCode,
  reg,
  main,
  serverManage,
  documentManage,
  registerManage,
  keyDistribution,
  accountPermissionManage,
}
