// 日志管理
export default {
  title: '日志管理表',
  createBy: '创建人',
  loginName: '账号',
  recordStartTime: '开始记录时间',
  recordEndTime: '记录结束时间',
  recordSpentTime: '所用时长(ms)',
  contextFrom: '会话来源',
  seq: '会话编号',
  subjectToken: '会话token',
  apiModule: '接口模块',
  apiModuleDescription: '接口模块说明',
  api: '接口',
  apiDescription: '接口说明',
  hasException: '是否发生异常',
  exceptionName: '异常名称完整路径',
  exceptionSimpleName: '异常名称',
  exception: '发生异常信息',
  requestParameters: '请求参数json',
  responseParameters: '响应参数json',
  jdbcEventDocuments: '请求设计的sql执行信息',
  slowQuery: '是否有慢sql',
  recordSpentTimeGte: '会话耗时大于等于(ms)',
}
