// 菜单权限
import ajax from '@/utils/ajax'
import { getCurrentPermission } from '@/api'
import { setSessionStorage, getSessionStorage, deleteSessionStorage, getLocalStorage} from '@/utils'
import router from '@/router'
import i18n from '@/lang'
import Home from '@/layout/home'

export default {
  namespaced: true,
  state: {
    permission: getSessionStorage('permission') || [], // 页面路由
  },
  mutations: {
    // 添加权限数据
    addPermission(state, permission) {
      state.permission = permission
      setSessionStorage('permission', permission)
    },
    // 刪除权限数据
    deletePermission(state) {
      state.permission = []
      deleteSessionStorage('permission')
    },
  },
  actions: {
    // 异步注册路由
    asyncAddRoutes(_, permission) {
      const routes = []
      const handleRoutes = (data) => {
        if (!data || !data.length) return
        data.forEach((item) => {
          item.name = i18n.t(`functionEnum.${item.permissionEnum}`)
          if (item.url) {
            routes.push({
              path: item.url,
              name: item.url,
              component: () => import(`@/views${item.url}`),
            })
          }
          const { children } = item
          if (children && children.length) handleRoutes(children)
        })
      }
      handleRoutes(permission)
      router.addRoutes([
        {
          path: '/'+ getLocalStorage('apiprefix'),
          component: Home,
          children: routes,
        },
      ])
    },
    // 获取权限数据
    getPermission({ commit, dispatch, state }, isLogin) {
      console.log(getLocalStorage('apiprefix'));
      return new Promise((resolve, reject) => {
        if (state.permission.length && !isLogin) {
          dispatch('asyncAddRoutes', state.permission).then(() => {
            resolve()
          })
          return
        }
        // 私有化配置处理
        ajax({
          url: getLocalStorage('apiprefix') + '/' + getCurrentPermission,
        })
          .then((result) => {
            const permission = result ? result.children || [] : []
            commit('addPermission', permission)
            dispatch('asyncAddRoutes', permission).then(() => {
              resolve()
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
