<template>
  <div class="tinymceWrap" :style="{ width: width ? `${width}px` : 'auto' }">
    <textarea class="tinymceTextarea" :id="tinymceId" />
    <div class="disabledMask" :class="{ isDisabled: disabled }"></div>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>