// 账号管理
export default {
  title: '管理员账号表',
  title_user: '企业用户账号表',
  title_platform: '平台用户账号表',
  exportUsers: '企业用户账号',
  exportPlatfromUsers: '平台用户账号',
  name: '姓名',
  mobile: '手机号',
  loginName: '登录名',
  password: '密码',
  type: '账号类型',
  company: '所属企业',
  nickname: '昵称',
  accountGroup: '所属部门',
  subscribeType: '订阅模式',
  serviceProvide: '是否在线支持人员',
  scope: '可见范围',
  role: '角色',
  canAddOutsideUserAccount: '是否可添加外部联系人',
  shouldRemoveOutsideUserAccount: '是否清空外部联系人',
  nameOrNotes: '登录名/姓名/手机号/昵称',
  renew: '续订',
  renewConfirm: '确定要续订吗',
  renewSuccess: '续订成功',
  changeTips: '该账户不是本企业平台账户，无法操作',
  track: '轨迹图',
  companyInfo: '企业信息',
  renewNumber: '订阅年数',
  normal: '正常',
  pastDue: '过期',
  inteirm: '临期',
  nextSubscribeTime: '有效期至',
  terminalPower: '终端权限',
  code: "验证码",
  codeText: "请输入验证码",
  getCode:"获取验证码",
  sendCode:"已发送",
  modifyPass: '修改密码',
  nextStep: '下一步',
  mobileErr:"手机号格式不对",
  mobileTxt:"请输入手机号",
  bindMobile:"绑定手机号",
  bindMobileSec:"手机号绑定成功"
}
