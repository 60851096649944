<template>
  <base-dialog
    class="dialogImport"
    :title="title"
    :width="width"
    :height="height"
    @cancel="cancel"
    @confirm="confirm"
  >
    <el-upload
      ref="upload"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.4)"
      action=""
      :accept="accept"
      :show-file-list="false"
      :http-request="httpRequest"
      :before-upload="beforeUpload"
      drag
    >
      <div class="importBox">
        <div class="empty">
          <template v-if="file">
            <i class="iconfont icon-excel" />
            <p>{{ file.name }}</p>
          </template>
          <template v-else>
            <img src="./img/import.png" />
          </template>
        </div>
        <div class="importLink">
          <span>{{ $t('base.drawFile') }}</span>
          <el-link type="primary">{{ $t('base.clickUpload') }}</el-link>
        </div>
      </div>
    </el-upload>
    <div v-if="showTips" class="tips">
      {{ $t('base.importSize') }}
      <el-link v-if="!defineDownDeal"  type="primary" target="_blank" :href="template">
        {{ $t('base.downloadTemplate') }}
      </el-link>
      <el-link v-if="defineDownDeal"  type="primary" target="_blank" @click="$emit('downTemplate', false)">
        {{ $t('base.downloadTemplate') }}
      </el-link>
    </div>
    <slot />
  </base-dialog>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>
