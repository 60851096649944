import i18n from '@/lang'

export default {
  name: 'dialogTableList',
  props: {
    // 弹出框配置项-------------
    title: {
      // 显示标题
      type: String,
      default: '',
    },
    width: {
      // 弹出框宽度
      type: String,
      default: '1000px',
    },
    height: {
      // 弹出框高度
      type: String,
      default: '485px',
    },
    showConfirm: {
      // 是否显示确定按钮
      type: Boolean,
      default: true,
    },
    center: {
      // 底部操作按钮是否左右居中
      type: Boolean,
      default: true,
    },
    showClose: {
      // 是否显示右上角关闭按钮
      type: Boolean,
      default: true,
    },
    // 弹出表格配置---------------
    url: {
      // 请求地址
      type: String,
      default: '',
    },
    defaultParams: {
      // 请求参数
      type: Object,
      default: () => null,
    },
    value: {
      // 默认值，单选为对象，多选为数组，例如：{id: 1, name: '姓名'}或者[{id: 1, name: '姓名'}]
      type: Object | Array,
      default: () => null,
    },
    isDefine: {
      // 当前是否是自定义数据
      type: Boolean,
      default: false,
    },
    defaultContent: {
      // 自定义数据
      type: Array,
      default: () => [],
    },
    defaultPage: {
      // 默认开始页数
      type: Number,
      default: 1,
    },
    defaultSize: {
      // 如果有分页，每页请求的条数
      type: Number,
      default: 10,
    },
    pagination: {
      // 是否显示分页
      type: Boolean,
      default: true,
    },
    rowKey: {
      // 表格行 key 的取值
      type: String,
      default: 'id',
    },
    rowName: {
      // 表格行展示的名称
      type: String,
      default: 'name',
    },
    columns: {
      // 表格展示字段
      type: Array,
      default: () => [],
    },
    multiple: {
      // 是否为多选树
      type: Boolean,
      default: false,
    },
    highlightCurrentRow: {
      // 是否高亮当前选中节点，如果multiple为true，此属性不起作用
      type: Boolean,
      default: true,
    },
    align: {
      // 表格底部分页对其方式
      type: String,
      default: 'right',
    },
    treeProps: {
      // 如果为树形表格，则树得配置项
      type: Object,
      default: () => ({
        children: 'nochildren',
      }),
    },
    defaultExpandAll: {
      // 是否默认展开所有行
      type: Boolean,
      default: false,
    },
    expandRowKeys: {
      // 可以通过该属性设置 Table 目前的展开行
      type: Array,
      default: () => [],
    },
    showOverflowTooltip: {
      // 表格超出是否显示省略号
      type: Boolean,
      default: true,
    },
    config: {
      // 搜索表单配置项
      type: Array,
      default: () => [
        {
          label: i18n.t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: i18n.t('base.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: i18n.t('base.createTime'),
          prop: 'createTime',
          span: 10,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: i18n.t('base.startDate'),
            endPlaceholder: i18n.t('base.endDate'),
          },
        },
      ],
    },
    formData: {
      // 绑定数据
      type: Object,
      default: () => ({}),
    },
    baseUrl: {
      // 定制化请求头
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: this.value ? this.$util.copyData(this.value) : null,
    }
  },
  watch: {
    // 选中数据发生变化时，同步更新上个页面
    tableData(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    // 弹框取消
    cancel() {
      this.$emit('cancel')
    },
    // 弹框确定
    confirm() {
      if ((!this.multiple && !this.tableData) || (this.multiple && !this.tableData.length)) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      this.$emit('confirm', this.tableData)
    },
    // 获取当前选中数据
    rowClick(row) {
      this.$emit('rowClick', row)
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(rows) {
      this.$emit('select', rows)
    },
    // 当用户手动勾选全选 Checkbox 时触发的事件
    selectAll(rows) {
      this.$emit('selectAll', rows)
    },
    // 根据条件查询数据
    searchList(searchParams) {
      this.$refs.tableList.searchList(searchParams, true)
    },
    // 搜索
    search() {
      this.$emit('search')
    },
    // 重置
    reset() {
      this.$refs.tableList.reset()
    },
  },
}
