<template>
  <div class="previewImage" @mousemove.stop="mousemove" @mouseup.stop="mouseup">
    <img
      v-if="showSrc"
      id="currentImg"
      class="currentImg"
      :src="showSrc"
      :style="{
        transform: `rotate(${rotate}deg) scale(${scale})`,
        left: left + 'px',
        top: top + 'px',
      }"
      @mousedown.stop.prevent="mousedown"
    />
    <div class="optionButton">
      <div @click="rotateLeft">
        <!-- lang:逆时针旋转 -->
        <el-tooltip placement="top" :content="$t('base.rotateLeft')">
          <i class="el-icon-refresh-left" />
        </el-tooltip>
      </div>
      <div @click="rotateRight">
        <!-- lang:顺时针旋转 -->
        <el-tooltip placement="top" :content="$t('base.rotateRight')">
          <i class="el-icon-refresh-right" />
        </el-tooltip>
      </div>
      <div @click="scaleReduce">
        <!-- lang:缩小 -->
        <el-tooltip placement="top" :content="$t('base.shrink')">
          <i class="el-icon-minus" />
        </el-tooltip>
      </div>
      <div class="showValue" @click="resetScale">
        <!-- lang:还原 -->
        <el-tooltip placement="top" :content="$t('base.restore')">
          <span> {{ Math.floor(scale * 100) }}% </span>
        </el-tooltip>
      </div>
      <div @click="scaleAdd">
        <!-- lang:放大 -->
        <el-tooltip placement="top" :content="$t('base.amplification')">
          <i class="el-icon-plus" />
        </el-tooltip>
      </div>
      <div @click="downLoad">
        <!-- lang:下载 -->
        <el-tooltip placement="top" :content="$t('base.download')">
          <i class="el-icon-download" />
        </el-tooltip>
      </div>
      <div @click="close">
        <!-- lang:关闭 -->
        <el-tooltip placement="top" :content="$t('base.close')">
          <i class="el-icon-close" />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>