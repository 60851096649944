import { copyData, getLocalStorage } from '@/utils'
import router from '@/router'

// 页签
export default {
  namespaced: true,
  state: {
    activeTag: 'INDEX', // 当前激活菜单枚举
    targetPermission: null, // 当前页面菜单权限
    tags: [], // 当前已经选择的页签
  },
  mutations: {
    // 更新菜单选中项
    updateActiveTag(state, permissionEnum = 'INDEX') {
      state.activeTag = permissionEnum
    },
    // 更新页面菜单权限
    updateTargetPermission(state, targetPermission = null) {
      state.targetPermission = targetPermission
    },
    // 更新页签
    updateTags(state, tags = []) {
      state.tags = tags
    },
  },
  actions: {
    // 当前页面菜单权限
    getTargetPermission({ rootState }, permissionEnum) {
     const { permission } = rootState.permission
     let targetPermission = null
      const getData = (data) => {
        if (!data || !data.length) return
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          if (item.permissionEnum === permissionEnum) {
            targetPermission = item
            return
          }
          if (item.children && item.children.length) getData(item.children)
        }
      }
    getData(permission)
     return targetPermission
    },
    // 新增页签
    async addTags({ state, commit, dispatch }, { permissionEnum, query }) {
      const { tags, activeTag } = state
      // 点击当前激活项
      if (permissionEnum === activeTag) return
      // 更新激活菜单
      commit('updateActiveTag', permissionEnum)
      // 当前页面菜单权限
      const targetPermission = await dispatch('getTargetPermission', permissionEnum)
      commit('updateTargetPermission', targetPermission)

      // 查询当前选择项是否已经在当前页签里
      const hased = tags.some((item) => item.permissionEnum === permissionEnum)
      if (!hased) {
        const newTags = copyData(tags)
        newTags.push({
          permissionEnum,
          label: targetPermission.name,
        })
        commit('updateTags', newTags)
      }
      console.log(3333, { path: targetPermission.url, query })
      router.push({ path: targetPermission.url, query })
    },
    // 删除页签
    async deleteTags({ state, commit, dispatch }, permissionEnum) {
      console.log(getLocalStorage('apiprefix'))
      const { activeTag, tags } = state
      // 如果页签的长度只有1
      if (tags.length === 1) {
        // 如果删除的页签是当前选中的菜单
        if (permissionEnum === activeTag) {
          commit('updateActiveTag')
          commit('updateTargetPermission')
          commit('updateTags')
          router.push({ path: '/'+ getLocalStorage('apiprefix')})
          return
        }
        // 如果删除的页签不是当前选中的菜单
        commit('updateTags')
        return
      }
      const newTags = copyData(tags)
      const targetIndex = newTags.findIndex((item) => item.permissionEnum === permissionEnum)
      // 如果页签的长度大于1
      if (permissionEnum !== activeTag) {
        // 如果删除的页签不是当前选中的菜单
        newTags.splice(targetIndex, 1)
        commit('updateTags', newTags)
        return
      }
      // 如果删除的页签是当前选中的菜单，targetIndex不存在说明删除的是页签第一个，否则不是
      const nowIndex = targetIndex ? targetIndex - 1 : targetIndex + 1
      const targetPermissionEnum = newTags[nowIndex].permissionEnum
      const targetPermission = await dispatch('getTargetPermission', targetPermissionEnum)
      commit('updateActiveTag', targetPermissionEnum)
      commit('updateTargetPermission', targetPermission)
      newTags.splice(targetIndex, 1)
      commit('updateTags', newTags)
      router.push({ path: targetPermission.url })
    },
  },
}
