// 企业管理
export default {
  title: '企业管理表',
  customTitle: '私有服务企业配置表',
  name: '企业名称',
  feeMode: '计费模式', // 当前计费模式
  feeModeStr: '可用计费模式', // 当前可用计费模式
  industry: '所属行业',
  customIndustry: '其他行业',
  businessScope: '业务描述',
  address: '联系地址',
  addressDetail: '详细地址',
  contacts: '联系人',
  contactsInformation: '联系方式',
  email: '联系邮箱',
  invoice: '发票信息',
  setInvoiceSuccess: '发票信息设置成功',
  invoiceType: '发票类型',
  companyNameForInvoice: '开票企业名称',
  creditCodeForInvoice: '统一社会信用代码',
  openAccountBankNameForInvoice: '开户行名称',
  openAccountBankAccountForInvoice: '开户行账号',
  openAccountTelNumForInvoice: '开户电话',
  openAccountBankAddressAddressForInvoice: '开户地址',
  openAccountBankAddressDetailForInvoice: '详细地址',
  nameOrNotes: '名称/联系人/联系方式/邮箱',
  excelImport: '导入企业',
  remainAmount: '剩余数量',
  remainAmountSee: '余额查看',
  feeModeChangeTips: '计费模式如果修改，修改企业及下级企业将同步更新并且清空余量，确定要更换计费模式吗？',
  canAddSubCompany: '是否可以创建下级企业',
  // 私有化企业
  syNameOrNotes: '企业名称',
  host: '访问地址',
  code: '企业编号',
  company: '私有企业',
  companyPlaceholder: '请输入企业名称',
  welcomeWord: '欢迎语',
  contactWord: '联系我们文本',
  contactLink: '联系我们链接',
  copyright: '版权信息',
  companyInviteCode: '企业邀请码',
  updateInvite: '更新邀请码',
  createInvite: '创建邀请码',
}
