// 网关管理
export default {
  title: 'Gateway management table',
  searchHolder: 'Please enter the gateway ID',
  account: 'Gateway ID',
  status: 'The current sign-in status',
  ipAddress: 'IP address',
  macAddress: 'MAC address',
  password: 'The new password',
  confirmPassword: 'Confirm the new password',
  code: 'Gateway QR code',
  // 查看操作日志
  operateLog: 'The action log',
  mac: 'The MAC address',
  type: 'Type',
  gatewayId: 'Gateway ID',
  companyName: 'The name of the company',
  accountName: 'The account name',
  updateTime: 'Modify the time',
}
