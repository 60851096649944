// 网关管理
export default {
  title: '网关管理表',
  searchHolder: '请输入网关ID',
  account: '网关ID',
  status: '当前登录状态',
  ipAddress: 'IP地址',
  macAddress: 'MAC地址',
  password: '新密码',
  confirmPassword: '确认新密码',
  code: '网关二维码',
  // 查看操作日志
  operateLog: '操作日志',
  mac: 'mac地址',
  type: '类型',
  gatewayId: '网关ID',
  companyName: '公司名称',
  accountName: '账号名称',
  updateTime: '修改时间',
}
