// 终端日志
export default {
  title: 'OSログテーブル',
  createTime: '作成時間',
  type: 'タイプ',
  company: '所属会社',
  userAccount: '所属アカウント',
  device: 'デバイス情報',
  file: 'ログファイル',
}
