<template>
  <div id="app" ref="app">
    <router-view />
  </div>
</template>
<script>
import { resetRouter } from '@/router'
import _ from 'lodash'
export default {
  data() {
    return {
      //设置超时时间： 30分种
      // timeOut : 30 * 60 * 1000,
      timeOut : 6 * 1000,
      lastTime: null, // 第一次点击时间
      currentTime: null,
      quitTime:null
    }
  },
  // 监听路由
  beforeCreate() {
    // 内页刷新要重新获取
    console.log(this.$util.getLocalStorage('apiprefix'))
     if (this.$util.getLocalStorage('apiprefix') && this.$util.getSessionStorage('operator')){
        this.$store.dispatch('permission/getPermission')
     }
    // if (
    //   this.$util.getSessionStorage('operator') &&
    //   this.$util.getSessionStorage('token')
    // ) {
    //   const adminModule = window.location.pathname
    //   if(adminModule.includes("core")){
    //     this.$util.setLocalStorage('apiprefix','core')
    //   }
    //   if(adminModule.includes("tmva")){
    //     this.$util.setLocalStorage('apiprefix','tmva')
    //   }
    //   if(adminModule.includes("device")){
    //     this.$util.setLocalStorage('apiprefix','device')
    //   }
    //   this.$store.dispatch('permission/getPermission')
    // }
  },
  created() {
    this.lastTime = new Date().getTime() // 第一次打开记录时间
  },
  mounted() {
    this.getConfigByCode()
    // this.$nextTick(() => {
    //   const $app = this.$refs.app
    //   // const standarScale = 1920 / 1080
    //   window.addEventListener(
    //       'resize',
    //       _.debounce(() => {
    //         const docHeight = document.body.clientHeight // BODY对象高度
    //         const docWidth = document.body.clientWidth // BODY对象宽度
    //         console.log(docWidth)
    //         // let standarWidth
    //         // if (docHeight / 1920 !== 0 && ((docWidth / 1920) / (docHeight / 1080)) >= 1) {
    //         //   standarWidth = docHeight / 1080
    //         // } else {
    //         //   standarWidth = docWidth / 1920
    //         // }
    //         // $app.style.zoom = standarWidth
    //
    //         if (docWidth < 1680) {
    //           const standarWidth = docWidth / 1920
    //           const standarHeight = docHeight / 1080
    //           console.log(standarWidth, standarHeight)
    //           $app.style.zoom = standarHeight;
    //         }
    //       })
    //   );
    //   if (document.createEvent) {
    //     var event = document.createEvent("HTMLEvents")
    //     event.initEvent('resize', true, true)
    //     window.dispatchEvent(event)
    //   } else if (document.createEventObject) {
    //     window.fireEvent("onresize")
    //   }
    // })
 },
  methods: {
    checkTimeout() {
      //更新当前时间
      let currentTime = new Date().getTime()
      let lastTime = localStorage.getItem("lastTime")
      //判断是否超时
      if (currentTime - lastTime > this.timeOut) {
        // 调用自己的注销接口
        console.log('超时')
        window.clearInterval(this.quitTime)
        resetRouter()
        // 清除本地缓存数据
        this.$store.dispatch('clearSessionInfo').then(() => {
          this.$router.replace({ path: '/login' })
        })
      }
    },
    // 查询企业配置
    getConfigByCode() {
      const localCode = this.$util.getLocalStorage('localCode')
      const code = this.$route.query.code || localCode
      if (!code) {
        return
      }
      this.$util.setLocalStorage('localCode', code)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.getConfigByCode,
        data: {
          code,
        },
      }).then((data) => {
        this.$store.commit('updateCustomCompanyInfo', data)
        // 动态设置网站ico
        this.$nextTick(() => {
          document
            .getElementById('networkFavicon')
            .setAttribute(
              'href',
              data.favicon.fileHost + '/' + data.favicon.objectName
            )
        })
      })
    },
  },
}
</script>
