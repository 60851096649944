// 分配账单
export default {
  title: '分配账单表',
  createTime: '分配时间',
  company: '上级企业',
  assignDetailSet: '详情',
  status: '状态',
  assignName: '授权内容',
  assignNumber: '授权数量',
}
