// 用户信息
import ajax from '@/utils/ajax'
import { login } from '@/api'
import { setSessionStorage, getSessionStorage, deleteSessionStorage } from '@/utils'
import { setTokenTime } from '@/utils/auth'

export default {
  namespaced: true,
  state: {
    token: getSessionStorage('token') || '', // 验证密钥
    operator: getSessionStorage('operator') || null, // 操作员信息
    productConfigEntities: getSessionStorage('productConfigEntities') || null, // 计费模式
  },
  mutations: {
    // 添加验证密钥
    addToken(state, token) {
      state.token = token
      setSessionStorage('token', token)
    },
    // 删除验证密钥
    deleteToken(state) {
      state.token = ''
      deleteSessionStorage('token')
    },
    // 添加用户信息
    addOperator(state, operator) {
      state.operator = operator
      setSessionStorage('operator', operator)
    },
    // 删除用户信息
    deleteOperator(state) {
      state.operator = null
      deleteSessionStorage('operator')
    },
    // 添加计费模式
    addProductConfigEntities(state, productConfigEntities) {
      state.productConfigEntities = productConfigEntities
      setSessionStorage('productConfigEntities', productConfigEntities)
    },
    // 删除计费模式
    deleteProductConfigEntities(state) {
      state.productConfigEntities = null
      deleteSessionStorage('productConfigEntities')
    },

  },
  actions: {
    // 登录
    login({ commit }, params) {
      return new Promise((resolve, reject) => {
        ajax({url: login, data: params})
          .then((result) => {
            const { token, account, productConfigEntities} = result
            commit('addToken', token)
            commit('addOperator', account)
            commit('addProductConfigEntities', productConfigEntities)
            // 设置token登录时间
            setTokenTime()
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
