// 服务返回code
export default {
  // 请求响应状态-state
  SUCCESS: 'Success',
  ERROR: 'Error',
  SYSTEM_ERROR: 'System error',
  NO_PERMISSION: 'No permission',
  NO_LOGIN: 'No login',
  KICK_OUT: 'Kick out',
  // 请求响应错误码-msg-账户相关
  ACCOUNT_000001: 'Account or password error',
  ACCOUNT_000002: 'Account error',
  ACCOUNT_000003: 'Platform user account  cannot be added and add contact',
  ACCOUNT_000004: 'The account cannot be renewed',
  ACCOUNT_000005: 'Account logged in',
  ACCOUNT_000006: 'Same enterprise user account cannot be added',
  ACCOUNT_000007: `Friends can't add any more`,
  ACCOUNT_000008: 'An application has been initiated',
  ACCOUNT_000009: 'The application has been processed (agreed or rejected)',
  ACCOUNT_000010: `The device's unique code is illegal`,
  ACCOUNT_000011: 'Non-external contacts of this account',
  ACCOUNT_000012: 'The password is not in the correct format',
  ACCOUNT_000013: 'The account number does not correspond to the device type',
  ACCOUNT_000014: 'The current account cannot add external contacts',
  ACCOUNT_000015: 'External contacts cannot be added to the peer account',
  ACCOUNT_000016: 'The login already exists',
  ACCOUNT_000017: 'There are already super administrators under the enterprise',
  ACCOUNT_000018: 'Private clouds cannot log on to public clouds',
  // 请求响应错误码-msg-接口请求
  API_000001: 'No login',
  API_000002: 'No Authorized',
  API_000003: 'Unknown interface',
  API_000004: 'Illegal parameters',
  API_000005: 'Unsupported request method',
  API_000006: 'HTTP read and write failed',
  // 请求响应错误码-msg-数据访问
  DATA_000001: 'Data access failed',
  DATA_000002: 'No data',
  DATA_000003: 'Data already exist',
  // 请求响应错误码-msg-文件操作
  FILE_000001: 'File size over limit',
  FILE_000002: 'Create folder failed',
  FILE_000003: 'Upload failed',
  FILE_000004: 'Get file upload signature failed',
  FILE_000005: 'File upload callback check failed',
  FILE_000006: 'File upload callback resolution parameter failed',
  // 请求响应错误码-msg-其它错误
  OTHER_000001: 'Unknown error',
  OTHER_000002: 'Operate failed',
  // 请求响应错误码-msg-设备相关
  EQUIPMENT_000001: 'Non-corporate shared data cannot editable',
  EQUIPMENT_000002: 'Device group has devices cannot be deleted',
  EQUIPMENT_000003: 'Devices/parts have been sold and are not allowed to be deleted',
  // 请求响应错误码-msg-销售客户相关
  CUSTOMER_000001: 'Remove associated customer prompt',
  // 请求响应错误码-msg-地址池信息
  POOL_000001: 'Not enough number to assign address pool information prompts',
  POOL_000002: 'Pool sub-type does not exist',
  POOL_000003: 'Not enough device pools',
  POOL_000004: 'Not enough parts pools',
  POOL_000005: 'The enterprise does not have sales resources/qualifications',
  POOL_000006: 'Enterprise AR digital display information resources are insufficient',
  // 请求响应错误码-msg-EXCEL相关
  EXCEL_000001: 'Read excel file failed',
  EXCEL_000002: 'Read excel data failed',
  // 请求响应错误码-msg-部门相关
  USER_ACCOUNT_GROUP_000001: 'Department has Sub-departments cannot be deleted',
  USER_ACCOUNT_GROUP_000002: 'Department has accounts cannot be deleted',
  // 请求响应错误码-msg-授权资源
  SERVICE_RESOURCE_000001: 'Insufficient authorization allowance',
  SERVICE_RESOURCE_000002: 'Unsupported resource billing mode',
  SERVICE_RESOURCE_000003: 'The codepool type corresponding to the authorization type was not found',
  SERVICE_RESOURCE_000004: 'The target enterprise has insufficient authorization margin',
  // 请求响应错误码-msg-网关设备
  GATEWAY_EQUIPMENT_000001: 'The gateway device does not exist',
  GATEWAY_EQUIPMENT_000002: 'The gateway file reads incorrectly',
  // 请求响应错误码-msg-好视通
  HST_ERROR_000001: `Failed to invoke Fastmeeting's interface`,
  // 消息通知错误码-msg-消息通知
  MESSAGE_000001: 'The message has been recalled and cannot continue to be recalled',
  // 请求响应错误码-msg-版本信息
  VERSION_000001: 'The main version cannot be deleted',
  // 请求响应错误码-msg-角色相关
  ROLE_000001: 'The operator data contained under the role cannot be deleted',
  // 请求响应错误码-msg-企业相关
  COMPANY_000001: 'Containing a sub-enterprise cannot be deleted',
  COMPANY_000002: 'Enterprise accounts containing background accounts cannot be deleted',
  COMPANY_000003: 'Enterprise accounts containing foreground accounts cannot be deleted',
  COMPANY_000004: 'Enterprise data does not exist',
  COMPANY_000005: 'Custom enterprise code repeat',
}
