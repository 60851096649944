import {findLicenceExpiryTime, updateLicence} from "@/api";

export default {
  data() {
    return {
      lang: this.$util.getLocalStorage('lang') || 'zhCN',
      formData: {
        loginName: '', // administrator
        password: '', // 111111
      },
      showPass:false,
      isPermanent: false,
      expiredTime: null,
      showUpdateLicence: false,
    }
  },
  computed: {
    // 私有化企业信息
    customCompanyInfo() {
      return this.$store.state.customCompanyInfo
    },
  },
  mounted() {
    this.$util.setLocalStorage('apiprefix','core')
    this.getLicenceExpiryTime()
  },
  methods: {
    uploadLicence() {
      const file = this.$refs.uploadLicence.files[0]
      console.log(file)
      const fd = new FormData()
      fd.append('file', file)
      fd.append('fileName', file.name)
      fd.append('fileFrom', 'SERVER_LICENCE')
      this.$ajax({
        url: this.$api.updateLicence,
        data: fd,
      }).then((data) => {
        this.getLicenceExpiryTime()
      })
    },
    getLicenceExpiryTime() {
      this.$ajax({
        url: this.$api.findLicenceExpiryTime,
        data: {},
      }).then((data) => {
        console.log(11111, JSON.stringify(data) === '{}')
        if (data === null || JSON.stringify(data) === '{}') {
          this.isPermanent = true
        } else {
          this.isPermanent = false
          this.expiredTime = data.expiryDate
          const newTime = (new Date()).getTime()
          const oneFiveDayTime = 60 * 60 * 24 * 15 * 1000
          if (newTime + oneFiveDayTime > data.expiryTime) {
            this.showUpdateLicence = true
          } else {
            this.showUpdateLicence = false
          }
        }
      })
    },
    // 切换语言
    changeLanguage(lang) {
      this.lang = lang
      this.$i18n.locale = lang
      this.$util.setLocalStorage('lang', lang)
      this.$refs.form.resetFields()
    },
    // 登录
    login() {
      const formData = this.$util.copyData(this.formData)
      if (this.$path.publicHost.includes(location.hostname)) {
        formData.operator = 'PUBLIC'
      } else if (this.$route.query.code) {
        formData.operator = this.$route.query.code
      }
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.$store.dispatch('operator/login', formData).then(() => {
          this.$router.replace({ path: '/main' })
        })
      })
    },
  },
}
