import LeftNavs from './leftNavs'
import TopNavs from './topNavs'
import PageTabs from './pageTabs'
import bus from '@/utils/bus'
export default {
  components: {
    LeftNavs,
    TopNavs,
    PageTabs,
  },
  data() {
    return {
      reconnection: true,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/login' || to.path === '/main') {
      this.reconnection = false
      this.clearTimer()
      this.closeWs()
    }
   bus.$emit('closeHomeWs')
    next()
  },
  destroyed () {
    this.closeWs()
  },
  mounted() {
   this.initWebSocket()
  },
  methods: {
    // 连接心跳
    initWebSocket() {
      const subject_token = this.$util.getSessionStorage('token')
      // 连接socket
      if(!subject_token) return false
      this.ws = new WebSocket(this.$path.websocektHead + this.$api.websocketOperator + '?subject_token=' + subject_token)
      // 连接成功
      this.ws.onopen = () => {
        this.clearTimer()
        this.timer = setInterval(() => {
          this.ws.send(
            JSON.stringify({
              type: 'OPERATOR_HEART_BEAT',
              body: {},
            })
          )
        }, 5000)
      }
      // 连接关闭
      this.ws.onclose = () => {
        if (!this.reconnection) return
        this.clearTimer()
        this.timer = setTimeout(() => {
          this.initWebSocket()
          this.clearTimer()
        }, 5000)
      }
      bus.$on('closeHomeWs', this.closeWs) 
    },
    // 清除定时器
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer)
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    // 清除心跳上报
    closeWs() {
      if (this.ws) {
        this.ws.close()
        this.ws = null
      }
    },
  },
}
