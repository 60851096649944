// 授权管理
export default {
  title: '授権割当テーブル',
  createTime: '授権時間',
  targetCompany: '授権会社',
  assignDetailSet: '詳細',
  status: '状態',
  assignName: '授権内容',
  remainNumber: '割当てられる数量',
  assignNumber: 'ライセンス数量',
  assignNumberTips: '数量を入力してください',
  assignTips: '入力できるのは整数のみで、割り当て可能数量を超えることはできません。値が入力された場合、デフォルトで割り当てられます',
  assignValidateTips: '0以上整数しか入力できません',
  assignCommitTips: '授権の内容を選択し、ライセンス数を記入してください',
  companySelectTips: '授権する会社',
  assignSuccess: '授権をしました',
  rollbackConfirm: 'ライセンスキーを撤回することを確認します',
  rollbackSuccess: '撤回成功しました',
  feeMode: '今の会社が使用可能な課金モード',
}
