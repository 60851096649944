// 功能枚举
export default {
  // 功能管理
  ALL_FUNCTION: '全部の機能',
  // 系统管理
  SYSTEM_MANAGE: 'システム機能',
  FUNCTION_MANAGE: '機能管理',
  FUNCTION_MANAGE_ADD: '追加',
  FUNCTION_MANAGE_EDIT: '変更',
  FUNCTION_MANAGE_DELETE: '削除',
  FUNCTION_MANAGE_VIEW: '詳細',
  CUSTOM_FUNCTION_MANAGE: 'カスタム機能管理',
  CUSTOM_FUNCTION_MANAGE_ADD: '追加',
  CUSTOM_FUNCTION_MANAGE_EDIT: '変更',
  CUSTOM_FUNCTION_MANAGE_DELETE: '削除',
  CUSTOM_FUNCTION_MANAGE_VIEW: '詳細',
  // 角色管理
  ROLE_MANAGE: 'ロール追加',
  ROLE_MANAGE_ADD: '追加',
  ROLE_MANAGE_EDIT: '変更',
  ROLE_MANAGE_DELETE: '削除',
  ROLE_MANAGE_VIEW: '詳細',
  ROLE_MANAGE_GRANT_PERMISSION: '権限付与',
  // 账号管理
  ACCOUNT_MANAGE: 'アカウント管理',
  // 管理员账号
  OPERATOR_MANAGE: '管理者アカウント',
  OPERATOR_MANAGE_ADD: '追加',
  OPERATOR_MANAGE_EDIT: '変更',
  OPERATOR_MANAGE_DELETE: '削除',
  OPERATOR_MANAGE_VIEW: '詳細',
  OPERATOR_MANAGE_DISABLE: '無効化',
  OPERATOR_MANAGE_RECOVER: '有効化',
  OPERATOR_MANAGE_RESET_PASSWORD: 'パスワードリセット',
  OPERATOR_MANAGE_GRANT_ROLE: 'ロール割当',
  // 企业用户账号
  USER_ACCOUNT_MANAGE: '会社ユーザーアカウント',
  USER_ACCOUNT_MANAGE_ADD: '追加',
  USER_ACCOUNT_MANAGE_EDIT: '変更',
  USER_ACCOUNT_MANAGE_DELETE: '削除',
  USER_ACCOUNT_MANAGE_VIEW: '詳細',
  USER_ACCOUNT_MANAGE_DISABLE: '無効化',
  USER_ACCOUNT_MANAGE_RECOVER: '有効化',
  USER_ACCOUNT_MANAGE_RESET_PASSWORD: 'パスワードリセット',
  USER_ACCOUNT_MANAGE_EXPORT_QR_CODE: '一括QRコードをエクスポート',
  USER_ACCOUNT_MANAGE_TRAJECTORIES: '軌跡図',
  USER_ACCOUNT_MANAGE_RENEW: '更新',
  USER_ACCOUNT_IMPORT_FROM_EXCEL: 'ユーザーアカウントインポート',
  // 平台用户账号
  SCOPE_USER_ACCOUNT_MANAGE: 'プラットフォームユーザーアカウント',
  SCOPE_USER_ACCOUNT_MANAGE_ADD: '追加',
  SCOPE_USER_ACCOUNT_MANAGE_EDIT: '変更',
  SCOPE_USER_ACCOUNT_MANAGE_DELETE: '削除',
  SCOPE_USER_ACCOUNT_MANAGE_VIEW: '詳細',
  SCOPE_USER_ACCOUNT_MANAGE_DISABLE: '無効化',
  SCOPE_USER_ACCOUNT_MANAGE_RECOVER: '有効化',
  SCOPE_USER_ACCOUNT_MANAGE_RESET_PASSWORD: 'パスワードリセット',
  SCOPE_USER_ACCOUNT_MANAGE_EXPORT_QR_CODE: '一括QRコードをエクスポート',
  SCOPE_USER_ACCOUNT_MANAGE_TRAJECTORIES: '軌跡図',
  SCOPE_USER_ACCOUNT_MANAGE_RENEW: '更新',
  SCOPE_USER_ACCOUNT_IMPORT_FROM_EXCEL: 'ユーザーアカウントインポート',
  // 字典管理
  DICT_MANAGE: '辞書管理',
  DICT_MANAGE_ADD: '追加',
  DICT_MANAGE_EDIT: '変更',
  DICT_MANAGE_DELETE: '削除',
  DICT_MANAGE_VIEW: '詳細',
  // 企业管理
  COMPANY_MANAGE: '会社管理',
  COMPANY_MANAGE_ADD: '追加',
  COMPANY_MANAGE_EDIT: '変更',
  COMPANY_MANAGE_DELETE: '削除',
  COMPANY_MANAGE_VIEW: '詳細',
  COMPANY_IMPORT_FROM_EXCEL: '会社をインポート',
  COMPANY_INVOICE: '領収書情報',
  COMPANY_BALANCE: '残高チェック',
  COMPANY_CONFIG_MANAGE: 'カスタム会社配置',
  COMPANY_CONFIG_MANAGE_ADD: '追加',
  COMPANY_CONFIG_MANAGE_EDIT: '変更',
  COMPANY_CONFIG_MANAGE_DELETE: '削除',
  COMPANY_CONFIG_MANAGE_VIEW: '詳細',
  COMPANY_GRANT_PERMISSION: '機能権限付与',
  COMPANY_QRCODE_BATCH_EXPORT: '导出企业码',
  // 日志管理
  LOG_MANAGE: 'ログ管理',
  CONTEXT_LOG_MANAGE: 'コンテキストログ',
  CONTEXT_LOG_MANAGE_VIEW: '詳細',
  CLIENT_LOG_MANAGE: 'クライアントログ',
  CLIENT_LOG_DOWNLOAD: 'ダウンロード',
  CLIENT_LOG_MANAGE_DOWNLOAD: 'ダウンロード',
  // 财务管理
  FINANCE_MANAGE: '財務管理',
  ASSIGN_TO_ME_MANAGE: '料金明細書割当',
  ASSIGN_TO_ME_MANAGE_VIEW: '詳細',
  // 授权分配
  ASSIGN_TO_OTHER_MANAGE: '割当権限付与',
  ASSIGN_TO_OTHER_MANAGE_ADD: '権限付与を追加',
  ASSIGN_TO_OTHER_MANAGE_ROLLBACK: '取消し',
  ASSIGN_TO_OTHER_MANAGE_VIEW: '詳細',
  // 账单查询
  FINANCIAL_FLOW_RECORD: '料金明細書検索',
  // 设备管理
  EQUIPMENT: 'デバイス管理',
  EQUIPMENT_MANAGE: '販売デバイス情報',
  EQUIPMENT_GROUP_MANAGE_ADD: '追加',
  EQUIPMENT_GROUP_MANAGE_EDIT: '変更',
  EQUIPMENT_GROUP_MANAGE_DELETE: '削除',
  EQUIPMENT_MANAGE_ADD: 'デバイス追加',
  EQUIPMENT_MANAGE_EDIT: '変更',
  EQUIPMENT_MANAGE_DELETE: '削除',
  EQUIPMENT_MANAGE_VIEW: '詳細',
  SCOPE_EQUIPMENT_MANAGE: 'プラットフォームデバイス情報',
  SCOPE_EQUIPMENT_GROUP_MANAGE_ADD: '追加',
  SCOPE_EQUIPMENT_GROUP_MANAGE_EDIT: '変更',
  SCOPE_EQUIPMENT_GROUP_MANAGE_DELETE: '削除',
  SCOPE_EQUIPMENT_MANAGE_ADD: 'デバイス追加',
  SCOPE_EQUIPMENT_MANAGE_EDIT: '変更',
  SCOPE_EQUIPMENT_MANAGE_DELETE: '削除',
  SCOPE_EQUIPMENT_MANAGE_VIEW: '詳細',
  EQUIPMENT_MANAGE_COPY: 'へコピー',
  EQUIPMENT_MANAGE_MOVE: 'へ移動',
  // 自用设备信息
  CUSTOM_EQUIPMENT: '自社デバイス情報',
  CUSTOM_EQUIPMENT_GROUP_MANAGE_ADD: '追加',
  CUSTOM_EQUIPMENT_GROUP_MANAGE_EDIT: '変更',
  CUSTOM_EQUIPMENT_GROUP_MANAGE_DELETE: '削除',
  CUSTOM_EQUIPMENT_MANAGE_ADD: 'デバイス追加',
  CUSTOM_EQUIPMENT_MANAGE_EDIT: '変更',
  CUSTOM_EQUIPMENT_MANAGE_DELETE: '削除',
  CUSTOM_EQUIPMENT_MANAGE_VIEW: '詳細',
  CUSTOM_EQUIPMENT_MANAGE_COPY: 'へコピー',
  CUSTOM_EQUIPMENT_MANAGE_MOVE: 'へ移動',
  CUSTOM_EQUIPMENT_MANAGE_EXPORT: 'QRコードをエクスポート',
  // 地址池管理
  POOL: 'アドレスプール管理',
  EQUIPMENT_POOL_CODE: 'デバイスコード',
  EQUIPMENT_POOL_CODE_ADD: '追加',
  EQUIPMENT_POOL_CODE_LIST: '検索',
  EQUIPMENT_POOL_CODE_DELETE: '削除',
  GATEWAY_POOL: 'ゲートウェイアドレスプール',
  GATEWAY_POOL_ADD: '追加',
  GATEWAY_POOL_UPDATE: '変更',
  GATEWAY_POOL_DELETE: '削除',
  GATEWAY_POOL_VIEW: '詳細',
  AR_GATEWAY_IMPORT: 'ゲートウェイアドレスをインポート',
  AR_POOL: 'ARデバイスアドレスプール',
  AR_POOL_ADD: '追加',
  AR_POOL_UPDATE: '変更',
  AR_POOL_DELETE: '削除',
  AR_POOL_VIEW: '詳細',
  AR_POOL_IMPORT: 'ARデバイスアドレスをインポート',
  // 销售信息
  SALE: '販売情報',
  SALE_ADD: '追加',
  SALE_UPDATE: '変更',
  SALE_DELETE: '削除',
  SALE_VIEW: '詳細',
  SALE_EXPORT: '一括QRコードをエクスポート',
  SALE_LIST_DELETE: 'ログを削除',
  // 销售详情
  SALE_EQUIPMENT_LIST: '販売詳細テーブル',
  // 客户管理
  CUSTOMER: '顧客管理',
  CUSTOMER_ADD: '追加',
  CUSTOMER_UPDATE: '変更',
  CUSTOMER_DELETE: '削除',
  CUSTOMER_VIEW: '詳細',
  CUSTOMER_IMPORT: '顧客情報をインポート',
  // AR数显管理
  AR_DIGTAL_POOL: 'ARデジタルディスプレイ管理',
  AR_DIGTAL_ADD: '追加',
  AR_DIGTAL_UPDATE: '変更',
  AR_DIGTAL_DELETE: '削除',
  AR_DIGTAL_VIEW: '詳細',
  AR_DIGTAL_IMPORT: 'ARデジタルディスプレイをインポート',
  AR_DIGTAL_EXPORT: '一括QRコードをエクスポート',
  // 部门管理
  GROUP_MANAGE: '部署管理',
  GROUP_MANAGE_USER_ACCOUNT_GROUP: '会社部署をグループ分け',
  GROUP_MANAGE_USER_ACCOUNT_GROUP_ADD: '追加',
  GROUP_MANAGE_USER_ACCOUNT_GROUP_UPDATE: '変更',
  GROUP_MANAGE_USER_ACCOUNT_GROUP_DELETE: '削除',
  GROUP_MANAGE_USER_ACCOUNT_GROUP_VIEW: '詳細',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP: 'プラットフォーム部署グループ分け',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_ADD: '追加',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_UPDATE: '変更',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_DELETE: '削除',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_VIEW: '詳細',
  // 信息管理
  INFO_MANAGE: '情報管理',
  INFO_MANAGE_ME: 'プラットフォーム情報管理',
  INFO_MANAGE_ME_CHANGE_PASSWORD: 'パスワード変更',
  INFO_MANAGE_COMPANY: '会社情報管理',
  INFO_MANAGE_COMPANY_UPDATE: '会社情報変更',
  INFO_MANAGE_COMPANY_UPDATE_INVOICE: '領収書情報変更',
  COMPANY_QRCODE_EXPORT: '导出企业码',
  // 网关管理
  GATEWAY_EQUIPMENT: 'ゲートウェイ管理',
  GATEWAY_EQUIPMENT_RESET: 'パスワードリセット',
  GATEWAY_EQUIPMENT_EXPORT: '一括QRコードをエクスポート',
  GATEWAY_EQUIPMENT_OP_LOG: '操作ログ',
  // 消息管理
  MESSAGE_MANAGE: 'メッセージ管理',
  MESSAGE_MANAGE_ADD: '追加',
  MESSAGE_UPDATE: '変更',
  MESSAGE_DELETE: '削除',
  MESSAGE_VIEW: '詳細',
  MESSAGE_ROLLBACK: '取消し',
  // 版本管理
  VERSION_MANAGE_APK: 'アンドロイドバージョン管理',
  VERSION_MANAGE_APK_ADD: '追加',
  VERSION_MANAGE_APK_UPDATE: '変更',
  VERSION_MANAGE_APK_DELETE: '削除',
  VERSION_MANAGE_APK_VIEW: '詳細',
  VERSION_MANAGE_PC: 'PCバージョン管理',
  VERSION_MANAGE_PC_ADD: '追加',
  VERSION_MANAGE_PC_UPDATE: '変更',
  VERSION_MANAGE_PC_DELETE: '削除',
  VERSION_MANAGE_PC_VIEW: '詳細',
  // 设备激活码
  TERMINAL_MANAGE: '终端设备管理',
  TERMINAL_ACTIVATION_CODE_MANAGE: '设备激活码管理',
  TERMINAL_ACTIVATION_CODE_MANAGE_ADD: '新增',
  TERMINAL_ACTIVATION_CODE_MANAGE_UPDATE: '禁用',
  TERMINAL_ACTIVATION_CODE_MANAGE_EXPORT: '批量导出二维码',
}
