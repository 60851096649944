<template>
  <base-dialog
    ref="baseDialog"
    :title="title"
    :width="width"
    :height="height"
    :showConfirm="showConfirm"
    :center="center"
    :showClose="showClose"
    @cancel="cancel"
    @confirm="confirm"
  >
    <base-tree
      ref="baseTree"
      v-model="treeData"
      :baseUrl="baseUrl"
      :url="url"
      :defaultParams="defaultParams"
      :value="value"
      :isDefine="isDefine"
      :defaultContent="defaultContent"
      :nodeKey="nodeKey"
      :defaultProps="defaultProps"
      :multiple="multiple"
      :highlightCurrentRow="highlightCurrentRow"
      :expandOnClickNode="expandOnClickNode"
      :defaultExpandAll="defaultExpandAll"
      :defaultExpandedKeys="defaultExpandedKeys"
      @nodeClick="nodeClick"
      @check="check"
    >
      <template v-slot:default="{ node, data }">
        <slot :node="node" :data="data" />
      </template>
    </base-tree>
  </base-dialog>
</template>>

<script>
import Index from './_index.js'
export default Index
</script>