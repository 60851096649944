<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
    width="650px"
  />
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      formData: {
        loginName: this.tableRow.accountId,
      },
      config: [
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'loginName',
          rules: {
            noNull: true,
          },
          tagProps: {
            disabled: true,
          },
        },
        // lang:新密码
        {
          label: this.$t('personalInfo.password'),
          prop: 'password',
          rules: {
            noNull: true,
            type: 'password',
          },
          tagProps: {
            showPassword: true,
            placeholder: this.$t('base.validatePassword'),
          },
        },
      ],
    }
  },
  methods: {
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.operatorResetPassword,
        data: {
          id: this.tableRow.id,
          password: this.formData.password,
        },
      }).then(() => {
        // lang:重置成功
        this.$element.showMsg(this.$t('base.resetSuccess'), 'success')
        this.$emit('closePage', 'resetPassword')
      })
    },
  },
}
</script>