// 终端日志
export default {
  title: '终端日志表',
  createTime: '创建时间',
  type: '类型',
  company: '所属企业',
  userAccount: '所属账号',
  device: '设备信息',
  file: '日志文件',
}
