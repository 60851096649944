// 公用路径
export const baseUrl = ''
export const baseUrlCustom = '/oem/'
export const contactUsUrl = 'http://www.tmva-sh.com'
const protocol = {
    'http:': 'ws://',
    'https:': 'wss://'
}
export const websocektHead = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_WEBSOCEKTHEAD : protocol[document.location.protocol] + document.location.host
export const publicHost = ['api.team-view.cn', 'manage.tmva-cn.com', 'manage.ar-office.com']
