// 授权管理
export default {
  title: 'Authorization assignment table',
  createTime: 'Authorized time',
  targetCompany: 'Authorized company',
  assignDetailSet: 'Details',
  status: 'State',
  assignName: 'Authorized content',
  remainNumber: 'Distributable number',
  assignNumber: 'Authorization number',
  assignNumberTips: 'Please enter number',
  assignTips: 'Input positive integers and no more than the allocable number,assign this item by default when there is an input value',
  assignValidateTips: 'Input positive integer',
  assignCommitTips: 'Please select the authorized content and fill in the authorized number',
  companySelectTips: 'Please select the authorized company',
  assignSuccess: 'Authorization success',
  rollbackConfirm: 'Undo authorization code confirm',
  rollbackSuccess: 'Undo success',
  feeMode: 'Available billing model of current company',
}
