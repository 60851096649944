// AR设备地址池
export default {
  title: 'AR设备地址池表',
  arType: '类型',
  code: 'AR码',
  notes: '备注',
  nameOrNotes: 'AR码/备注',
  eqCode: '设备码',
  eqCodeList: '设备码表',
  addArPool: '添加至AR设备地址池',
  code64: '码(64位)',
  code32: '码(32位)',
  writeEqCode: '填写设备码',
}
