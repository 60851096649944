// 分配账单
export default {
  title: 'Distribution bill table',
  createTime: 'Allocate time',
  company: 'Superior enterprises',
  assignDetailSet: 'Details',
  status: 'State',
  assignName: 'Authorized content',
  assignNumber: 'Authorization number',
}
