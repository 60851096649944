export default {
  name: 'basePage',
  props: {
    // 基础页配置项--------------
    title: {
      type: String,
      default: '',
    },
    // 搜索表单配置项------------
    config: {
      type: Array,
      default: () => [],
    },
    // 绑定数据
    formData: {
      type: Object,
      default: () => ({}),
    },
    // 文本标题宽度
    labelWidth: {
      type: String,
      default: '150px',
    },
    // 表单间隔距离
    gutter: {
      type: Number,
      default: 10,
    },
    searchSpan: {
      // 搜索按钮宽度
      type: Number,
      default: 4,
    },
    searchAlign: {
      // 搜索按钮对其方式
      type: String,
      default: 'left',
    },
  },
  methods: {
    // 权限按钮点击回调
    functionMethod(item) {
      this.$emit('functionMethod', item)
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$emit('search', isActiveQuery)
    },
    // 自定义弹框点击回调
    openDialog(item) {
      this.$emit('openDialog', item)
    },
  },
}
