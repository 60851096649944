// 全局公用
export default {
  // element.js
  title: 'タイトル',
  content: '内容',
  // ajax.js
  serverException: 'サーバー異常',
  netWorkError: 'サーバーへの接続が中断されました。後でもう一度試してください',
  // validate.js
  pleaseEnter: '入力してください',
  incorrectFormat: 'フォーマットは正しくありません',
  numOrLetter: 'アルファベットもしくは数字を使ってください',
  letter: 'アルファベットを使ってください',
  chinese: '中国語を使ってください',
  positiveInt0: '≥0の整数を使ってください',
  positiveInt: '＞0の整数を使ってください',
  int: '整数を使ってください',
  positive: '＞0の数字を使ってください',
  positive0: '≥0の数字を使ってください',
  len: '長さは　　に設定されるはずです',
  min: '最小長さは　に設定されるはずです',
  max: '最大長さは　に設定されるはずです',
  validatePassword: 'パスワードは頭文字大文字、少なくとも1つの数字、1つの記号の複合で作成してください。字数制限：8-15文字',
  // 登录
  welcome: 'ARリモートアフターサービスシステム-バックグラウンドの管理へようこそ',
  loginName: 'アカウントID',
  password: 'パスワード',
  loginNameHolder: 'アカウントを入力してください',
  passwordHolder: 'パスワードを入力してください',
  login: 'ログイン',
  copyright: 'Copyright © 2021 蒂姆维澳(上海)网络技术有限公司. All rights reserved.',
  // 图片预览
  rotateLeft: '反時計回りに回転',
  rotateRight: '時計回りに回転',
  shrink: 'ズームアウト',
  restore: '元に戻す',
  amplification: 'ズームイン',
  download: 'ダウロード',
  close: '閉じる',
  // 主页
  homePage: 'ホームページ',
  set: '設定',
  help: 'ヘルプ',
  quit: '退出',
  quitConfirm: '退出しますか。',
  contactUs: 'お問合せ',
  cancel: 'キャンセル',
  confirm: 'OK',
  tip: 'tip',
  name: '名称',
  value: '値',
  enter: '入力してください',
  select: '選択してください',
  selectTreeNode: 'ツリーノードデータを選択してください',
  selectTableRow: 'データを選択してください',
  addSuccess: '追加しました',
  editSuccess: '変更しました',
  deleteSuccess: '削除しました',
  sortSuccess: 'ソートしました',
  distributionSuccess: '配分しました',
  copySuccess: 'コピーしました',
  moveSuccess: '移動しました',
  deleteConfirm: 'キャンセルしますか',
  currentSelect: 'すでに選択されています',
  notes: '備考',
  serialNumber: 'S/N',
  sort: 'ソート',
  option: '操作',
  searchSuccess: 'データクエリー成功',
  resetTips: '検索したフォームはリセットされました',
  search: '検索',
  reset: 'リセット',
  all: '全部',
  canUse: '機能は利用できるかどうか',
  searchStr: 'キーワード',
  startDate: '開始日',
  endDate: '終了日',
  createTime: '作成時間',
  disableConfirm: '無効化しますか',
  recoverConfirm: '有効化しますか',
  disableSuccess: '無効化しました',
  recoverSuccess: '有効化しました',
  hasDisabled: 'このデータはすでに無効化されています',
  hasRecover: 'このデータはすでに有効化されています',
  enable: '状態',
  resetConfirm: 'リセットしますか',
  resetSuccess: 'リセットしました',
  setRoleSuccess: 'ロールをセットしました',
  noData: 'データなし',
  hasSelect: '選択した項目：',
  totalSelect: '選択した全ての項目',
  item: 'アイテム',
  add: '追加',
  edit: '変更',
  del: '削除',
  view: '詳細',
  countLimit: 'アップロード可能数量',
  count: '枚',
  sizeLimit: 'サイズが制限を超えました。現在の画像サイズは',
  fileSizeLimit: 'サイズが制限を超えました。現在のファイルサイズは',
  maxSize: 'アップロードサイズの上限は',
  uploadError: '画像アップロード失敗',
  fileUploadError: 'ファイルアップロード失敗',
  uploadFile: 'ファイルをアップロード',
  fileTypeTitle: 'フォーマット',
  fileTypeEnd: '対応可能',
  back: '戻す',
  nameOrNotes: '名称/備考',
  drawFile: 'ファイルをここにドラッグ、もしくは',
  clickUpload: 'クリックしてアップロード',
  importSize: 'このシステムで提供されている.xlsx形式のテンプレートのみをサポートし、5Mを超えることはできません',
  downloadTemplate: 'ここをクリックしてテンプレートをダウロード',
  uploadFileTips: 'ファイルをアップロード',
  excelImport: 'EXCEL導入',
  importSuccess: '導入しました',
  isImporting: 'ファイルをエクスポート中、少々お待ちください...',
  export: 'エクスポート',
  customField: 'カスタムフィールド',
  setCustomField: 'カスタムフィールドを設定',
  writeTips: '内容が完全に入力されているかチェックしてください',
  confirmRollback: '取り消しますか。',
  rollbackSuccess: ' 取り消しました',
  mapStart: 'スタート',
  mapEnd: 'エンド',
  canAddFirstCate: '新しいバージョン分類を追加する時トップカテゴリー(レベル1)しか追加できません。',
  zhCN: '中国語',
  en: '英語',
  ja: '日本語',
  justSelectOne: '只能选择一条数据',
  codeErrText: '验证码输入错误',
  validateCode: '请输入',
  companyRegister:'企业注册',
  accountRegister:'用户注册',
  forgotpassword: '忘记密码',
  revocationConfirm: '确定要撤回吗',
  revocationSuccess: '撤回成功',
  renewalSuccess: '续期成功',
  bindMobile:'请输入绑定手机号码',
  bindSuccess:'手机绑定成功',
  closeRegister:'确定关闭注册吗',
  closeRegisterSuccess:'关闭注册成功',
  maxlength:'必须为18位',
  applyTime: '申请时间',
  refuseConfirm: '确定要拒绝吗?',
  pleaseSelect: '请勾选',
  pleaseSelect1: '请选择',
  deactivateConfirm: '确定要停用吗',
  refuseSuccess: '拒绝成功',
  deactivateSuccess: '停用成功',
}
