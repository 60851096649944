import dynamicLoadScript from './dynamicLoadScript'
import plugins from './package/plugins'
import toolbar from './package/toolbar'
const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js'
// const tinymceCDN = 'https://cdn.bootcdn.net/ajax/libs/tinymce/4.9.3/tinymce.min.js'

export default {
  name: 'tinymceText',
  props: {
    value: {
      // 默认值
      type: String,
      default: '',
    },
    height: {
      // 高度
      type: Number,
      default: 360,
    },
    width: {
      // 宽度
      type: Number,
      default: 0,
    },
    disabled: {
      // 是否禁用
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 富文本唯一标识
      tinymceId: 'tinymce-' + new Date().getTime(),
      hasChange: false,
      hasInit: false,
      fullscreen: false,
    }
  },
  computed: {
    // 根据本地语言类型获取语言值
    getLang() {
      const languageList = {
        zhCN: 'zh_CN',
        en: 'en',
      }
      const lang = this.$i18n.locale
      return languageList[lang]
    },
  },
  watch: {
    // 放默认值
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() => {
          this.setContent(val || '')
        })
      }
    },
  },
  mounted() {
    this.dynamicLoadScript()
  },
  beforeDestroy() {
    this.destroyTinymce()
  },
  methods: {
    // 加载资源
    dynamicLoadScript() {
      // this.initTinymce()
      dynamicLoadScript(tinymceCDN, () => {
        this.initTinymce()
      })
    },
    // 初始化富文本
    initTinymce() {
      const self = this
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        branding: false,
        language: this.getLang,
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: toolbar,
        menubar: 'file edit insert view format table',
        plugins: plugins,
        end_container_on_empty_block: false,
        powerpaste_word_import: 'clean',
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com'],
        default_link_target: '_blank',
        link_title: false,
        nonbreaking_force_tab: true,
        init_instance_callback: (editor) => {
          self.hasInit = true
          if (self.value) editor.setContent(self.value)
          editor.on('NodeChange Change KeyUp SetContent', () => {
            self.hasChange = true
            self.$emit('input', editor.getContent())
          })
        },
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            self.fullscreen = e.state
          })
        },
      })
    },
    // 设置富文本的值
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    // 获取富文本的值
    getContent() {
      return window.tinymce.get(this.tinymceId).getContent()
    },
    // 销毁富文本
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) tinymce.execCommand('mceFullScreen')
      if (tinymce) tinymce.destroy()
    },
  },
}
