// 设备管理
export default {
  // 筛选
  nameOrNotes: 'Device name/number/model',
  // 分组
  title: 'Enterprise device information table',
  groupTitle: 'Enterprise devices grouping',
  title_platform: 'Platform device information table',
  groupTitle_platform: 'Platform device grouping',
  customTitle: 'Self - use equipment information table',
  customGroupTitle: 'Self-use device group',
  addFirstGroup: 'Add',
  groupName: 'Group name',
  topName: 'Parent grouping',
  level: 'Affiliated level',
  enterTitle: 'Please enter a title',
  enterContent: 'Please enter the content',
  // 表格
  name: 'Name',
  number: 'Number',
  model: 'Model',
  specifications: 'Specs',
  userAccountGroup: 'Subordinate department',
  selectGroup: 'Please select the device grouping data',
  addEquipment: 'Add equipment',
  addPort: 'Add part',
  addIssue: 'Add fault information',
  equipmentInfo: 'Device information',
  partInfo: 'Part information',
  faultInfo: 'Fault information',
  scope: 'Visible range',
  type: 'Type',
  selectEquipment: 'Select equipment',
  selectGroup_1: 'Please select a group',
  selectEquipmentTips: 'Please select device',
  selectPartTips: 'Please select parts',
  selectDefautTips: 'Please select fault',
  company: 'Company',
  hasEquipment: 'There are already devices under the current grouping, and you cannot create a new device grouping',
  equipmentType: 'The group type',
  equipmentCompnay: 'Enterprise device grouping',
  equipmentPlatform: 'Platform device grouping',
  copyEqTips: 'Only the last level of device grouping can be selected',
  copyData: 'Copy to',
  purchaseTime: 'Acquisition date',
  // 设备
  equipmentName: 'Device name',
  equipmentGroup: 'Group',
  equipmentUserAccountGroup: 'Section',
  equipmentNumber: 'Factory number',
  equipmentNumber_1: 'Factory number_1',
  equipmentModel: 'Device model',
  equipmentSpecifications: 'Device specifications',
  equipmentDetail: 'Device description',
  equipmentImageSet: 'Device picture',
  equipmentFileSet: 'Related attachment',
  equipmentDetail_1: 'Equipment details',
  // 部件
  partName: 'Part name',
  partEquipment: 'Affiliated device',
  partNumber: 'Factory number',
  partNumber_1: 'Factory number_1',
  partModel: 'Part model',
  partSpecifications: 'Part specifications',
  partDetail: 'Part description',
  partImageSet: 'Part picture',
  partFileSet: 'Related attachment',
  partDetail_1: 'Part details',
  // 故障信息
  faultName: 'Fault name',
  faultNumber: 'Fault code',
  faultPart: 'Affiliated attachment',
  faultDetail: 'Fault description',
  faultSolution: 'Solution',
  faultImageSet: 'Fault picture',
  faultFileSet: 'Related attachment',
  faultDetail_1: 'Fault information',
  code: 'Device QR code',
  companyEqSelf: 'Private',
  companyEq: 'Marketable',
  platformEq: 'Platform',
}
