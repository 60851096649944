import jsonViews from 'vue-json-views'

export default {
  name: 'jsonView',
  components: {
    jsonViews,
  },
  props: {
    json: {
      // 需要展示的数据
      type: Object | Array,
      default: () => null,
    },
    closed: {
      // 是否折叠全部
      type: Boolean,
      default: false,
    },
    deep: {
      // 展开深度,越大渲染速度越慢,建议不超过5
      type: Number,
      default: 2,
    },
    iconStyle: {
      // 折叠按钮样式，可选值为square、circle、triangle
      type: String,
      default: 'square',
    },
    theme: {
      // 可选主题样式,可选值为one-dark、vs-code，不选时为默认的白色主题
      type: String,
      default: '',
    },
    iconColor: {
      // 两个折叠按钮的颜色
      type: Array,
      default() {
        return this.theme === 'vs-code' ? ['#c6c6c6', '#c6c6c6'] : ['#747983', '#747983']
      },
    },
    fontSize: {
      // 字体大小,单位px
      type: Number,
      default: 14,
    },
    lineHeight: {
      // 行高，单位px
      type: Number,
      default: 24,
    },
  },
}
