// 授权管理
export default {
  title: '授权分配表',
  createTime: '授权时间',
  targetCompany: '授权企业',
  assignDetailSet: '详情',
  status: '状态',
  assignName: '授权内容',
  remainNumber: '可分配数量',
  assignNumber: '授权数量',
  assignNumberTips: '请输入数量',
  assignTips: '只能输入整数并且不能大于可分配数量，当有输入值时默认分配此项',
  assignValidateTips: '只能输入大于0的整数',
  assignCommitTips: '请选择授权内容并填写授权数量',
  companySelectTips: '请选择授权企业',
  assignSuccess: '授权成功',
  rollbackConfirm: '确认要撤回授权码',
  rollbackSuccess: '撤回成功',
  feeMode: '当前企业可用计费模式',
}
