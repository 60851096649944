// 状态模块出口
import operator from './operator'
import permission from './permission'
import tags from './tags'

export default {
  operator,
  permission,
  tags,
}
