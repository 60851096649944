export default {
    title: '企业接口管理',
    name: '名称',
    url: '请求地址',
    request: '请求体',
    response: '响应',
    header: '请求头',
    method: '请求方式',
    ruleType: '规则',
    createTime: '添加时间',
    updateTime: '修改时间',
    requestValues: '动态参数',
}
