import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getLocalStorage, setLocalStorage } from './../utils'

// element ui语言包
import zhCN from 'element-ui/lib/locale/lang/zh-CN'
import en from 'element-ui/lib/locale/lang/en'
import ja from 'element-ui/lib/locale/lang/ja'

// 本地语言包
import zhCNLocale from './zhCN'
import enLocale from './en'
import jaLocale from './ja'

Vue.use(VueI18n)

const lang = getLocalStorage('lang') || 'zhCN'
setLocalStorage('lang', lang)

const i18n = new VueI18n({
  locale: lang, // 默认中文
  messages: {
    zhCN: {
      ...zhCNLocale,
      ...zhCN,
    },
    en: {
      ...enLocale,
      ...en,
    },
    ja: {
      ...jaLocale,
      ...ja,
    },
  },
})

export default i18n
