export default {
  name: 'dialogTree',
  props: {
    // 弹出框配置项-------------
    title: {
      // 显示标题
      type: String,
      default: '',
    },
    width: {
      // 弹出框宽度
      type: String,
      default: '500px',
    },
    height: {
      // 弹出框高度
      type: String,
      default: 'auto',
    },
    showConfirm: {
      // 是否显示确定按钮
      type: Boolean,
      default: true,
    },
    center: {
      // 底部操作按钮是否左右居中
      type: Boolean,
      default: true,
    },
    showClose: {
      // 是否显示右上角关闭按钮
      type: Boolean,
      default: true,
    },
    // 弹出树配置---------------
    url: {
      // 请求地址
      type: String,
      default: '',
    },
    defaultParams: {
      // 请求参数
      type: Object,
      default: () => null,
    },
    value: {
      // 默认值，单选为对象，多选为数组，例如：{id: 1, name: '姓名'}或者[{id: 1, name: '姓名'}]
      type: Object | Array,
      default: () => null,
    },
    isDefine: {
      // 当前是否是自定义数据
      type: Boolean,
      default: false,
    },
    defaultContent: {
      // 自定义数据，如果有数据则默认认为为自定义数据，不需要发送请求
      type: Array,
      default: () => [],
    },
    nodeKey: {
      // 树节点用来作为唯一标识的属性
      type: String,
      default: 'id',
    },
    defaultProps: {
      // 显示配置选项
      type: Object,
      default: () => ({
        children: 'children',
        label: 'name',
      }),
    },
    multiple: {
      // 是否为多选树
      type: Boolean,
      default: false,
    },
    highlightCurrentRow: {
      // 是否高亮当前选中节点，如果multiple为true，此属性不起作用
      type: Boolean,
      default: true,
    },
    expandOnClickNode: {
      // 是否在点击节点的时候展开或者收缩节点
      type: Boolean,
      default: false,
    },
    defaultExpandAll: {
      // 是否默认展开所有节点
      type: Boolean,
      default: false,
    },
    defaultExpandedKeys: {
      // 默认展开的节点的 key 的数组
      type: Array,
      default: () => [],
    },
    defineConfirm: {
      // 确定时自己处理选中数据判断
      type: Boolean,
      default: false,
    },
    baseUrl: {
      // 定制化请求头
      type: String,
      default: '',
    },
  },
  data() {
    return {
      treeData: this.value ? this.value : null,
    }
  },
  watch: {
    // 选中数据发生变化时，同步更新上个页面
    treeData(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    // 弹框取消
    cancel() {
      this.$emit('cancel')
    },
    // 弹框确定
    confirm() {
      if (this.defineConfirm) {
        this.$emit('confirm', this.treeData)
        return
      }
      if ((!this.multiple && !this.treeData) || (this.multiple && !this.treeData.length)) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      this.$emit('confirm', this.treeData)
    },
    // 节点点击回调
    nodeClick(treeRow) {
      this.$emit('nodeClick', treeRow)
    },
    // 当复选框被点击的时候触发
    check(treeRows) {
      this.$emit('check', treeRows)
    },
    // 根据条件查询数据
    searchList(searchParams) {
      this.$refs.baseTree.searchList(searchParams)
    },
  },
}
