export default {
    title: '企业权限',
    name: '企业名称',
    industry: '所属行业',
    unknown: '未知',
    contacts: '联系人',
    contactsInformation: '联系方式',
    email: '联系邮箱',
    limitedNumberOfAttendees: '参会人数限定',
    simultaneousCommunicationLimit: '通信并发数限定',
    setLimitedNumberOfAttendees: '允许参会人数设定',
    setSimultaneousCommunicationLimit: '通信并发数设定',
    unlimited: '不限',
    workFlowFileLimitNum: '允许创建工作流文件数量(个)',
    workFlowTaskLimitNum: '允许创建任务数量(个)',
    workFlowExInTimeLimitNum: '任务执行并发人数(个)',
}
