/**
 * 表单验证规则
 * item包含验证字段{
 *    label:         提示语
 *    noNull: true   非空验证
 *    type: 'mobile' 类型验证
 *    len: 4         长度验证(优先级高于min、max)
 *    min: 2         最小长度验证
 *    max: 6         最大长度验证
 * }
 * */
import i18n from '@/lang'

// 长度验证，优先判断长度，然后判断范围
const validateLen = (item, value, callback) => {
  value = value.toString()
  // 长度验证
  if (item.len && value.length !== item.len) {
    //lang:长度应为
    callback(new Error(`${item.label}${i18n.t('base.len')}${item.len}`))
    return
  }
  // 最小长度验证
  if (item.min && !item.max && value.length < item.min) {
    //lang:最小长度应为
    callback(new Error(`${item.label}${i18n.t('base.min')}${item.min}`))
    return
  }
  // 最大长度验证
  if (item.max && !item.min && value.length > item.max) {
    //lang:最大长度应为
    callback(new Error(`${item.label}${i18n.t('base.max')}${item.max}`))
    return
  }
  // 长度范围验证
  if (value.length < item.min || value.length > item.max) {
    //lang:最小长度应为
    //lang:最大长度应为
    callback(new Error(`${item.label}${i18n.t('base.min')}${item.min}${i18n.t('base.max')}${item.max}`))
    return
  }
  return true
}

// 类型验证
const validateType = (item, value, callback) => {
  switch (item.type) {
    case 'mobile': // 手机号
      // if (/^1\d{10}$/.test(value)) return true
     if (/^[1][3-9][0-9]{9}$/.test(value)) return true
      //lang:格式不正确
      callback(new Error(`${item.label}${i18n.t('base.incorrectFormat')}`))
      break
    case 'phone': // 固话
      if (/^(0\d{2,3}-)?\d{7,8}(-\d+)?$|^0\d{10,11}(-\d+)?$|^\d{7,8}(-\d+)?$/.test(value)) return true
      //lang:格式不正确
      callback(new Error(`${item.label}${i18n.t('base.incorrectFormat')}`))
      break
    case 'mobileOrPhone': // 手机号或固话
      if (/^(0\d{2,3}-)?\d{7,8}(-\d+)?$|^0\d{10,11}(-\d+)?$|^\d{7,8}(-\d+)?$|^1\d{10}$/.test(value)) return true
      //lang:格式不正确
      callback(new Error(`${item.label}${i18n.t('base.incorrectFormat')}`))
      break
    case 'idCard': // 身份证
      if (/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) return true
      //lang:格式不正确
      callback(new Error(`${item.label}${i18n.t('base.incorrectFormat')}`))
      break
    case 'positive0': // 大于等于0的数字
      if (/^(0|([1-9]\d*))(\.\d+)?$/.test(value) || Number(value) >= 0) return true
      //lang:应为大于0的数字
      callback(new Error(`${item.label}${i18n.t('base.positive0')}`))
      break
    case 'positive': // 应为大于0的数字
      if (/^(0|([1-9]\d*))(\.\d+)?$/.test(value) && Number(value) > 0) return true
      //lang:应为大于0的数字
      callback(new Error(`${item.label}${i18n.t('base.positive')}`))
      break
    case 'int': // 整数
      if (/^-?[0-9]\d*$/.test(value)) return true
      //lang:应为整数
      callback(new Error(`${item.label}${i18n.t('base.int')}`))
      break
    case 'positiveInt': // 正整数
      if (/^\d+$/.test(value) && Number(value) > 0) return true
      //lang:应为大于0的整数
      callback(new Error(`${item.label}${i18n.t('base.positiveInt')}`))
      break
    case 'positiveInt0': // 大于等于0的整数
      if (/^[0-9]\d*$/.test(value)) return true
      //lang:应为大于等于0的整数
      callback(new Error(`${item.label}${i18n.t('base.positiveInt0')}`))
      break
    case 'email': // 邮箱
      if (/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(value)) return true
      //lang:格式不正确
      callback(new Error(`${item.label}${i18n.t('base.incorrectFormat')}`))
      break
    case 'numOrLetter': // 字母或数字
      if (/^[0-9a-zA-Z]*$/.test(value)) return true
      //lang:应为字母或数字
      callback(new Error(`${item.label}${i18n.t('base.numOrLetter')}`))
      break
    case 'letter': // 字母
      if (/^[a-zA-Z]*$/.test(value)) return true
      //lang:应为字母
      callback(new Error(`${item.label}${i18n.t('base.letter')}`))
      break
    case 'chinese': // 中文
      if (/^[\u4e00-\u9fa5]*$/.test(value)) return true
      //lang:应为中文
      callback(new Error(`${item.label}${i18n.t('base.chinese')}`))
      break
    // lang:首字母大写，至少1个数字，至少一个特殊字符，限制8-15个字符
    case 'password':
      if (/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,15}$/.test(value) && /^[A-Z]+$/.test(value.slice(0, 1))) return true
      callback(new Error(`${item.label}${i18n.t('base.validatePassword')}`))
      break
    case 'maxlength':
      if(value.length === 18) return true
      callback(new Error(`${item.label}${i18n.t('base.maxlength')}`))
      break
    case 'url':
      // var regexp = /^(?=^.{3,255}$)(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*([?&]\w+=\w*)*$/
      var regexp =  "^((https|http|ftp|rtsp|mms)?://)"
          + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" //ftp的user@
          + "(([0-9]{1,3}.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
          + "|" // 允许IP和DOMAIN（域名）
          + "([0-9a-z_!~*'()-]+.)*" // 域名- www.
          + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]." // 二级域名
          + "[a-z]{2,6})" // first level domain- .com or .museum
          + "(:[0-9]{1,4})?" // 端口- :80
          + "((/?)|" // a slash isn't required if there is no file name
          + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$"
      var re=new RegExp(regexp);
      if(re.test(value)) return true
      callback(new Error(`${item.label}${i18n.t('base.url')}`))
      break
    default: // do something
  }
}

// 导出规则
const validate = (item) => {
  if (!item) return
  const rules = []
  // 非空验证
  if (item.noNull)
    rules.push({
      trigger: 'blur',
      required: true,
      message: `${item.tips}${item.label}`,
    })
  // 自定义校验规则
  rules.push({
    trigger: 'blur',
    validator(_, value, callback) {
      if (!value) {
        callback()
        return
      }
      let passed = true
      // 类型验证
      if (item.type) passed = validateType(item, value, callback)
      // 长度验证
      if (passed && (item.min || item.max || item.len)) passed = validateLen(item, value, callback)
      if (passed) callback()
    },
  })
  console.log(rules)
  return rules
}

export default validate
