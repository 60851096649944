<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
      @openDialog="openDialog"
      width="650px"
    >
    <!-- 验证码 -->
    <template v-slot:authCode>
      <div style="position: relative">
          <el-input v-model="formData.authCode"  :placeholder="$t('adminAccount.codeText')" maxlength="6" >
          <el-button slot="suffix" size="mini"  @click.stop="sendcode" v-if="codeShow">{{$t('adminAccount.getCode')}}<span id="count"></span>
          </el-button>
          <el-button slot="suffix" size="mini" disabled v-if="!codeShow">{{$t('adminAccount.sendCode')}}{{count}}s</el-button>
        </el-input>
        <div class="el-form-item__error" v-show="codeErrshow">{{codeErrText}}</div>
      </div>
    </template>
    </dialog-form-list>
    <!-- 弹出表格 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      :defaultParams="dialogDefaultParams"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
    </dialog-table-list>
  </div>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    const cmd = this.$store.state.cmd
    return {
      cmd: cmd,
      formData: {},
      count: '', // 验证码倒计时
      timer:null ,
      codeShow: true,
      codeErrText:'',
      codeErrshow: false,
      config: [
        // lang:姓名
        {
          label: this.$t('adminAccount.name'),
          prop: 'name',
          rules: {
            noNull: true,
          },
        },
        // lang:手机号
        {
          label: this.$t('adminAccount.mobile'),
          prop: 'mobile',
          rules: {
            // noNull: true ,
            type: 'mobile',
          },
          tagProps: {
            maxLength: 11,
          },
          tagEvents:{
            change(data){
              // if (self.cmd !== 'add') return
              const reg = /^[1][3-9][0-9]{9}$/
              if (reg.test(data)) {
                if (self.cmd !== 'add') {
                  if(data === self.tableRow.mobile) {
                    self.config[2].hidden = true
                  }else {
                    self.config[2].hidden = false
                  }
                }else{
                  self.config[2].hidden = false
                }
              }else{
                self.config[2].hidden = true
              }
            },
          }
        },
        // 获取手机验证吗
        {
          tag: 'slot-content',
          label: this.$t('adminAccount.code'),
          prop: 'authCode',
          rules: {
            noNull: true,
          },
          hidden: true,
          tagProps: {
            placeholder: this.$t('base.validateCode'),
          },
        },
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'accountId',
          rules: {
            noNull: true,
          },
        },
        // lang:密码
        {
          label: this.$t('adminAccount.password'),
          prop: 'password',
          hidden: cmd === 'add' ? false : true,
          rules: {
            noNull: true,
            type: 'password',
          },
          tagProps: {
            showPassword: true,
            placeholder: this.$t('base.validatePassword'),
          },
        },
        // lang:账号类型
        {
          tag: 'el-select',
          label: this.$t('adminAccount.type'),
          prop: 'operatorType',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.roleType(),
          },
          tagEvents: {
            change(type) {
              if (type === 'COMPANY_MANAGER') {
                self.config[1].rules.noNull = true
              } else {
                self.config[1].rules.noNull = false
              }
              const hidden = type === 'COMPANY_MANAGER' ? false : true
              self.config[6].hidden = hidden
            },
          },
        },
        // lang:所属企业
        {
          tag: 'dialog-define',
          label: this.$t('adminAccount.company'),
          prop: 'company',
          hidden: false,
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          },
        },
        // lang:昵称
        {
          label: this.$t('adminAccount.nickname'),
          prop: 'nickName',
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
        },
      ],
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: null,
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
    },
    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      // 选择设备
      this.dialogTitle = this.$t('adminAccount.companyInfo')
      this.dialogUrl = this.$api.companyFind
      this.dialogDefaultParams = {
        productType : this.$util.getLocalStorage('productType')
      }
      this.dialogColumns = [
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
        },
        // lang:行业
        {
          label: this.$t('companyManage.industry'),
          prop: 'industry',
          callback(row) {
            return row && row.industry && row.industry.value
          },
        },
        // lang:地址
        {
          label: this.$t('companyManage.address'),
          prop: 'address',
          callback(row) {
            return row && row.address && row.address.value
          },
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang:邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
        },
      ]
      this.dialogConfig = [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('companyManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 11,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ]
      this.showDialogTableList = true
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.tableList.searchList(dialogFormData)
    },
    // 设置默认值
    setDefaultValue() {
      console.log(this.tableRow)
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          console.log(this.formData, this.tableRow)
          this.$util.setDefaultValue(this.formData, this.tableRow)
          this.formData.operatorType = this.tableRow.operator.operatorType
          // 禁掉账号类型
          this.config[5].tagProps.disabled = true
          // 处理所属企业
          if (this.tableRow.operator.operatorType === 'COMPANY_MANAGER') {
            this.config[6].tagProps.disabled = true
            this.config[6].hidden = false
            this.config[1].rules.noNull = true
          } else {
            this.config[6].hidden = true
            this.config[1].rules.noNull = false
          }
        } else {
          const operator = this.$util.getSessionStorage('operator')
          console.log(operator)
          const { company } = operator
          const { level, canAddSubCompany } = company
          if (level !== 'ONE') {
            if (!canAddSubCompany || canAddSubCompany === 'NO') {
              console.log(canAddSubCompany)
              const item = this.$dict.roleType()[1]
              this.config[5].tagProps.options = [item]
              this.config[5].tagProps.disabled = true
              this.formData.operatorType = 'SUB_MANAGER'
              this.config[6].hidden = true
            } else {
              this.formData.operatorType = 'COMPANY_MANAGER'
            }
          } else {
            this.formData.operatorType = 'COMPANY_MANAGER'
          }

          this.config[1].rules.noNull = true
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      // 处理所属企业
      if (formData.operatorType !== 'COMPANY_MANAGER') delete formData.company
      // 处理密码
      if (this.cmd !== 'add') delete formData.password
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      console.log(formData)
      if(formData.operatorType === 'SUB_MANAGER'){
        const operator = this.$util.getSessionStorage('operator')
        const { company } = operator
        formData.companyId = company.id
      }else{
        const companyId = formData.company.id
        formData.companyId = companyId
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.operatorAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      }).catch((error) =>{
        this.$element.showMsg(this.$t('serverCode.' + error.msg), 'warning')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      // Object.assign(tableRow, formData)
      formData.id = tableRow.id
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.operatorUpdate,
        data: formData,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
    onInputCode() {
      this.codeErrshow = false
      this.codeErrText = ""
    },
    // 获取验证码
    sendcode() {
      // 验证码
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.sendCode,
        data:{
          mobile: this.formData.mobile,
        }
      }).then((data) => {
        console.log(data)
        this.formData.authCodeSeqNo = data
      })
      const TIME_COUNT = 30   //倒计时60秒
      if(!this.timer) {
        this.count = TIME_COUNT
        this.codeShow = false
        this.timer = setInterval(() => {
          if(this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.codeShow = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
  },
}
</script>
