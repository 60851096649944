// 部门管理
export default {
  title: '会社ユーザーアカウントテーブル',
  title_platform: 'プラットフォームユーザーアカウントテーブル',
  groupTitle: '会社部署グループ',
  groupTitle_platform: 'プラットフォーム部署グループ',
  addFirstGroup: '追加',
  name: '部署名称',
  topName: '上級部署',
  level: '所属レベル',
  accountGroup: '所属部署',
}
