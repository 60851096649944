// 功能枚举
export default {
  // 功能管理
  ALL_FUNCTION: 'All Function',
  // 系统管理
  SYSTEM_MANAGE: 'System management',
  FUNCTION_MANAGE: 'Functional management',
  FUNCTION_MANAGE_ADD: 'Add',
  FUNCTION_MANAGE_EDIT: 'Edit',
  FUNCTION_MANAGE_DELETE: 'Delete',
  FUNCTION_MANAGE_VIEW: 'Details',
  CUSTOM_FUNCTION_MANAGE: 'Privatization function management',
  CUSTOM_FUNCTION_MANAGE_ADD: 'Add',
  CUSTOM_FUNCTION_MANAGE_EDIT: 'Edit',
  CUSTOM_FUNCTION_MANAGE_DELETE: 'Delete',
  CUSTOM_FUNCTION_MANAGE_VIEW: 'Details',
  // 角色管理
  ROLE_MANAGE: 'Role management',
  ROLE_MANAGE_ADD: 'Add',
  ROLE_MANAGE_EDIT: 'Edit',
  ROLE_MANAGE_DELETE: 'Delete',
  ROLE_MANAGE_VIEW: 'Details',
  ROLE_MANAGE_GRANT_PERMISSION: 'Authorize',
  // 账号管理
  ACCOUNT_MANAGE: 'Account management',
  // 管理员账号
  OPERATOR_MANAGE: 'Administrator account',
  OPERATOR_MANAGE_ADD: 'Add',
  OPERATOR_MANAGE_EDIT: 'Edit',
  OPERATOR_MANAGE_DELETE: 'Delete',
  OPERATOR_MANAGE_VIEW: 'Details',
  OPERATOR_MANAGE_DISABLE: 'Disable',
  OPERATOR_MANAGE_RECOVER: 'Enable',
  OPERATOR_MANAGE_RESET_PASSWORD: 'Reset password',
  OPERATOR_MANAGE_GRANT_ROLE: 'Assign role',
  // 企业用户账号
  USER_ACCOUNT_MANAGE: 'Company user account',
  USER_ACCOUNT_MANAGE_ADD: 'Add',
  USER_ACCOUNT_MANAGE_EDIT: 'Edit',
  USER_ACCOUNT_MANAGE_DELETE: 'Delete',
  USER_ACCOUNT_MANAGE_VIEW: 'Details',
  USER_ACCOUNT_MANAGE_DISABLE: 'Disable',
  USER_ACCOUNT_MANAGE_RECOVER: 'Enable',
  USER_ACCOUNT_MANAGE_RESET_PASSWORD: 'Reset password',
  USER_ACCOUNT_MANAGE_EXPORT_QR_CODE: 'Batch Export qr Code',
  USER_ACCOUNT_MANAGE_TRAJECTORIES: 'Track diagram',
  USER_ACCOUNT_MANAGE_RENEW: 'Renew',
  USER_ACCOUNT_IMPORT_FROM_EXCEL: 'Importing user Accounts',
  // 平台用户账号
  SCOPE_USER_ACCOUNT_MANAGE: 'Platform user account',
  SCOPE_USER_ACCOUNT_MANAGE_ADD: 'Add',
  SCOPE_USER_ACCOUNT_MANAGE_EDIT: 'Edit',
  SCOPE_USER_ACCOUNT_MANAGE_DELETE: 'Delete',
  SCOPE_USER_ACCOUNT_MANAGE_VIEW: 'Details',
  SCOPE_USER_ACCOUNT_MANAGE_DISABLE: 'Disable',
  SCOPE_USER_ACCOUNT_MANAGE_RECOVER: 'Enable',
  SCOPE_USER_ACCOUNT_MANAGE_RESET_PASSWORD: 'Reset password',
  SCOPE_USER_ACCOUNT_MANAGE_EXPORT_QR_CODE: 'Batch Export qr Code',
  SCOPE_USER_ACCOUNT_MANAGE_TRAJECTORIES: 'Track diagram',
  SCOPE_USER_ACCOUNT_MANAGE_RENEW: 'Renew',
  SCOPE_USER_ACCOUNT_IMPORT_FROM_EXCEL: 'Importing user Accounts',
  // 字典管理
  DICT_MANAGE: 'Dictionary management',
  DICT_MANAGE_ADD: 'Add',
  DICT_MANAGE_EDIT: 'Edit',
  DICT_MANAGE_DELETE: 'Delete',
  DICT_MANAGE_VIEW: 'Details',
  // 企业管理
  COMPANY_MANAGE: 'Company management',
  COMPANY_MANAGE_ADD: 'Add',
  COMPANY_MANAGE_EDIT: 'Edit',
  COMPANY_MANAGE_DELETE: 'Delete',
  COMPANY_MANAGE_VIEW: 'Details',
  COMPANY_IMPORT_FROM_EXCEL: 'Import the enterprise',
  COMPANY_INVOICE: 'Invoice information',
  COMPANY_BALANCE: 'Check the balance',
  COMPANY_CONFIG_MANAGE: 'Private service enterprise configuration',
  COMPANY_CONFIG_MANAGE_ADD: 'Add',
  COMPANY_CONFIG_MANAGE_EDIT: 'Edit',
  COMPANY_CONFIG_MANAGE_DELETE: 'Delete',
  COMPANY_CONFIG_MANAGE_VIEW: 'Details',
  COMPANY_GRANT_PERMISSION: 'Functional authorization',
  COMPANY_QRCODE_BATCH_EXPORT: '导出企业码',
  // 日志管理
  LOG_MANAGE: 'Log management',
  CONTEXT_LOG_MANAGE: 'Session log',
  CONTEXT_LOG_MANAGE_VIEW: 'Details',
  CLIENT_LOG_MANAGE: 'Client logs',
  CLIENT_LOG_DOWNLOAD: 'Download',
  CLIENT_LOG_MANAGE_DOWNLOAD: 'Download',
  // 财务管理
  FINANCE_MANAGE: 'Financial management',
  ASSIGN_TO_ME_MANAGE: 'Allocate bill',
  ASSIGN_TO_ME_MANAGE_VIEW: 'Details',
  FINANCE_KEY_AUTHORIZATION_RECORD:'Key bill',
  // 授权分配
  ASSIGN_TO_OTHER_MANAGE: 'Authorize assignment',
  ASSIGN_TO_OTHER_MANAGE_ADD: 'Add authorization',
  ASSIGN_TO_OTHER_MANAGE_ROLLBACK: 'Revoke',
  ASSIGN_TO_OTHER_MANAGE_VIEW: 'Details',
  // 账单查询
  FINANCIAL_FLOW_RECORD: 'Billing inquiries',
  // 设备管理
  EQUIPMENT: 'Device management',
  EQUIPMENT_MANAGE: 'Company device information',
  EQUIPMENT_GROUP_MANAGE_ADD: 'Add',
  EQUIPMENT_GROUP_MANAGE_EDIT: 'Edit',
  EQUIPMENT_GROUP_MANAGE_DELETE: 'Delete',
  EQUIPMENT_MANAGE_ADD: 'Add device',
  EQUIPMENT_MANAGE_EDIT: 'Edit',
  EQUIPMENT_MANAGE_DELETE: 'Delete',
  EQUIPMENT_MANAGE_VIEW: 'Details',
  SCOPE_EQUIPMENT_MANAGE: 'Platform device information',
  SCOPE_EQUIPMENT_GROUP_MANAGE_ADD: 'Add',
  SCOPE_EQUIPMENT_GROUP_MANAGE_EDIT: 'Edit',
  SCOPE_EQUIPMENT_GROUP_MANAGE_DELETE: 'Delete',
  SCOPE_EQUIPMENT_MANAGE_ADD: 'Add device',
  SCOPE_EQUIPMENT_MANAGE_EDIT: 'Edit',
  SCOPE_EQUIPMENT_MANAGE_DELETE: 'Delete',
  SCOPE_EQUIPMENT_MANAGE_VIEW: 'Details',
  EQUIPMENT_MANAGE_COPY: 'Copy to',
  EQUIPMENT_MANAGE_MOVE: 'Move to',
  // 自用设备信息
  CUSTOM_EQUIPMENT: 'Self-use device information',
  CUSTOM_EQUIPMENT_GROUP_MANAGE_ADD: 'Add',
  CUSTOM_EQUIPMENT_GROUP_MANAGE_EDIT: 'Edit',
  CUSTOM_EQUIPMENT_GROUP_MANAGE_DELETE: 'Delete',
  CUSTOM_EQUIPMENT_MANAGE_ADD: 'Add device',
  CUSTOM_EQUIPMENT_MANAGE_EDIT: 'Edit',
  CUSTOM_EQUIPMENT_MANAGE_DELETE: 'Delete',
  CUSTOM_EQUIPMENT_MANAGE_VIEW: 'Details',
  CUSTOM_EQUIPMENT_MANAGE_COPY: 'Copy to',
  CUSTOM_EQUIPMENT_MANAGE_MOVE: 'Move to',
  CUSTOM_EQUIPMENT_MANAGE_EXPORT: 'Export QR codes',
  // 地址池管理
  POOL: 'Address pool management',
  EQUIPMENT_POOL_CODE: 'Device code',
  EQUIPMENT_POOL_CODE_ADD: 'Add',
  EQUIPMENT_POOL_CODE_LIST: 'Inquire',
  EQUIPMENT_POOL_CODE_DELETE: 'Delete',
  GATEWAY_POOL: 'Gateway address pool',
  GATEWAY_POOL_ADD: 'Add',
  GATEWAY_POOL_UPDATE: 'Edit',
  GATEWAY_POOL_DELETE: 'Delete',
  GATEWAY_POOL_VIEW: 'Details',
  AR_GATEWAY_IMPORT: 'Importing gateway Address',
  AR_POOL: 'AR device address pool',
  AR_POOL_ADD: 'Add',
  AR_POOL_UPDATE: 'Edit',
  AR_POOL_DELETE: 'Delete',
  AR_POOL_VIEW: 'Details',
  AR_POOL_IMPORT: 'Import the AR device address',
  // 销售信息
  SALE: 'Sales information',
  SALE_ADD: 'Add',
  SALE_UPDATE: 'Edit',
  SALE_DELETE: 'Delete',
  SALE_VIEW: 'Details',
  SALE_EXPORT: 'Export sales QR code',
  SALE_LIST_DELETE: 'Delete records',
  // 销售Details
  SALE_EQUIPMENT_LIST: 'List of sales details',
  // 客户管理
  CUSTOMER: 'Customer management',
  CUSTOMER_ADD: 'Add',
  CUSTOMER_UPDATE: 'Edit',
  CUSTOMER_DELETE: 'Delete',
  CUSTOMER_VIEW: 'Details',
  CUSTOMER_IMPORT: 'Customer information import',
  // AR数显管理
  AR_DIGTAL_POOL: 'AR digital display management',
  AR_DIGTAL_ADD: 'Add',
  AR_DIGTAL_UPDATE: 'Edit',
  AR_DIGTAL_DELETE: 'Delete',
  AR_DIGTAL_VIEW: 'Details',
  AR_DIGTAL_IMPORT: 'Import AR digital display',
  AR_DIGTAL_EXPORT: 'Export sales QR code',
  // 部门管理
  GROUP_MANAGE: 'Departmental management',
  GROUP_MANAGE_USER_ACCOUNT_GROUP: 'Company department grouping',
  GROUP_MANAGE_USER_ACCOUNT_GROUP_ADD: 'Add',
  GROUP_MANAGE_USER_ACCOUNT_GROUP_UPDATE: 'Edit',
  GROUP_MANAGE_USER_ACCOUNT_GROUP_DELETE: 'Delete',
  GROUP_MANAGE_USER_ACCOUNT_GROUP_VIEW: 'Details',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP: 'Platform department grouping',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_ADD: 'Add',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_UPDATE: 'Edit',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_DELETE: 'Delete',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_VIEW: 'Details',
  // 信息管理
  INFO_MANAGE: 'Information management',
  INFO_MANAGE_ME: 'Personal information management',
  INFO_MANAGE_ME_CHANGE_PASSWORD: 'Change password',
  INFO_MANAGE_COMPANY: 'Company information management',
  INFO_MANAGE_COMPANY_UPDATE: 'Modify company information',
  INFO_MANAGE_COMPANY_UPDATE_INVOICE: 'Modify invoice information',
  COMPANY_QRCODE_EXPORT: '导出企业码',
  // 网关管理
  GATEWAY_EQUIPMENT: 'Gateway management',
  GATEWAY_EQUIPMENT_RESET: 'Reset password',
  GATEWAY_EQUIPMENT_EXPORT: 'Export sales QR code',
  GATEWAY_EQUIPMENT_OP_LOG: 'The action log',
  // 消息管理
  MESSAGE_MANAGE: 'Message management',
  MESSAGE_MANAGE_ADD: 'Add',
  MESSAGE_UPDATE: 'Edit',
  MESSAGE_DELETE: 'Delete',
  MESSAGE_VIEW: 'Details',
  MESSAGE_ROLLBACK: 'Withdraw',
  // 版本管理
  VERSION_MANAGE_APK: 'Android version management',
  VERSION_MANAGE_APK_ADD: 'Add',
  VERSION_MANAGE_APK_UPDATE: 'Edit',
  VERSION_MANAGE_APK_DELETE: 'Delete',
  VERSION_MANAGE_APK_VIEW: 'Details',
  VERSION_MANAGE_PC: 'PC version management',
  VERSION_MANAGE_PC_ADD: 'Add',
  VERSION_MANAGE_PC_UPDATE: 'Edit',
  VERSION_MANAGE_PC_DELETE: 'Delete',
  VERSION_MANAGE_PC_VIEW: 'Details',
  // 设备激活码
  TERMINAL_MANAGE: '终端设备管理',
  TERMINAL_ACTIVATION_CODE_MANAGE: '设备激活码管理',
  TERMINAL_ACTIVATION_CODE_MANAGE_ADD: '新增',
  TERMINAL_ACTIVATION_CODE_MANAGE_UPDATE: '禁用',
  TERMINAL_ACTIVATION_CODE_MANAGE_EXPORT: '批量导出二维码',
  // 验证码
  SEND_MESSAGE_AUTH_CODE_000003: "手机号验证码已达后去上限",
  // 文档管理
  DOCUMENT_MANAGE:'文档管理',
  DOCUMENT_MANAGE_FIND:'查找',
  DOCUMENT_MANAGE_ADD:'新增',
  DOCUMENT_MANAGE_DELETE:'删除',
  // 产品服务开通
  PRODUCT_SERVICE_MANAGE:'产品服务开通',
  // 客户管理 新用户注册
  REGISTER_COMPANY_MANAGE:'注册用户管理',
  REGISTER_COMPANY_INFO:'详情',
  REGISTER_COMPANY_ALLOT_SP:'分配服务商',
  // 账号密钥授权
  KEY_AUTHORIZATION_MANAGE:'账号密钥授权',
  KEY_AUTHORIZATION_MANAGE_FIND:'密钥分配',
}
