// 服务返回code
export default {
  // 请求响应状态-state
  SUCCESS: '成功',
  ERROR: '错误',
  SYSTEM_ERROR: '系统错误',
  NO_PERMISSION: '没有权限',
  NO_LOGIN: '没有登录',
  KICK_OUT: '被踢出',
  // 请求响应错误码-msg-账户相关
  ACCOUNT_000001: '用户名或密码错误',
  ACCOUNT_000002: '账户异常',
  ACCOUNT_000003: '平台用户账号是不能够被添加和发起添加联系人',
  ACCOUNT_000004: '账号无法续订',
  ACCOUNT_000005: '账号已登陆',
  ACCOUNT_000006: '同企业用户账号无法添加',
  ACCOUNT_000007: '已是好友无法添加',
  ACCOUNT_000008: '已发起过申请',
  ACCOUNT_000009: '已处理过该申请（已同意或已拒绝过）',
  ACCOUNT_000010: '设备唯一码不合法',
  ACCOUNT_000011: '非本账号外部联系人',
  ACCOUNT_000012: '密码格式不正确',
  ACCOUNT_000013: '账号与设备类型不对应',
  ACCOUNT_000014: '当前账号不能添加外部联系人',
  ACCOUNT_000015: '对方账号不能添加外部联系人',
  ACCOUNT_000016: '登录名已存在',
  ACCOUNT_000017: '企业下已有超级管理员',
  ACCOUNT_000018: '私有云不能登录公有云',
  // 请求响应错误码-msg-接口请求
  API_000001: '未登录',
  API_000002: '未授权',
  API_000003: '未知接口',
  API_000004: '非法参数',
  API_000005: '不支持的请求方法',
  API_000006: 'http体读写失败',
  // 请求响应错误码-msg-数据访问
  DATA_000001: '数据访问异常',
  DATA_000002: '数据不存在',
  DATA_000003: '数据已存在',
  // 请求响应错误码-msg-文件操作
  FILE_000001: '文件大小超限',
  FILE_000002: '创建文件夹失败',
  FILE_000003: '上传失败',
  FILE_000004: '获取文件上传签名失败',
  FILE_000005: '文件上传回调验签失败',
  FILE_000006: '文件上传回调解析参数失败',
  // 请求响应错误码-msg-其它错误
  OTHER_000001: '未知异常',
  OTHER_000002: '操作失败',
  // 请求响应错误码-msg-设备相关
  EQUIPMENT_000001: '非本企业共享数据不可编辑',
  EQUIPMENT_000002: '设备分组下面有设备不允许删除',
  EQUIPMENT_000003: '设备/部件已经销售不允许删除',
  // 请求响应错误码-msg-销售客户相关
  CUSTOMER_000001: '删除失败，此客户已关联销售信息',
  // 请求响应错误码-msg-地址池信息
  POOL_000001: '分配地址池信息 数量不够提示',
  POOL_000002: '池子类型不存在',
  POOL_000003: '设备池子数量不够',
  POOL_000004: '部件池子数量不够',
  POOL_000005: '企业没有销售资源/资质',
  POOL_000006: '企业AR数显信息资源不足',
  // 请求响应错误码-msg-EXCEL相关
  EXCEL_000001: '读取excel文件失败',
  EXCEL_000002: '读取excel数据失败',
  // 请求响应错误码-msg-部门相关
  USER_ACCOUNT_GROUP_000001: '部门下含有子部门无法删除',
  USER_ACCOUNT_GROUP_000002: '部门下含有账号无法删除',
  // 请求响应错误码-msg-授权资源
  SERVICE_RESOURCE_000001: '授权余量不足',
  SERVICE_RESOURCE_000002: '不支持的资源计费模式',
  SERVICE_RESOURCE_000003: '未找到授权类型对应的码池类型',
  SERVICE_RESOURCE_000004: '目标企业授权余量不足',
  // 请求响应错误码-msg-网关设备
  GATEWAY_EQUIPMENT_000001: '网关设备不存在',
  GATEWAY_EQUIPMENT_000002: '网关文件读取错误',
  // 请求响应错误码-msg-好视通
  HST_ERROR_000001: '调用好视通接口失败',
  // 消息通知错误码-msg-消息通知
  MESSAGE_000001: '消息已撤回无法继续撤回',
  // 请求响应错误码-msg-版本信息
  VERSION_000001: '不能删除主版本',
  // 请求响应错误码-msg-角色相关
  ROLE_000001: '角色下含有操作员数据无法删除',
  // 请求响应错误码-msg-企业相关
  COMPANY_000001: '含有下级企业无法删除',
  COMPANY_000002: '企业下含有后台账号无法删除',
  COMPANY_000003: '企业下含有前台账号无法删除',
  COMPANY_000004: '企业数据不存在',
  COMPANY_000005: '定制化企业code重复',
  COMPANY_000006: '该企业没有权限创建下级企业',
  // 忘记密码
  ACCOUNT_MOBILE_ERROR:'当前手机号码错误',
  ACCOUNT_MOBILE_NULL: '当前手机号码未绑定',
  SEND_MESSAGE_AUTH_CODE_000002:'短信验证码错误',
  COMPANY_HAS_CREDITCODEFORINVOICE:'统一社会信用代码已存在',
  COMPANY_HAS_NAME:'企业名称已存在',
  COMPANY_HAS_EMAIL:'电子邮箱已存在',
  COMPANY_HAS_BANK_ACCOUNT_NUMBER:'企业开户账号已存在',
  ACCOUNT_HAS_MOBILE:'当前手机号码已存在,请更换后重新提交',
  REGISTER_ACCOUNT_000002: '登录账号已存在,是否要加入新的企业?',
  PARAMETERS_EXCEPTION: '参数异常',
}
