<template>
  <div class="bothColumPage">
    <div class="leftTree">
      <div class="topTitle">
        <h2 class="leftTreeH2">
          <!-- lang:名称 -->
          <slot name="left-title">{{ $t('base.name') }}</slot>
        </h2>
      </div>
      <div class="treeContent scollBar">
        <slot name="left" />
      </div>
    </div>
    <div class="rightTable">
      <base-page
        :style="{ top: 0, left: 0, right: right, bottom: 0 }"
        :title="title"
        :config="config"
        :formData="formData"
        :labelWidth="labelWidth"
        :gutter="gutter"
        :search-span="searchSpan"
        :search-align="searchAlign"
        @functionMethod="functionMethod"
        @search="search"
      >
        <template v-slot:search>
          <slot name="search" />
        </template>
        <template v-slot:functionButton>
          <slot name="functionButton" />
        </template>
        <slot></slot>
      </base-page>
      <slot name="other" />
    </div>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>