<template>
  <div class="uploadFile">
    <el-upload
      ref="upload"
      action=""
      :accept="accept"
      :show-file-list="false"
      :http-request="httpRequest"
      :before-upload="beforeUpload"
      :on-error="onError"
      :disabled="disabled"
    >
      <el-button
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0.4)"
        class="uploadButton"
        icon="el-icon-plus"
        size="mini"
        :disabled="disabled"
      >
        {{ $t('base.uploadFile') }}
      </el-button>
    </el-upload>
    <p class="fileType">
      {{ $t('base.fileTypeTitle') + accept + $t('base.fileTypeEnd') }}
    </p>
    <ul>
      <li v-for="(item, index) in value" :key="item.fileName">
        <el-link
          :href="item.fileHost + '/' + item.objectName"
          :icon="getIcon(item)"
          :download="item.originalName"
          :underline="false"
          target="_blank"
        >
          {{ item.originalName }}
        </el-link>
        <i
          class="el-icon-circle-check optionIcon"
          :class="{ isDisabled: disabled }"
        />
        <i
          class="el-icon-circle-close optionIcon"
          @click="deleteMethod(index)"
          v-if="!disabled"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>