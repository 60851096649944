// 设备管理
export default {
  // 筛选
  nameOrNotes: '设备名称/编号/型号',
  // 分组
  title: '企业设备信息表',
  groupTitle: '企业设备分组',
  title_platform: '平台设备信息表',
  groupTitle_platform: '平台设备分组',
  customTitle: '自用设备信息表',
  customGroupTitle: '自用设备分组',
  addFirstGroup: '新增',
  groupName: '分组名称',
  topName: '上级分组',
  level: '所属层级',
  enterTitle: '请输入标题',
  enterContent: '请输入内容',
  // 表格
  name: '名称',
  number: '编号',
  model: '型号',
  specifications: '规格',
  userAccountGroup: '所属部门',
  selectGroup: '请选择设备分组数据',
  addEquipment: '新增设备',
  addPort: '新增部件',
  addIssue: '新增故障信息',
  equipmentInfo: '设备',
  partInfo: '部件',
  faultInfo: '故障',
  scope: '可见范围',
  type: '类型',
  selectEquipment: '选择设备',
  selectGroup_1: '请选择分组',
  selectEquipmentTips: '请选择设备',
  selectPartTips: '请选择部件',
  selectDefautTips: '请选择故障',
  company: '所属企业',
  hasEquipment: '当前分组下已经有设备，无法新建设备分组',
  equipmentType: '分组类型',
  equipmentCompnay: '企业设备分组',
  equipmentPlatform: '平台设备分组',
  copyEqTips: '只能选择设备分组最后一层级',
  copyData: '复制到',
  purchaseTime: '购置日期',
  // 设备
  equipmentName: '设备名称',
  equipmentGroup: '所属分组',
  equipmentUserAccountGroup: '所属部门',
  equipmentNumber: '出厂编号',
  equipmentNumber_1: '设备编号',
  equipmentModel: '设备型号',
  equipmentSpecifications: '设备规格',
  equipmentDetail: '设备描述',
  equipmentImageSet: '设备图片',
  equipmentFileSet: '相关附件',
  equipmentDetail_1: '设备详情',
  // 部件
  partName: '部件名称',
  partEquipment: '所属设备',
  partNumber: '出厂编号',
  partNumber_1: '部件编号',
  partModel: '部件型号',
  partSpecifications: '部件规格',
  partDetail: '部件描述',
  partImageSet: '部件图片',
  partFileSet: '相关附件',
  partDetail_1: '部件详情',
  // 故障信息
  faultName: '故障名称',
  faultNumber: '故障代码',
  faultPart: '所属部件',
  faultDetail: '故障描述',
  faultSolution: '解决方案',
  faultImageSet: '故障图片',
  faultFileSet: '相关附件',
  faultDetail_1: '故障信息',
  code: '设备二维码',
  companyEqSelf: '自用设备',
  companyEq: '销售设备',
  platformEq: '平台设备',
}
