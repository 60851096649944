export default {
  name: 'formList',
  props: {
    config: {
      // 表单配置项
      type: Array,
      default: () => [],
    },
    formData: {
      // 绑定数据
      type: Object,
      default: () => ({}),
    },
    labelWidth: {
      // 文本标题宽度
      type: String,
      default: '150px',
    },
    gutter: {
      // 表单间隔距离
      type: Number,
      default: 10,
    },
    disabled: {
      // 是否禁用表单
      type: Boolean,
      default: false,
    },
    isSearch: {
      // 是否是搜索表单
      type: Boolean,
      default: false,
    },
    searchSpan: {
      // 搜索按钮宽度
      type: Number,
      default: 4,
    },
    searchAlign: {
      // 搜索按钮对其方式
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      // 弹出树
      targetItem: {},
      showDialogTree: false,
    }
  },
  computed: {
    // 是否禁用
    isDisabled() {
      return this.isSearch ? false : this.disabled || this.$store.state.cmd === 'view'
    },
    // 设置一些默认值
    newConfig() {
      return this.config.map((item) => {
        // 默认输入框
        item.tag = item.tag || 'el-input'
        // 默认属性
        item.tagProps = item.tagProps || {}
        const defaultTagProps = {
          clearable: true,
          filterable: true,
          placeholder: item.tag === 'el-input' ? this.$t('base.enter') : this.$t('base.select'),
        }
        Object.assign(defaultTagProps, item.tagProps)
        item.tagProps = defaultTagProps
        // 日期
        if (item.tag === 'el-date-picker') {
          item.tagProps.valueFormat = item.tagProps.valueFormat || 'yyyy-MM-dd'
        }
        // 显示统计字数
        item.tagProps.showWordLimit = true
        return item
      })
    },
  },
  mounted() {
    this.setFormDataKey()
    console.log('newConfig', this.newConfig)
  },
  methods: {
    // 动态给响应对象添加属性
    setFormDataKey() {
      this.config.forEach((item) => {
        let defaultValue = this.formData[item.prop] || ''
        if (!defaultValue) {
          if (item.tag === 'upload-file' || item.tag === 'upload-image' || item.tag === 'el-checkbox-group' || item.tag === 'el-cascader' || (item.tagProps && item.tagProps.multiple)) {
            defaultValue = []
          }
        }
        this.$set(this.formData, item.prop, defaultValue)
      })
    },
    // 查询按钮点击回调
    search() {
      this.$emit('search', true)
    },
    // 重置表单
    resetForm() {
      this.$refs.formList.resetFields()
      this.$element.showMsg(this.$t('base.resetTips'))
      this.$emit('search', false)
    },
    // 打开弹出树
    dialogTableListOpen(item) {
      if (this.isDisabled || item.tagProps.disabled) return
      if (item.tag === 'dialog-define') {
        this.$emit('openDialog', item)
        return
      }
      if (this.formData[item.prop]) item.tagProps.value = this.formData[item.prop]
      this.targetItem = item
      this.showDialogTree = true
    },
    // 弹出树点击取消回调
    dialogTableListCancel() {
      this.showDialogTree = false
    },
    // 弹出树点击确定回调
    dialogTableListConfirm(tableData) {
      this.formData[this.targetItem.prop] = tableData
      this.showDialogTree = false
    },
    // 清除选中数据
    clearDialogValue(item) {
      item.tagProps.value = null
      if (item.tagProps.multiple) {
        this.formData[item.prop] = []
      } else {
        this.formData[item.prop] = null
      }
    },
    //
    querySearchAsync(e,callback) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findByNameOrMark,
        data:{
          name: e,
        }
      }).then((data) => {
        const targetItem = [{
          value: data.name,
          id: data.id
        }]
        callback(this.$util.copyData(targetItem))
      }).catch((error)=>{
        console.log(error)
       })
    },
    handleSelect(e) {
      // console.log(e)
    }
  },
}
