export default {
  name: 'baseDialog',
  props: {
    title: {
      // 显示标题
      type: String,
      default: '',
    },
    width: {
      // 宽度
      type: String,
      default: '500px',
    },
    height: {
      // 高度
      type: String,
      default: 'auto',
    },
    showConfirm: {
      // 是否显示确定按钮
      type: Boolean,
      default: true,
    },
    center: {
      // 底部操作按钮是否左右居中
      type: Boolean,
      default: true,
    },
    showClose: {
      // 是否显示右上角关闭按钮
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: true,
    }
  },
  methods: {
    // 关闭前的回调，会暂停 Dialog 的关闭
    beforeClose() {
      this.cancel()
    },
    // 弹框取消
    cancel() {
      this.$emit('cancel')
    },
    // 弹框确定
    confirm() {
      this.$emit('confirm')
    },
  },
}
