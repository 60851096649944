import { resetRouter } from '@/router'
import screenfull from 'screenfull'
import bus from '@/utils/bus'
export default {
  data() {
    return {}
  },
  computed: {
    // 操作员信息
    operator() {
      const { operator } = this.$store.state.operator
      console.log(operator)
      return operator || {}
    },
    // 企业名称
    companyName() {
      return this.operator.company && this.operator.company.name
    },
  },
  methods: {
    // 设置操作
    setCommand(command) {
      if (command === 'logout') {
        this.$element
          .showConfirm({
            content: this.$t('base.quitConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: this.$api.logout,
            })
            .then((data) => {
              resetRouter()
              // 清除本地缓存数据
              this.$util.deleteLocalStorage('apiprefix')
              this.$store.dispatch('clearSessionInfo').then(() => {
                bus.$emit('closeWs')
                this.$router.replace({ path: '/login' })

              })
            })
          })
        return
      }
      console.log('other command')
    },
    // 帮助操作
    helpCommand(command) {
      if (command === 'contactUs') {
        window.open(this.$path.contactUsUrl)
        return
      }
      console.log('other command')
    },
    // 折叠与展开
    updateIsFold() {
      this.$store.commit('updateIsFold')
    },
    // 页面全屏
    fullscreen() {
      screenfull.toggle()
    },
  },
}
