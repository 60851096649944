import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cmd: '', // 当前点击的权限按钮的方法名
    dialogTitle: '', // 弹框标题
    isFold: false, // 是否折叠左边菜单
    customCompanyInfo: null, // 私有化企业信息
  },
  mutations: {
    // 更新当前权限按钮方法
    updateCmd(state, method = '') {
      state.cmd = method
    },
    // 更新权限按钮弹框标题名称
    updateDialogTitle(state, title = '') {
      state.dialogTitle = title
    },
    // 更新折叠菜单状态
    updateIsFold(state) {
      state.isFold = !state.isFold
    },
    // 更新私有化企业信息
    updateCustomCompanyInfo(state, customCompanyInfo) {
      state.customCompanyInfo = customCompanyInfo
    },
  },
  actions: {
    // 退出登录时，清除本地缓存数据
    clearSessionInfo({ commit }) {
      return new Promise((resolve) => {
        // 操作员信息
        commit('operator/deleteToken')
        commit('operator/deleteOperator')

        commit('operator/deleteProductConfigEntities') // 删除计费模式
        // 权限
        commit('permission/deletePermission')
        // 页签
        commit('tags/updateActiveTag')
        commit('tags/updateTargetPermission')
        commit('tags/updateTags')

        resolve()
      })
    },
  },
  getters,
  modules,
})
