// 数据类型枚举
export default {
    BOOLEAN_FORM: 'Boolean',
    INTEGER_FORM: 'Integer',
    DOUBLE_FORM: 'Double',
    LONG_FORM: 'Long',
    NUMBER_FORM: 'Number',
    STRING_FORM: 'String',
    OBJECT_FORM: 'Object',
    ARRAY_FORM: 'Array',
}
