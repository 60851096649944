<template>
  <div class="inviteEquipment">
    <!-- 设备 -->
    <equipment-detail
      v-if="showEquipmentDetail"
      :tableRow="tableRow"
      :fontFamily="fontFamily"
    />
    <!-- 部件 -->
    <spare-part-detail
      v-if="showSparePartDetail"
      :tableRow="tableRow"
      :fontFamily="fontFamily"
    />
    <!-- 故障信息 -->
    <fault-detail
      v-if="showFaultDetail"
      :tableRow="tableRow"
      :fontFamily="fontFamily"
    />
  </div>
</template>

<script>
import EquipmentDetail from './equipmentDetail'
import SparePartDetail from './sparePartDetail'
import FaultDetail from './faultDetail'

export default {
  components: {
    EquipmentDetail,
    SparePartDetail,
    FaultDetail,
  },
  data() {
    return {
      tableRow: null,
      // 详情
      showEquipmentDetail: false,
      showSparePartDetail: false,
      showFaultDetail: false,
      // 字体
      fontFamily: 'Microsoft YaHei',
    }
  },
  mounted() {
    const query = this.$util.getParams(location.href)
    // 字体
    if (query.lang) this.fontFamily = query.lang
    this.getDetailByCode(query)
  },
  methods: {
    // 根据code查看设备信息
    getDetailByCode(query) {
      const { code, token } = query
      // 判断设备码是什么环境的、然后走不同的接口
      let baseUrl, url
      if (code.indexOf('custom') === 0) {
        baseUrl = this.$path.oemBaseURL
        url = '/tmva'  + this.$api.equipmentGetEquipmentByWebCode
      } else if (code.indexOf('publicCustom') === 0) {
        baseUrl = ''
        url = '/tmva' + this.$api.customEquipmentGetEquipmentByWebCode
      } else {
        baseUrl = ''
        url = '/tmva' + this.$api.equipmentGetEquipmentByWebCode
      }
      this.$ajax({
        baseUrl,
        url,
        headers: {
          subject_token: token,
        },
        data: {
          qrCode: code,
          type: 'WEB_DEVICE_INFO',
        },
      }).then((data) => {
        const { level, equipment, parts, fault } = data
        // 判断是设备，还是部件，还是故障
        if (level === 'ONE') {
          this.tableRow = equipment
          this.showEquipmentDetail = true
        } else if (level === 'TWO') {
          this.tableRow = parts
          this.showSparePartDetail = true
        } else {
          this.tableRow = fault
          this.showFaultDetail = true
        }
        // 国际化
        const { language } = query
        this.$nextTick(() => {
          if (language === 'Chinese') {
            this.$i18n.locale = 'zhCN'
            this.$util.setLocalStorage('lang', 'zhCN')
          } else if (language === 'Japanese') {
            this.$i18n.locale = 'ja'
            this.$util.setLocalStorage('lang', 'ja')
          } else {
            this.$i18n.locale = 'en'
            this.$util.setLocalStorage('lang', 'en')
          }
        })
      })
    },
  },
}
</script>
