// 客户管理
export default {
  title: 'Company management table',
  name: 'Company name',
  department: 'Department',
  director: 'Head',
  contactsInformation: 'Contact information',
  address: 'Contact address',
  addressDetail: 'Detailed address',
  enable: 'State',
  nameOrNotes: 'Name/owner/contact details',
}
