import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang/index.js'
import Element from 'element-ui'

// 公用组件导入
import BasePage from '@/components/basePage'
import BothColumPage from '@/components/bothColumPage'
import BaseDetail from '@/components/baseDetail'
import BaseDialog from '@/components/baseDialog'
import PreviewImage from '@/components/previewImage'
import FunctionButton from '@/components/functionButton'
import TinymceText from '@/components/tinymceText'
import BaseTree from '@/components/baseTree'
import DialogTree from '@/components/dialogTree'
import FormList from '@/components/formList'
import DialogFormList from '@/components/dialogFormList'
import TableList from '@/components/tableList'
import DialogTableList from '@/components/dialogTableList'
import UploadImage from '@/components/uploadImage'
import UploadFile from '@/components/uploadFile'
import JsonView from '@/components/jsonView'
import DetailList from '@/components/detailList'
import DialogImport from '@/components/dialogImport'
import DocumentUpload from '@/components/documentUpload'


// 样式
import './style/iconfont/iconfont.css'
import './style/element-variables.scss'
import './style/element.scss'
import './style/reset.scss'
import './style/common.scss'

// 工具类引入
import * as path from './path'
import * as api from './api'
import * as dict from './dict'
import * as util from './utils'
import * as element from './utils/element'
import * as page from './utils/page'
import validate from './utils/validate'
import ajax from './utils/ajax'
import echarts from './utils/echartsUI'
// 工具类挂载
Vue.prototype.$path = path
Vue.prototype.$api = api
Vue.prototype.$dict = dict
Vue.prototype.$util = util
Vue.prototype.$element = element
Vue.prototype.$page = page
Vue.prototype.$validate = validate
Vue.prototype.$ajax = ajax
Vue.prototype.$echarts = echarts

// 组件注册
Vue.use(Element, {
  size: 'small',
  i18n: (key, value) => i18n.t(key, value),
})

// 自定义组件注册
Vue.component('base-page', BasePage)
Vue.component('both-colum-page', BothColumPage)
Vue.component('base-detail', BaseDetail)
Vue.component('base-dialog', BaseDialog)
Vue.component('preview-image', PreviewImage)
Vue.component('function-button', FunctionButton)
Vue.component('tinymce-text', TinymceText)
Vue.component('base-tree', BaseTree)
Vue.component('dialog-tree', DialogTree)
Vue.component('form-list', FormList)
Vue.component('dialog-form-list', DialogFormList)
Vue.component('table-list', TableList)
Vue.component('dialog-table-list', DialogTableList)
Vue.component('upload-image', UploadImage)
Vue.component('upload-file', UploadFile)
Vue.component('json-view', JsonView)
Vue.component('detail-list', DetailList)
Vue.component('dialog-import', DialogImport)
Vue.component('document-upload', DocumentUpload)


Vue.config.productionTip = false
// 省市联动
import VueAreaLinkage from 'vue-area-linkage'
Vue.use(VueAreaLinkage)

export default new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
