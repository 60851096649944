<template>
  <div class="tableWrap">
    <!-- 表格 -->
    <el-table
      style="width: 100%"
      ref="tableList"
      :data="content"
      :row-key="rowKey"
      :highlight-current-row="highlightCurrentRow && !multiple"
      :tree-props="treeProps"
      :defaultExpandAll="defaultExpandAll"
      :expandRowKeys="expandRowKeys"
      @row-click="rowClick"
      @select="select"
      @select-all="selectAll"
      border
    >
      <el-table-column v-if="multiple" type="selection" align="center" />
      <el-table-column
        v-if="showSort"
        type="index"
        :label="$t('base.serialNumber')"
        :index="indexMethod"
        align="center"
        width="80px"
      />
      <el-table-column
        v-for="item in columns"
        :key="item[rowKey]"
        :label="item.label"
        :prop="item.prop"
        :align="item.align || 'left'"
        :width="item.width || 'auto'"
        :show-overflow-tooltip="showOverflowTooltip"
      >
        <template v-slot:default="{ row, $index }">
          <slot :name="item.prop" :row="row" :index="$index" :content="content">
            {{ item.callback ? item.callback(row, $index) : row[item.prop] }}
          </slot>
        </template>
        <template v-slot:header="{ column, $index }">
          <slot
            :name="`${item.prop}-header`"
            :column="column"
            :index="$index"
            :content="content"
          >
            {{
              item.labelCallback
                ? item.labelCallback(column, $index)
                : item.label
            }}
          </slot>
        </template>
      </el-table-column>
    </el-table>
    <div class="paginationWrap">
      <!-- 已选择 -->
      <div class="hasSelectRows" v-if="loaded && totalElements && showSelected">
        <span>{{ $t('base.hasSelect') }}</span>
        <el-select
          :placeholder="$t('base.noData')"
          size="mini"
          v-model="hasSelectValue"
        >
          <el-option
            v-for="item in hasSelectRows"
            :key="item[rowKey]"
            :label="getRowName(item)"
            :value="item[rowKey]"
          >
          </el-option>
        </el-select>
        <span>
          {{ $t('base.totalSelect') }}
          <b>{{ hasSelectRows.length }}</b>
          {{ $t('base.item') }}
        </span>
      </div>
      <!-- 分页 -->
      <el-pagination
        :style="`text-align: ${align}`"
        v-if="
          pagination &&
          loaded &&
          !isDefine &&
          totalElements &&
          totalElements > defaultSize
        "
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="page"
        :page-sizes="[
          defaultSize,
          2 * defaultSize,
          3 * defaultSize,
          4 * defaultSize,
        ]"
        :page-size="size"
        :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalElements"
      />
    </div>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>