export default {
  computed: {
    // 数据
    state() {
      return this.$store.state
    },
    // 当前激活菜单项
    activeTag() {
      const { activeTag } = this.state.tags
      return activeTag
    },
    // 当前已打开页签
    tags() {
      const { tags } = this.state.tags
      return tags
    },
  },
  methods: {
    // 选择页签
    tabClick(tag) {
      const { name } = tag
      if (name === this.activeTag) return
      if (name === 'INDEX') {
        this.$store.commit('tags/updateActiveTag', 'INDEX')
        this.$store.commit('tags/updateTargetPermission', null)
        this.$router.push({ path: '/main' })
        return
      }
      this.$store.dispatch('tags/addTags', { permissionEnum: name })
    },
    // 移除页签
    deleteTags(name) {
      this.$store.dispatch('tags/deleteTags', name)
    },
  },
}
