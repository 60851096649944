// 客户管理
export default {
  title: '客户管理表',
  name: '客户名称',
  department: '部门',
  director: '负责人',
  contactsInformation: '联系方式',
  address: '联系地址',
  addressDetail: '详细地址',
  enable: '状态',
  nameOrNotes: '名称/负责人/联系方式',
}
