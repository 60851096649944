<template>
  <div class="uploadFile">
    <el-upload
      ref="upload"
      action=""
      :accept="accept"
      :show-file-list="false"
      :http-request="httpRequest"
      :before-upload="beforeUpload"
      :on-error="onError"
      :disabled="disabled"
    >
      <el-button
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0.4)"
        class="uploadButton"
        icon="el-icon-plus"
        size="mini"
        :disabled="disabled"
      >
        {{ $t('base.uploadFile') }}
      </el-button> <p> {{previeName}}</p>
    </el-upload>
    <p class="fileType">
      {{ $t('base.fileTypeTitle') + accept + $t('base.fileTypeEnd') }}
    </p>
  </div>
</template>

<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>