// 功能管理
export default {
  title: '機能管理テーブル',
  moveUp: '上に移動',
  moveDown: '下に移動',
  permissionEnum: '名称列挙',
  permissionType: '機能タイプ',
  method: '方法名',
  apiSet: 'APIセット',
  icon: 'アイコン',
  url: 'ページパス',
  addButtonError: 'ボタン機能に下位レベルを追加できません',
  shouldBeBasic: '右上の権限ボタンを非表示にするかどうか',
}
