// 授权管理
export default {
  title: '传感器设备管理',
  name: '名称',
  deviceMode: '设备型号',
  measuringUnit: '测量单位',
  communicationMode: '通信方式',
  maxMeasuringRange: '最大量程',
  minMeasuringRange: '最小量程',
  tolerance: '公差',
  functionDes: '功能描述',
  imgFile: '图片',
}
