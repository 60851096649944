// AR数显管理
export default {
  title: 'AR数显管理表',
  name: 'AR数显码名称',
  code: 'url地址',
  notes: '备注',
  prefixHttp:"http协议",
  nameOrNotes: '名称/url地址/备注',
  arName: 'AR数显码',
  exportCode: '导出二维码',
}
