// 文档管理
export default {
  title: '文档管理',
  uploadTime: '上传时间',
  fileType:'文档类型',
  fileVersion: '文档版本号',
  status:'状态',
  fileUpload:'文档上传',
  deviceType: '客户端',
  softwareVersionId: '软件版本号',
}