// 部门管理
export default {
  title: 'Company user account table',
  title_platform: 'Platform user account table',
  groupTitle: 'Company department grouping',
  groupTitle_platform: 'Platform department grouping',
  addFirstGroup: 'Add',
  name: 'Department name',
  topName: 'Superior department',
  level: 'Affiliated level',
  accountGroup: 'The department to which it belongs',
}
