export default {
  name: 'dialogImport',
  props: {
    // 弹出框----
    title: {
      // 显示标题
      type: String,
      default: '',
    },
    width: {
      // 宽度
      type: String,
      default: '550px',
    },
    height: {
      // 高度
      type: String,
      default: 'auto',
    },
    // 上传----
    url: {
      // 导入地址
      type: String,
      default: '',
    },
    // 携带参数
    defaultParams: {
      // 导入地址
      type: Object,
      default: () => ({}),
    },
    accept: {
      // 可上传的文件类型
      type: String,
      default: '.xlsx',
    },
    size: {
      // 文件最大尺寸，单位KB，0表示不限制
      type: Number,
      default: 1024 * 5,
    },
    template: {
      // 模板地址
      type: String,
      default: '',
    },
    defineDeal: {
      // 是否需要自己处理
      type: Boolean,
      default: false,
    },
    defineDownDeal: {
      // 是否需要自己处理下载模板
      type: Boolean,
      default: false,
    },
    showTips: {
      // 是否显示提示
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      loading: false,
      file: null,
    }
  },
  methods: {
    // 上传前
    beforeUpload(file) {
      const size = +parseFloat(file.size / 1024).toFixed(2)
      if (this.size && size >= this.size) {
        this.$element.showMsg(`${this.$t('base.sizeLimit')}${size}KB，${this.$t('base.maxSize')}${this.size}KB！`, 'warning', 5000)
        return false
      }
    },
    // 文件选取成功
    httpRequest({ file }) {
      this.file = file
    },
    // 取消
    cancel() {
      this.$emit('cancel')
    },
    // 确认
    confirm() {
      if (!this.file) {
        this.$element.showMsg(this.$t('base.uploadFileTips'), 'warning')
        return
      }
      if (this.defineDeal) {
        this.$emit('confirm', false)
        return
      }
      this.import()
    },
    // 提交导入
    import() {
      this.loading = true
      const formData = new FormData()
      formData.append('file', this.file)
      for (let key in this.defaultParams) {
        formData.append(key, this.defaultParams[key])
      }
      const headers = {
        'Content-Type': 'multipart/form-data',
        subject_token: this.$util.getSessionStorage('token'),
      }
      this.$ajax({
        url: this.url,
        data: formData,
        headers,
        showLoading: false,
      })
        .then(() => {
          this.loading = false
          this.$element.showMsg(this.$t('base.importSuccess'), 'success')
          this.$emit('confirm', true)
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
