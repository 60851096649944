const TOKEN_TIME = 'tokentime'
const TONKEN_TIME_VALUE =   0.1 * 60 * 1000 // 30分钟

// 登录时间设置
export const setTokenTime = () => {
  localStorage.setItem(TOKEN_TIME, Date.now())
}

// 获取
export const getTokenTime = () => {
  return localStorage.getItem(TOKEN_TIME)
}

// 是否已过期
export const diffTokenTime = () => {
  const currerntTime = Date.now()
  const tokentime = getTokenTime()
  return currerntTime - tokentime > TONKEN_TIME_VALUE
}