export default {
  name: 'baseTree',
  props: {
    url: {
      // 请求地址
      type: String,
      default: '',
    },
    defaultParams: {
      // 请求参数
      type: Object,
      default: () => null,
    },
    value: {
      // 默认值，单选为对象，多选为数组，例如：{id: 1, name: '姓名'}或者[{id: 1, name: '姓名'}]
      type: Object | Array,
      default: () => null,
    },
    isDefine: {
      // 当前是否是自定义数据
      type: Boolean,
      default: false,
    },
    defaultContent: {
      // 自定义数据，如果有数据则默认认为为自定义数据，不需要发送请求
      type: Array,
      default: () => [],
    },
    nodeKey: {
      // 树节点用来作为唯一标识的属性
      type: String,
      default: 'id',
    },
    defaultProps: {
      // 显示配置选项
      type: Object,
      default: () => ({
        children: 'children',
        label: 'name',
      }),
    },
    multiple: {
      // 是否为多选树
      type: Boolean,
      default: false,
    },
    highlightCurrentRow: {
      // 是否高亮当前选中节点，如果multiple为true，此属性不起作用
      type: Boolean,
      default: true,
    },
    expandOnClickNode: {
      // 是否在点击节点的时候展开或者收缩节点
      type: Boolean,
      default: false,
    },
    defaultExpandAll: {
      // 是否默认展开所有节点
      type: Boolean,
      default: false,
    },
    defaultExpandedKeys: {
      // 默认展开的节点的 key 的数组
      type: Array,
      default: () => [],
    },
    headers: {
      // 请求头
      type: Object,
      default: () => ({}),
    },
    baseUrl: {
      // 定制化请求头
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      content: [],
      newDefaultExpandedKeys: this.defaultExpandedKeys,
    }
  },
  watch: {
    // 默认展开的节点的key的数组发生变化更新展开数组
    defaultExpandedKeys(val) {
      this.newDefaultExpandedKeys = val
    },
  },
  mounted() {
    // 自定义数据
    if (this.isDefine) {
      this.loaded = true
      this.content = this.defaultContent
      this.setDefaultValue()
      return
    }
    // 后台请求数据
    this.getList()
  },
  methods: {
    // 查询数据
    getList(searchParams = {}) {
      if (!this.url) return
      const params = {}
      // 默认参数
      if (this.defaultParams) Object.assign(params, this.defaultParams)
      // 查询参数
      for (const key in searchParams) {
        const item = searchParams[key]
        if (item && item !== 'ALL') params[key] = item
      }
      // 请求数据
      this.$ajax({
        baseUrl: this.baseUrl,
        url: this.url,
        data: params,
        headers: this.headers,
      })
        .then((data) => {
          this.loaded = true
          if (data instanceof Array) {
            this.content = data
          } else {
            if (!data) return
            this.content = [data]
          }
          this.setDefaultValue()
        })
        .catch(() => {
          this.loaded = true
        })
    },
    // 根据条件查询数据
    searchList(searchParams) {
      if (this.multiple) this.$emit('input', [])
      else this.$emit('input', null)
      this.getList(searchParams)
    },
    // 清除选中数据
    reset() {
      const baseTree = this.$refs.baseTree
      if (this.multiple) {
        baseTree.setCheckedKeys([])
        this.$emit('input', [])
      } else {
        baseTree.setCurrentKey()
        this.$emit('input', null)
      }
    },
    // 节点点击回调
    nodeClick(data) {
      if (this.multiple) return
      this.$emit('input', data)
      this.$emit('nodeClick', data)
    },
    // 当复选框被点击的时候触发
    check(_, { checkedNodes, halfCheckedNodes }) {
      if (!this.multiple) return
      const treeRows = [...checkedNodes, ...halfCheckedNodes]
      this.$emit('input', treeRows)
      this.$emit('check', treeRows)
    },
    // 设置默认值
    setDefaultValue() {
      if (this.multiple) {
        this.setMultipleDefault()
      } else {
        this.setSingleDefault()
      }
    },
    // 设置多选树默认值
    setMultipleDefault() {
      this.$nextTick(() => {
        const baseTree = this.$refs.baseTree
        // 清除选中数据
        if (!this.value.length) {
          baseTree.setCheckedKeys([])
          return
        }
        // 设置选中数据
        const selectedKeys = this.value.map((item) => `${item[this.nodeKey]}`)
        const checkedKeys = []
        const dealCheckedKeys = (data) => {
          if (!data || !data.length) return
          data.forEach((item) => {
            if (item.rgt - item.lft === 1 && selectedKeys.indexOf(`${item.id}`) >= 0) {
              checkedKeys.push(item.id)
            }
            if (item.children && item.children.length) dealCheckedKeys(item.children)
          })
        }
        dealCheckedKeys(this.content)
        baseTree.setCheckedKeys(checkedKeys)
      })
    },
    // 设置单选树默认值
    setSingleDefault() {
      this.$nextTick(() => {
        const baseTree = this.$refs.baseTree
        // 清除选中数据
        if (!this.value) {
          baseTree.setCurrentKey()
          return
        }
        // 设置选中数据
        baseTree.setCurrentKey(this.value[this.nodeKey])
      })
    },
  },
}
