// 设备激活码
export default {
  title: '设备激活码管理表',
  code: '设备码激活码',
  companyId: '关联企业',
  functionId: '关联功能',
  expirationDate: '有效期至',
  activationCode: '设备激活码二维码',
  num: '激活码数量',
  isActivated: '是否已激活使用',
  noCompanyId: '未关联企业',
}
