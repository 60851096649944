// 设备管理
export default {
  // 筛选
  nameOrNotes: 'デバイス名称/番号/型番',
  // 分组
  title: '会社デバイス情報テーブル',
  groupTitle: '会社デバイスグループ',
  title_platform: 'プラットフォームデバイス情報リスト',
  groupTitle_platform: 'プラットフォームデバイスグループ',
  customTitle: 'カスタムデバイス情報リスト',
  customGroupTitle: 'カスタムデバイスグループ',
  addFirstGroup: '追加',
  groupName: 'グループ名前',
  topName: '上級グループ',
  level: '所属レベル',
  enterTitle: 'タイトルを入力してください',
  enterContent: '内容を入力してください',
  // 表格
  name: '名称',
  number: '番号',
  model: '型番',
  specifications: 'スペック',
  userAccountGroup: '所属部署',
  selectGroup: 'デバイスグループデータを選択してください',
  addEquipment: 'デバイス追加',
  addPort: 'コンポーネント追加',
  addIssue: '故障情報追加',
  equipmentInfo: 'デバイス',
  partInfo: 'コンポーネント',
  faultInfo: '故障',
  scope: '可視範囲',
  type: 'タイプ',
  selectEquipment: 'デバイス選択',
  selectGroup_1: 'グループを選択してください',
  selectEquipmentTips: 'デバイスを選択してください',
  selectPartTips: 'コンポーネントを選択してください',
  selectDefautTips: '故障を選択してください',
  company: '所属会社',
  hasEquipment: '今のグループの下には既にデバイスがあり、新しいデバイスグループを作成できません',
  equipmentType: 'グループタイプ',
  equipmentCompnay: '会社デバイスグループ',
  equipmentPlatform: 'プラットフォームデバイスグループ',
  copyEqTips: 'デバイスグループの最後のレベルのみを選択できます',
  copyData: 'へコピー',
  purchaseTime: '購入日',
  // 设备
  equipmentName: 'デバイス名称',
  equipmentGroup: '所属グループ',
  equipmentUserAccountGroup: '所属部署',
  equipmentNumber: '出荷番号',
  equipmentNumber_1: 'デバイス番号',
  equipmentModel: 'デバイス型番',
  equipmentSpecifications: 'デバイススペック',
  equipmentDetail: 'デバイス紹介',
  equipmentImageSet: 'デバイス画像',
  equipmentFileSet: '関連添付ファイル',
  equipmentDetail_1: 'デバイス詳細',
  // 部件
  partName: 'コンポーネント名称',
  partEquipment: '所属デバイス',
  partNumber: '出荷番号',
  partNumber_1: 'コンポーネント番号',
  partModel: 'コンポーネント型番',
  partSpecifications: 'コンポーネントスペック',
  partDetail: 'コンポーネント紹介',
  partImageSet: 'コンポーネント写真',
  partFileSet: '関連添付ファイル',
  partDetail_1: 'コンポーネント詳細',
  // 故障信息
  faultName: '故障名称',
  faultNumber: '故障コード',
  faultPart: '所属コンポーネント',
  faultDetail: '故障紹介',
  faultSolution: '解決策',
  faultImageSet: '故障画像',
  faultFileSet: '関連添付ファイル',
  faultDetail_1: '故障情報',
  code: 'デバイスQRコード',
  companyEqSelf: '自分のデバイス',
  companyEq: '販売用デバイス',
  platformEq: 'プラットフォームデバイス',
}
