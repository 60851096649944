// 客户管理
export default {
  title: 'お客様管理テーブル',
  name: 'お客様名',
  department: '部署',
  director: '担当者',
  contactsInformation: '電話番号',
  address: '住所',
  addressDetail: '住所詳細',
  enable: '状態',
  nameOrNotes: '名称/担当者/電話番号',
}
