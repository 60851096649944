import { singleUpload } from '@/api'

export default {
  name: 'uploadImage',
  props: {
    action: {
      // 上传地址
      type: String,
      default: singleUpload,
    },
    value: {
      // 绑定和提交内容
      type: Array,
      default: () => [],
    },
    accept: {
      // 可上传的文件类型
      type: String,
      default: 'image/*',
    },
    size: {
      // 文件最大尺寸，单位KB，0表示不限制
      type: Number,
      default: 0,
    },
    limit: {
      // 最大允许上传个数，0表示不限制
      type: Number,
      default: 1,
    },
    width: {
      // 宽度
      type: String,
      default: '80px',
    },
    height: {
      // 高度
      type: String,
      default: '80px',
    },
    disabled: {
      // 是否禁用
      type: Boolean,
      default: false,
    },
    baseUrl: {
      // 定制化请求头
      type: String,
      default: '',
    },
    showImage:{
      // 显示图片
      type: Boolean,
      default: false,
    },
    sendImg: {
      // 定制化请求头
      type: String,
      default: '',
    },
    formReg: {
      // 定制化请求头
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      showPreview: false,
      previewUrl: '',
      showImageUrl:''
    }
  },
  methods: {
    // 上传前
    beforeUpload(file) {
      // 判断最大格式
      if (this.limit && this.value.length >= this.limit) {
        this.$element.showMsg(`${this.$t('base.countLimit')}${this.limit}${this.$t('base.count')}`, 'warning')
        return false
      }
      const size = +parseFloat(file.size / 1024).toFixed(2)
      if (this.size && size >= this.size) {
        this.$element.showMsg(`${this.$t('base.sizeLimit')}${size}KB，${this.$t('base.maxSize')}${this.size}KB！`, 'warning', 5000)
        return false
      }
      this.loading = true
    },
    // 处理请求错误信息
    showErrorMsg(msg) {
      this.$element.showAlert({
        content: msg,
      })
    },
    // 上传成功
    httpRequest({ file }) {
      const size = +parseFloat(file.size / 1024 / 1024).toFixed(2)
      const baseUrl = this.baseUrl
      if (size >= 0 && !baseUrl) {
        this.getWebUploadSignature(file)
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      const headers = {
        'Content-Type': 'multipart/form-data',
        subject_token: this.$util.getSessionStorage('token'),
      }
      this.$ajax({
        baseUrl,
        url: this.action,
        data: formData,
        headers,
        showLoading: false,
      })
        .then((data) => {
          console.log(data)
          this.loading = false
          const imageList = this.$util.copyData(this.value)
          this.showImageUrl = data.fileHost + '/' + data.objectName
          imageList.push(data)
          this.$emit('input', imageList)
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 获取阿里云上传文件签名
    getWebUploadSignature(file) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.getWebUploadSignature,
        data: {
          originalName: file.name,
          fileSize: file.size,
        },
      })
        .then((data) => {
          this.uploadAliyunOss(file, data)
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 获取文件后缀
    get_suffix(filename) {
      const pos = filename.lastIndexOf('.')
      let suffix = ''
      if (pos != -1) {
        suffix = filename.substring(pos)
      }
      return suffix
    },
    // 阿里云直传
    uploadAliyunOss(file, params) {
      const formData = new FormData()
      formData.append('key', params.dir + params.fileName)
      formData.append('policy', params.policy)
      formData.append('OSSAccessKeyId', params.accessid)
      formData.append('success_action_status', '200')
      formData.append('callback', params.callback)
      formData.append('signature', params.signature)
      formData.append('file', file)
      this.$ajax({
        url: params.host,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        showLoading: false,
      })
        .then((data) => {
          console.log(data)
          this.loading = false
          const imageList = this.$util.copyData(this.value)
          this.showImageUrl = data.fileHost + '/' + data.objectName
          imageList.push(data)
          this.$emit('input', imageList)
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 上传失败
    onError() {
      this.loading = false
      this.$element.showMsg(this.$t('base.uploadError'), 'warning')
    },
    // 删除
    deleteMethod(index) {
      const imageList = this.$util.copyData(this.value)
      imageList.splice(index, 1)
      this.$emit('input', imageList)
    },
    // 预览
    previewMethod(data) {
      this.previewUrl = data.fileHost + '/' + data.objectName
      this.showPreview = true
    },
    // 关闭预览
    closePreview() {
      this.showPreview = false
    },
  },
}
