// 企业管理
export default {
  title: 'Company management table',
  customTitle: 'Private service enterprise configuration table',
  name: 'Company name',
  feeMode: 'Billing mode', // 当前计费模式
  feeModeStr: 'Available billing mode', // 当前可用计费模式
  industry: 'Subordinate to the industry',
  customIndustry: 'Other industries',
  businessScope: 'Company description',
  address: 'Contact address',
  addressDetail: 'Detailed address',
  contacts: 'Contact',
  contactsInformation: 'Contact',
  email: 'Contact email',
  invoice: 'Invoice information',
  setInvoiceSuccess: 'Invoice information set successfully',
  invoiceType: 'Invoice type',
  companyNameForInvoice: 'Invoicing enterprise name',
  creditCodeForInvoice: 'Unify social credit code',
  openAccountBankNameForInvoice: 'Name of the opening bank',
  openAccountBankAccountForInvoice: 'Account of the opening bank',
  openAccountTelNumForInvoice: 'Phone of the opening bank',
  openAccountBankAddressAddressForInvoice: 'Address of the opening bank',
  openAccountBankAddressDetailForInvoice: 'Detailed address',
  nameOrNotes: 'Name/contact/contact/mailbox',
  excelImport: 'The import business',
  remainAmount: 'Number remaining',
  remainAmountSee: 'Balance view',
  feeModeChangeTips: 'If the billing model is modified, the modification enterprise and the sub-enterprise will be updated simultaneously and empty the margin, determine whether to replace the billing model?',
  canAddSubCompany: 'Whether you can create a sub-enterprise',
  // 私有化企业
  syNameOrNotes: 'An enterprise name',
  host: 'Access address',
  code: 'Enterprise Numbers',
  company: 'Private enterprise',
  companyPlaceholder: 'Please enter the company name',
  welcomeWord: 'Welcome',
  contactWord: 'Contact us for text',
  contactLink: 'Contact us for links',
  copyright: 'Copyright information',
}
