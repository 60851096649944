<template>
<div class="page">
  <div class="pageBox">
    <el-row class="pageTop">
      <el-col :span="2">
        <el-select
            v-model="lang"
            placeholder="请选择"
            size="mini"
            style="width: 100px"
            @change="changeLang"
          >
          <el-option
            v-for="item in langData"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="1" :offset="3">
        <div class="back" @click="getBack">{{$t('reg.goback')}}</div>
      </el-col>
    </el-row>
    <el-form ref="dataForm" :model="ruleForm" :rules="rules" label-width="140px" >
      <el-row style="padding-top: 30px">
        <el-col :span="10" :offset="7">
          <el-form-item :label="$t('reg.loginName')"
            prop="loginName"
            :rules="
              $validate({
                noNull: true,
                label: $t('reg.loginName'),
                tips: $t('base.pleaseEnter'),
              })"
          >
            <el-input v-model="ruleForm.loginName" size="mini" :placeholder="$t('base.pleaseEnter') + $t('reg.loginName')" maxlength="32"></el-input>
          </el-form-item>
          <el-form-item :label="$t('reg.sms')" prop="sms">
              <el-select
                :placeholder="$t('reg.sms')"
                style="display:block;"
                v-model="ruleForm.sms"
                @change="changeType"
                size="mini"
              >
                <template v-for="(item, index) in contactType">
                  <el-option
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </template>
              </el-select>
          </el-form-item>
          <template v-if="isShowType === 1">
            <el-form-item :label="$t('reg.mobile')" prop="mobile"
              :rules="
                  $validate({
                    noNull: true,
                    label: $t('reg.mobile'),
                    tips: $t('base.pleaseEnter'),
                  })">
                <el-input v-model="ruleForm.mobile" size="mini" maxlength="11" :placeholder="$t('base.pleaseEnter') + $t('reg.mobile')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.code')" prop="code"
              :rules="
                  $validate({
                    noNull: true,
                    label: $t('reg.popcode'),
                    tips: $t('base.pleaseEnter'),
                  })">
                <el-input v-model="ruleForm.code" maxlength="6"  size="mini" :placeholder="$t('base.pleaseEnter') + $t('reg.popcode')" style="width:160px;"></el-input>
                <el-button type="plain" class="getCode" @click.stop="sendcode" v-if="codeShow" style="margin-left: 5px;" size="mini">{{ $t('reg.getCode') }}</el-button>
                <el-button type="plain" class="getCode" disabled v-if="!codeShow" style="margin-left: 5px;" size="mini">{{ $t('reg.getCode') }}{{count}}s</el-button>
            </el-form-item>
          </template>
          <template v-if="isShowType === 2">
            <el-form-item :label="$t('reg.popemail')" prop="email"
              :rules="
                  $validate({
                    noNull: true,
                    label: $t('reg.email'),
                    tips: $t('base.pleaseEnter'),
                  })">
                <el-input v-model="ruleForm.email" size="mini" :placeholder="$t('base.pleaseEnter') + $t('reg.email')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('reg.emailCode')" prop="emailCheckCode"
              :rules="
                  $validate({
                    noNull: true,
                    label: $t('reg.popcode'),
                    tips: $t('base.pleaseEnter'),
                  })">
                <el-input v-model="ruleForm.code" size="mini" maxlength="6" :placeholder="$t('base.pleaseEnter') + $t('reg.popcode')" style="width:160px;"></el-input>
                <el-button type="plain" size="mini" class="getCode" style="margin-left: 5px;">{{ $t('reg.getCode') }}</el-button>
            </el-form-item>
          </template>
          <el-form-item :label="$t('reg.newPass')" prop="password"
            :rules="
                $validate({
                  noNull: true,
                  type: 'password',
                  label: $t('reg.newPass'),
                  tips: $t('base.pleaseEnter'),
                })">
              <el-input v-model="ruleForm.password" size="mini" :type="eye1Show ? 'text' : 'password'" :placeholder="$t('base.pleaseEnter') + $t('reg.newPass')">
                <div slot="suffix" class="eye" autocomplete="auto" @click="triggerEye(1)">
                  <template v-if="eye1Show">
                    <img class="eye-image" src="./img/passShow.png" />
                  </template>
                  <template v-else>
                    <img class="eye-image" src="./img/passHide.png" />
                  </template>
                </div>
              </el-input>
          </el-form-item>
          <el-form-item :label="$t('reg.confirmpass')" prop="confirmpass">
              <el-input v-model="ruleForm.confirmpass" size="mini" :type="eye2Show ? 'text' : 'password'" :placeholder="$t('base.pleaseEnter') + $t('reg.confirmpass')">
                <div slot="suffix" class="eye" autocomplete="auto" @click="triggerEye(2)">
                  <template v-if="eye2Show">
                    <img class="eye-image" src="./img/passShow.png" />
                  </template>
                  <template v-else>
                    <img class="eye-image" src="./img/passHide.png" />
                  </template>
                </div>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10" :offset="8" class="footBtn">
          <el-button type="primary" @click="forgotConfirm" size="mini" class="downButtom">{{ $t('reg.referto') }}</el-button>
          <el-button type="plain" @click="forgotCancel"  size="mini" class="cancelBut">{{ $t('reg.cancel') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog
      :title="$t('reg.mobileBind')"
      ref="baseDialog"
      :visible.sync="bindMobileShow"
      v-if="bindMobileShow"
      class="passBox"
      width="476px"
      :modal="false"
      :close-on-click-modal="false"
      :modal-append-to-body='false'
    >
    <el-form ref="bindForm" :model="bindForm"  label-width="110px" >
      <el-row>
        <el-col :span="23">
          <el-form-item :label="$t('reg.popcode')" prop="code"
            :rules="
                $validate({
                  noNull: true,
                  label: $t('reg.popcode'),
                  tips: $t('reg.contactadministrator'),
                })">
              <el-input v-model="bindForm.code" :placeholder="$t('base.pleaseEnter') + $t('reg.popcode')" maxlength="6">
                  <el-button slot="suffix" size="mini"  v-if="codeShow" @click.stop="sendBindCode" class="suspensionBtn">{{$t('adminAccount.getCode')}}<span id="count"></span>
                 </el-button>
                <el-button slot="suffix" size="mini" disabled v-if="!codeShow" @click.stop="sendBindCode" class="suspensionBtn">{{$t('adminAccount.sendCode')}}{{count}}s</el-button>
              </el-input>
          </el-form-item>
          <el-form-item :label="$t('reg.bindingmobile')" prop="mobile"
            :rules="
                $validate({
                  noNull: true,
                  label: $t('reg.mobile'),
                  type: 'mobile',
                  tips: $t('base.pleaseEnter'),
                })">
              <el-input v-model="bindForm.mobile" maxlength="11"  :placeholder="$t('base.pleaseEnter') + $t('reg.mobile')"></el-input>
          </el-form-item>
          </el-col>
      </el-row>
      <el-row>
        <el-col :span="18" :offset="3" class="footBtn">
            <el-button @click="cancel" class="cancelPop">{{ $t('base.cancel') }}</el-button>
            <el-button type="primary" @click="confirmBind" class="confirmPop">
              {{ $t('base.confirm') }}
            </el-button>
          </el-col>
      </el-row>

    </el-form>
    </el-dialog>
  </div>
</div>

</template>
<script>
import Index from './_index.js'
export default Index
</script>

<style lang="scss">
@import './_index.scss';
</style>
