<template>
  <div class="baseDetail">
    <el-container style="height: 100%">
      <el-header
        height="auto"
        class="tableTitle clearfix"
        style="min-height: 60px"
      >
        <h2 class="tableH2">
          {{ $t(`functionEnum.${$store.getters.permissionEnum}`) }} /
          {{ $t('base.view') }}
        </h2>
        <div class="functionButton">
          <slot name="buttons" />
          <el-button size="mini" @click="closePage">
            <i class="el-icon-back" /> {{ $t('base.back') }}
          </el-button>
        </div>
      </el-header>
      <el-main class="detailContent scollBar">
        <slot />
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'baseDetail',
  methods: {
    // 返回按钮点击回调
    closePage() {
      this.$emit('closePage')
    },
  },
}
</script>

<style lang="scss">
@import '@/style/theme.scss';
.baseDetail {
  position: absolute;
  left: 15px;
  top: 15px;
  right: 15px;
  bottom: 15px;
  z-index: 1;

  .tableH2 {
    font-size: 15px;
    font-weight: bold;
    color: $--color-primary;
    line-height: 1;
    border-left: 4px solid $--color-primary;
    padding-left: 10px;
  }

  .tableTitle {
    background: #fff;
    position: relative;
    z-index: 1;

    h2 {
      margin: 22px 15px 0;
      display: inline-block;
    }

    .functionButton {
      float: right;
      padding-top: 15px;
      padding-left: 15px;
    }

    .el-button {
      margin-bottom: 10px;
      margin-left: 0;
      margin-right: 15px;
    }
  }

  .detailContent {
    background-color: #fff;
    border-radius: 6px;
    padding: 0 15px 15px;
    position: relative;
  }
}
</style>