// 基础工具类
/**
 * 设置Session Storage值
 * @param {string} key 键
 * @param {any} value 值
 */
export const setSessionStorage = (key, value) => {
  value = typeof value !== 'object' ? value : JSON.stringify(value)
  window.sessionStorage.setItem(key, value)
}

/**
 * 获取Session Storage值
 * @param {string} key 键
 */
export const getSessionStorage = (key) => {
  const obj = window.sessionStorage.getItem(key)
  // console.log('obj', obj)
  return obj && (obj.startsWith('{') || obj.startsWith('[')) ? JSON.parse(obj) : obj
}

/**
 * 删除Session Storage值
 * @param {string|undefined} key 键
 */
export const deleteSessionStorage = (key) => {
  key ? window.sessionStorage.removeItem(key) : window.sessionStorage.clear()
}

/**
 * 设置local Storage值
 * @param {string} key 键
 * @param {any} value 值
 */
export const setLocalStorage = (key, value) => {
  value = typeof value !== 'object' ? value : JSON.stringify(value)
  window.localStorage.setItem(key, value)
}

/**
 * 获取local Storage值
 * @param {string} key 键
 */
export const getLocalStorage = (key) => {
  const obj = window.localStorage.getItem(key)
  return obj && (obj.startsWith('{') || obj.startsWith('[')) ? JSON.parse(obj) : obj
}

/**
 * 删除local Storage值
 * @param {string|undefined} key 键
 */
export const deleteLocalStorage = (key) => {
  key ? window.localStorage.removeItem(key) : window.localStorage.clear()
}

/**
 * 深拷贝数据
 * @param {any} data 需要拷贝的数据
 * */
export const copyData = (data) => {
  return JSON.parse(JSON.stringify(data))
}

/**
 * 将字典转换成对象
 * @param {array} list 需要拷贝的数据
 * */
export const listToObj = (list) => {
  return list.reduce((result, item) => {
    result[item.value] = item.label
    return result
  }, {})
}

/**
 * 防抖函数
 * @param {function} callback 回调函数
 * @param {number} wait 延迟时间
 * */
let debounceTimer
export const debounce = (callback, wait = 100) => {
  if (debounceTimer) {
    clearTimeout(debounceTimer)
    debounceTimer = null
  }
  debounceTimer = setTimeout(callback, wait)
}

/**
 * 格式化日期
 * @param {string|Date} value 格式化前的时间
 * @param {string} style  格式化后的时间格式 yyyy-MM-dd hh:mm:ss
 * */
export const format = (value, style) => {
  if (!style || !(style = style.toString().trim())) style = 'yyyy-MM-dd hh:mm:ss'
  const time = new Date(value)
  const o = {
    'M+': time.getMonth() + 1, // month
    'd+': time.getDate(), // day
    'h+': time.getHours(), // hour
    'm+': time.getMinutes(), // minute
    's+': time.getSeconds(), // second
    S: time.getMilliseconds(), // millisecond
    'q+': Math.floor((time.getMonth() + 3) / 3), // quarter
  }
  if (/(y+)/.test(style)) {
    style = style.replace(
      RegExp.$1,
      time
        .getFullYear()
        .toString()
        .substr(4 - RegExp.$1.length)
    )
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(style)) {
      style = style.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(o[k].toString().length))
    }
  }
  return style
}

/**
 * 隐藏手机号中间4位
 * @param {string|number} mobile 手机号
 * */
export const hideMobile = (mobile) => {
  if (!Number(mobile) || String(mobile).length !== 11) return mobile
  mobile = String(mobile)
  return mobile.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
}

/**
 * 格式化数字 10000 => 10,000
 * @param {number} num 数字
 */
export const toThousandFilter = (num) => {
  return (+num || 0).toString().replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * 将分转化为万元
 * @param {number} num 数字
 * */
export const fToWFilter = (num) => {
  return +parseFloat((+num || 0) / 100 / 10000).toFixed(4)
}

/**
 * 将分转化为元
 * @param {number} num 数字
 * */
export const fToYFilter = (num) => {
  return +parseFloat((+num || 0) / 100).toFixed(2)
}

/**
 * 首字母大写
 * @param {string} str 字符串
 */
export const uppercaseFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * 格式化数字 like 10000 => 10k
 * @param {number} num 数字
 * @param {number} digits 精度(保留几位小数)
 */
export const numberFormatter = (num, digits) => {
  const si = [
    {
      value: 1e18,
      symbol: 'E',
    },
    {
      value: 1e15,
      symbol: 'P',
    },
    {
      value: 1e12,
      symbol: 'T',
    },
    {
      value: 1e9,
      symbol: 'G',
    },
    {
      value: 1e6,
      symbol: 'M',
    },
    {
      value: 1e3,
      symbol: 'k',
    },
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * 设置默认值
 * @param {Object} formData 表单绑定对象
 * @param {Object} tableRow 数据源
 */
export const setDefaultValue = (formData, tableRow = {}) => {
  for (const key in formData) {
    const value = tableRow[key]
    formData[key] = typeof value === 'number' ? value : value || ''
  }
}

/**
 * 获取树层次的展示值
 * @param {Object} data 数据源
 * @param {String} name 展示的字段
 */
export const getTreeNames = (data, key = 'name') => {
  if (!data) return ''
  if (!data.parent) return data[key]
  const parent = data.parent || []
  if (!parent.length) return data[key]
  const names = parent.map((item) => item[key])
  names.push(data[key])
  return names.join('/')
}

/**
 * 获取树层次的唯一标识
 * @param {Object} data 数据源
 * @param {String} id 数据唯一值
 */
export const getTreeIds = (data, key = 'id') => {
  if (!data) return []
  if (!data.parent) return [data[key]]
  const parent = data.parent || []
  if (!parent.length) return [data[key]]
  const ids = parent.map((item) => item[key])
  ids.push(data[key])
  return ids
}

/**
 * 解析url参数
 * @param {String} url 地址
 */
export const getParams = (url) => {
  let params = {},
    h
  let hash = url.slice(url.indexOf('?') + 1).split('%7C')
  for (let i = 0; i < hash.length; i++) {
    h = hash[i].split('%3D')
    params[h[0]] = h[1]
  }
  return params
}
// 数字千位分法
export const commafy  = (num) => {
  return num.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
}
// checkbox至少有一个被选中
export const checkboxs = (objNam) => {
  var obj = document.getElementsByName(objNam)// 获取对象
  var objLen= obj.length// 获取数组长度
  var objYN // bool
  var i
  objYN = false
  for (i = 0; i< objLen; i++) {
    if (obj[i].checked==true) {
      objYN= true
      break
    }
  }
    return objYN
}
// 对象转数组
export const fmObjToArr = (data) => {
  let arr = []
  for(let key in data) {
    arr.push({
      value: data[key],
      id: key
    })
  }
  return arr
}
// 排序1,2,3
export const sortKey = (array,key) =>{
  return array.sort(function(a,b){
      var x = a[key]
      var y = b[key]
      return ((x<y)?-1:(x>y)?1:0)
  })
}
// 7天间隔
export const getDateDays = () => {
  let base = new Date().getTime()
  const base1 = new Date().getTime()
  const oneDay = 24 * 3600 * 1000*6;
  const reStartDay = 24 * 3600 * 1000*7;
  const date = []
  const data = [Math.random() * 300]
  const time = new Date(base)
  date.push([time.getFullYear(), time.getMonth() + 1, time.getDate()].join('-'))
  const now = new Date(base -= oneDay)
  const reNow = new Date(base1 -reStartDay)
  const redate = [reNow.getFullYear(), reNow.getMonth() + 1, reNow.getDate()].join('-')
  date.push([now.getFullYear(), now.getMonth() + 1, now.getDate()].join('-'))
   //date.push([now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'));
   /*for (var i = 1; i <7; i++) {
       var now = new Date(base -= oneDay);
       date.push([now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'));
       console.log(date)
       data.push(Math.round((Math.random() - 0.5) * 20 + data[i - 1]));
   }*/
   var newdate = date.reverse()
    return {newdate}
}

