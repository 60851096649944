// 功能枚举
export default {
  // 功能管理
  ALL_FUNCTION: '全部功能',
  // 系统管理
  SYSTEM_MANAGE: '系统管理',
  FUNCTION_MANAGE: '功能管理',
  FUNCTION_MANAGE_ADD: '新增',
  FUNCTION_MANAGE_EDIT: '修改',
  FUNCTION_MANAGE_DELETE: '删除',
  FUNCTION_MANAGE_VIEW: '详情',
  CUSTOM_FUNCTION_MANAGE: '私有化功能管理',
  CUSTOM_FUNCTION_MANAGE_ADD: '新增',
  CUSTOM_FUNCTION_MANAGE_EDIT: '修改',
  CUSTOM_FUNCTION_MANAGE_DELETE: '删除',
  CUSTOM_FUNCTION_MANAGE_VIEW: '详情',
  // 角色管理
  ROLE_MANAGE: '角色管理',
  ROLE_MANAGE_ADD: '新增',
  ROLE_MANAGE_EDIT: '修改',
  ROLE_MANAGE_DELETE: '删除',
  ROLE_MANAGE_VIEW: '详情',
  ROLE_MANAGE_GRANT_PERMISSION: '授权',
  // 账号管理
  ACCOUNT_MANAGE: '账号管理',
  // 管理员账号
  OPERATOR_MANAGE: '管理员账号',
  OPERATOR_MANAGE_ADD: '新增',
  OPERATOR_MANAGE_EDIT: '修改',
  OPERATOR_MANAGE_DELETE: '删除',
  OPERATOR_MANAGE_VIEW: '详情',
  OPERATOR_MANAGE_DISABLE: '禁用',
  OPERATOR_MANAGE_RECOVER: '启用',
  OPERATOR_MANAGE_RESET_PASSWORD: '重置密码',
  OPERATOR_MANAGE_GRANT_ROLE: '分配角色',
  // 企业用户账号
  USER_ACCOUNT_MANAGE: '企业用户账号',
  USER_ACCOUNT_MANAGE_ADD: '新增',
  USER_ACCOUNT_MANAGE_EDIT: '修改',
  USER_ACCOUNT_MANAGE_DELETE: '删除',
  USER_ACCOUNT_MANAGE_VIEW: '详情',
  USER_ACCOUNT_MANAGE_DISABLE: '禁用',
  USER_ACCOUNT_MANAGE_RECOVER: '启用',
  USER_ACCOUNT_MANAGE_RESET_PASSWORD: '重置密码',
  USER_ACCOUNT_MANAGE_EXPORT_QR_CODE: '批量导出二维码',
  USER_ACCOUNT_MANAGE_TRAJECTORIES: '轨迹图',
  USER_ACCOUNT_MANAGE_RENEW: '续订',
  USER_ACCOUNT_IMPORT_FROM_EXCEL: '导入用户账号',
  // 平台用户账号
  SCOPE_USER_ACCOUNT_MANAGE: '平台用户账号',
  SCOPE_USER_ACCOUNT_MANAGE_ADD: '新增',
  SCOPE_USER_ACCOUNT_MANAGE_EDIT: '修改',
  SCOPE_USER_ACCOUNT_MANAGE_DELETE: '删除',
  SCOPE_USER_ACCOUNT_MANAGE_VIEW: '详情',
  SCOPE_USER_ACCOUNT_MANAGE_DISABLE: '禁用',
  SCOPE_USER_ACCOUNT_MANAGE_RECOVER: '启用',
  SCOPE_USER_ACCOUNT_MANAGE_RESET_PASSWORD: '重置密码',
  SCOPE_USER_ACCOUNT_MANAGE_EXPORT_QR_CODE: '批量导出二维码',
  SCOPE_USER_ACCOUNT_MANAGE_TRAJECTORIES: '轨迹图',
  SCOPE_USER_ACCOUNT_MANAGE_RENEW: '续订',
  SCOPE_USER_ACCOUNT_IMPORT_FROM_EXCEL: '导入用户账号',
  // 字典管理
  DICT_MANAGE: '字典管理',
  DICT_MANAGE_ADD: '新增',
  DICT_MANAGE_EDIT: '修改',
  DICT_MANAGE_DELETE: '删除',
  DICT_MANAGE_VIEW: '详情',
  // 企业管理
  COMPANY_MANAGE: '企业管理',
  COMPANY_MANAGE_ADD: '新增',
  COMPANY_MANAGE_EDIT: '修改',
  COMPANY_MANAGE_DELETE: '删除',
  COMPANY_MANAGE_VIEW: '详情',
  COMPANY_IMPORT_FROM_EXCEL: '导入企业',
  COMPANY_INVOICE: '发票信息',
  COMPANY_BALANCE: '余额查看',
  COMPANY_CONFIG_MANAGE: '私有服务企业配置',
  COMPANY_CONFIG_MANAGE_ADD: '新增',
  COMPANY_CONFIG_MANAGE_EDIT: '修改',
  COMPANY_CONFIG_MANAGE_DELETE: '删除',
  COMPANY_CONFIG_MANAGE_VIEW: '详情',
  COMPANY_GRANT_PERMISSION: '功能授权',
  COMPANY_QRCODE_BATCH_EXPORT: '导出企业码',
  // 日志管理
  LOG_MANAGE: '日志管理',
  CONTEXT_LOG_MANAGE: '会话日志',
  CONTEXT_LOG_MANAGE_VIEW: '详情',
  CLIENT_LOG_MANAGE: '客户端日志',
  CLIENT_LOG_DOWNLOAD: '下载',
  CLIENT_LOG_MANAGE_DOWNLOAD: '下载',
  // 财务管理
  FINANCE_MANAGE: '财务管理',
  ASSIGN_TO_ME_MANAGE: '分配账单',
  ASSIGN_TO_ME_MANAGE_VIEW: '详情',
  FINANCE_KEY_AUTHORIZATION_RECORD: '密钥账单查询',
  // 授权分配
  ASSIGN_TO_OTHER_MANAGE: '资源分配',
  ASSIGN_TO_OTHER_MANAGE_ADD: '新增授权',
  ASSIGN_TO_OTHER_MANAGE_ROLLBACK: '撤回',
  ASSIGN_TO_OTHER_MANAGE_VIEW: '	详情',
  // 账单查询
  FINANCIAL_FLOW_RECORD: '资源账单查询',
  // 设备管理
  EQUIPMENT: '设备管理',
  EQUIPMENT_MANAGE: '销售设备信息',
  EQUIPMENT_GROUP_MANAGE_ADD: '新增',
  EQUIPMENT_GROUP_MANAGE_EDIT: '修改',
  EQUIPMENT_GROUP_MANAGE_DELETE: '删除',
  EQUIPMENT_MANAGE_ADD: '新增设备',
  EQUIPMENT_MANAGE_EDIT: '修改',
  EQUIPMENT_MANAGE_DELETE: '删除',
  EQUIPMENT_MANAGE_VIEW: '详情',
  SCOPE_EQUIPMENT_MANAGE: '平台设备信息',
  SCOPE_EQUIPMENT_GROUP_MANAGE_ADD: '新增',
  SCOPE_EQUIPMENT_GROUP_MANAGE_EDIT: '修改',
  SCOPE_EQUIPMENT_GROUP_MANAGE_DELETE: '删除',
  SCOPE_EQUIPMENT_MANAGE_ADD: '新增设备',
  SCOPE_EQUIPMENT_MANAGE_EDIT: '修改',
  SCOPE_EQUIPMENT_MANAGE_DELETE: '删除',
  SCOPE_EQUIPMENT_MANAGE_VIEW: '详情',
  EQUIPMENT_MANAGE_COPY: '复制到',
  EQUIPMENT_MANAGE_MOVE: '移动到',
  // 自用设备信息
  CUSTOM_EQUIPMENT: '自用设备信息',
  CUSTOM_EQUIPMENT_GROUP_MANAGE_ADD: '新增',
  CUSTOM_EQUIPMENT_GROUP_MANAGE_EDIT: '修改',
  CUSTOM_EQUIPMENT_GROUP_MANAGE_DELETE: '删除',
  CUSTOM_EQUIPMENT_MANAGE_ADD: '新增设备',
  CUSTOM_EQUIPMENT_MANAGE_EDIT: '修改',
  CUSTOM_EQUIPMENT_MANAGE_DELETE: '删除',
  CUSTOM_EQUIPMENT_MANAGE_VIEW: '详情',
  CUSTOM_EQUIPMENT_MANAGE_COPY: '复制到',
  CUSTOM_EQUIPMENT_MANAGE_MOVE: '移动到',
  CUSTOM_EQUIPMENT_MANAGE_EXPORT: '导出二维码',
  // 地址池管理
  POOL: '地址池管理',
  EQUIPMENT_POOL_CODE: '设备码',
  EQUIPMENT_POOL_CODE_ADD: '新增',
  EQUIPMENT_POOL_CODE_LIST: '查询',
  EQUIPMENT_POOL_CODE_DELETE: '删除',
  GATEWAY_POOL: '网关地址池',
  GATEWAY_POOL_ADD: '新增',
  GATEWAY_POOL_UPDATE: '修改',
  GATEWAY_POOL_DELETE: '删除',
  GATEWAY_POOL_VIEW: '详情',
  AR_GATEWAY_IMPORT: '导入网关地址',
  AR_POOL: 'AR设备地址池',
  AR_POOL_ADD: '新增',
  AR_POOL_UPDATE: '修改',
  AR_POOL_DELETE: '删除',
  AR_POOL_VIEW: '详情',
  AR_POOL_IMPORT: '导入AR设备地址',
  // 销售信息
  SALE: '销售信息',
  SALE_ADD: '新增',
  SALE_UPDATE: '修改',
  SALE_DELETE: '删除',
  SALE_VIEW: '详情',
  SALE_EXPORT: '批量导出二维码',
  SALE_LIST_DELETE: '删除记录',
  // 销售详情
  SALE_EQUIPMENT_LIST: '销售详情列表',
  // 客户管理
  CUSTOMER: '客户管理',
  CUSTOMER_ADD: '新增',
  CUSTOMER_UPDATE: '修改',
  CUSTOMER_DELETE: '删除',
  CUSTOMER_VIEW: '详情',
  CUSTOMER_IMPORT: '导入客户信息',
  // AR数显管理
  AR_DIGTAL_POOL: 'AR数显管理',
  AR_DIGTAL_ADD: '新增',
  AR_DIGTAL_UPDATE: '修改',
  AR_DIGTAL_DELETE: '删除',
  AR_DIGTAL_VIEW: '详情',
  AR_DIGTAL_IMPORT: '导入AR数显',
  AR_DIGTAL_EXPORT: '批量导出二维码',
  // 部门管理
  GROUP_MANAGE: '部门管理',
  GROUP_MANAGE_USER_ACCOUNT_GROUP: '企业部门分组',
  GROUP_MANAGE_USER_ACCOUNT_GROUP_ADD: '新增',
  GROUP_MANAGE_USER_ACCOUNT_GROUP_UPDATE: '修改',
  GROUP_MANAGE_USER_ACCOUNT_GROUP_DELETE: '删除',
  GROUP_MANAGE_USER_ACCOUNT_GROUP_VIEW: '详情',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP: '平台部门分组',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_ADD: '新增',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_UPDATE: '修改',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_DELETE: '删除',
  SCOPE_GROUP_MANAGE_USER_ACCOUNT_GROUP_VIEW: '详情',
  // 信息管理
  INFO_MANAGE: '信息管理',
  INFO_MANAGE_ME: '个人信息管理',
  INFO_MANAGE_ME_CHANGE_PASSWORD: '修改密码',
  INFO_MANAGE_COMPANY: '企业信息管理',
  INFO_MANAGE_COMPANY_UPDATE: '修改企业信息',
  INFO_MANAGE_COMPANY_UPDATE_INVOICE: '修改发票信息',
  COMPANY_QRCODE_EXPORT: '导出企业码',
  // 网关管理
  GATEWAY_EQUIPMENT: '网关管理',
  GATEWAY_EQUIPMENT_RESET: '重置密码',
  GATEWAY_EQUIPMENT_EXPORT: '批量导出二维码',
  GATEWAY_EQUIPMENT_OP_LOG: '操作日志',
  // 消息管理
  MESSAGE_MANAGE: '消息管理',
  MESSAGE_MANAGE_ADD: '新增',
  MESSAGE_UPDATE: '修改',
  MESSAGE_DELETE: '删除',
  MESSAGE_VIEW: '详情',
  MESSAGE_ROLLBACK: '撤回',
  // 版本管理
  VERSION_MANAGE_APK: '安卓版本管理',
  VERSION_MANAGE_APK_ADD: '新增',
  VERSION_MANAGE_APK_UPDATE: '修改',
  VERSION_MANAGE_APK_DELETE: '删除',
  VERSION_MANAGE_APK_VIEW: '详情',
  VERSION_MANAGE_PC: 'PC版本管理',
  VERSION_MANAGE_PC_ADD: '新增',
  VERSION_MANAGE_PC_UPDATE: '修改',
  VERSION_MANAGE_PC_DELETE: '删除',
  VERSION_MANAGE_PC_VIEW: '详情',
  // 设备激活码
  TERMINAL_MANAGE: '终端设备管理',
  TERMINAL_ACTIVATION_CODE_MANAGE: '设备激活码管理',
  TERMINAL_ACTIVATION_CODE_MANAGE_ADD: '终端设备激活码新增',
  TERMINAL_ACTIVATION_CODE_MANAGE_UPDATE: '终端设备激活码修改',
  TERMINAL_ACTIVATION_CODE_MANAGE_EXPORT: '终端设备管理批量导出',
  // 验证码
  SEND_MESSAGE_AUTH_CODE_000003: '手机号验证码已达后去上限',
  // 文档管理
  DOCUMENT_MANAGE: '文档管理',
  DOCUMENT_MANAGE_FIND: '查找',
  DOCUMENT_MANAGE_ADD: '新增',
  DOCUMENT_DELETE: '删除',
  DOCUMENT_IN_USE: '上架',
  DOCUMENT_SOLD_OUT: '下架',
  DOCUMENT_DOWNLOAD: '下载',
  // 产品服务开通
  PRODUCT_SERVICE_MANAGE: '产品服务开通',
  PRODUCT_SERVICE_MANAGE_FIND: '查找',
  PRODUCT_SERVICE_MANAGE_UPDATE: '新增',
  PRODUCT_SERVICE_EDIT: '修改',
  // 客户管理 新用户注册
  REGISTER_COMPANY_MANAGE: '企业申请受理',
  REGISTER_COMPANY_INFO: '详情',
  REGISTER_COMPANY_ALLOT_SP: '分配服务商',
  TRACKING_NOTES: '跟踪备注',
  REGISTER_COMPANY_GOON: '继续注册',
  REGISTER_COMPANY_CLOSE: '关闭注册',
  // 账号密钥授权
  KEY_AUTHORIZATION_MANAGE: '密钥授权管理',
  KEY_AUTHORIZATION_MANAGE_FIND: '查询',
  KEY_AUTHORIZATION_MANAGE_ADD: '新增',
  KEY_AUTHORIZATION_MANAGE_REVOCATION: '撤回',
  KEY_AUTHORIZATION_MANAGE_DISABLE: '禁用',
  KEY_AUTHORIZATION_MANAGE_RENEWAL: '续期',
  KEY_AUTHORIZATION_MANAGE_ACTIVE: '激活',

  TERMINAL_DEVICE_MANAGE: '终端设备管理',
  TERMINAL_DEVICE_FIND: '设备查找',
  TERMINAL_WIFI_MANAGE: '终端WIFI管理',
  TERMINAL_WIFI_FIND: '终端WIFI查找',
  TERMINAL_WIFI_ADD: '终端WIFI新增',
  TERMINAL_WIFI_DELETE: '终端WIFI删除',
  TERMINAL_WIFI_UPDATE: '终端WIFI修改',

  LICENCE_MANAGE: '许可证管理',
  LICENCE_VIEW: '许可证列表',
  LICENCE_FIND: '许可证查询',
  LICENCE_DISABLE: '许可证禁用',
  LICENCE_RECOVER: '许可证启用',
  LICENCE_CREATE: '许可证创建',

  // 权限管理
  PERMISSION_MANAGE: '权限管理',
  ACCOUNT_PERMISSION_MANAGE: '用户权限管理',
  ACCOUNT_PERMISSION_SETTING: '用户权限设定',
  COMPANY_PERMISSION_MANAGE: '企业权限管理',
  COMPANY_PERMISSION_SETTING: '企业权限设定',

  // 点巡检权限管理
  SPOT_INSPECTION_PERMISSION_MANAGE: '点巡检权限管理',
  SPOT_INSPECTION_ACCOUNT_PERMISSION_MANAGE: '点巡检用户权限管理',
  SPOT_INSPECTION_ACCOUNT_PERMISSION_SETTING: '点巡检用户权限设定',
  SPOT_INSPECTION_COMPANY_PERMISSION_MANAGE: '点巡检企业权限管理',
  SPOT_INSPECTION_COMPANY_PERMISSION_SETTING: '点巡检企业权限设定',

  // 装配指导权限管理
  ASSEMBLY_INSTRUCTION_PERMISSION_MANAGE: '装配指导权限管理',
  ASSEMBLY_INSTRUCTION_ACCOUNT_PERMISSION_MANAGE: '装配指导用户权限管理',
  ASSEMBLY_INSTRUCTION_ACCOUNT_PERMISSION_SETTING: '装配指导用户权限设定',
  ASSEMBLY_INSTRUCTION_COMPANY_PERMISSION_MANAGE: '装配指导企业权限管理',
  ASSEMBLY_INSTRUCTION_COMPANY_PERMISSION_SETTING: '装配指导企业权限设定',

  PRACTICAL_TRAINING_EDUCATION_PERMISSION_MANAGE: '实训教育权限管理',
  PRACTICAL_TRAINING_EDUCATION_ACCOUNT_PERMISSION_MANAGE: '实训教育用户权限管理',
  PRACTICAL_TRAINING_EDUCATION_ACCOUNT_PERMISSION_SETTING: '实训教育用户权限设定',
  PRACTICAL_TRAINING_EDUCATION_COMPANY_PERMISSION_MANAGE: '实训教育企业权限管理',
  PRACTICAL_TRAINING_EDUCATION_COMPANY_PERMISSION_SETTING: '实训教育企业权限设定',

  SAFETY_MANAGEMENT_PERMISSION_MANAGE: '安全管理权限管理',
  SAFETY_MANAGEMENT_ACCOUNT_PERMISSION_MANAGE: '安全管理用户权限管理',
  SAFETY_MANAGEMENT_ACCOUNT_PERMISSION_SETTING: '安全管理用户权限设定',
  SAFETY_MANAGEMENT_COMPANY_PERMISSION_MANAGE: '安全管理企业权限管理',
  SAFETY_MANAGEMENT_COMPANY_PERMISSION_SETTING: '安全管理企业权限设定',


    //
  COMPANY_APPLY_JOIN_ACCOUNT_LIST: '用户加入申请受理',
  COMPANY_APPLY_JOIN_ACCOUNT_AGREE: '同意',
  COMPANY_APPLY_JOIN_ACCOUNT_REFUSE: '拒绝',
  COMPANY_CREATE_COMPANY_INVITE_CODE: '编辑邀请码',

  //企业位置标签管理
  COMPANY_AREA_LABEL_MANAGE: '企业位置标签',
  COMPANY_AREA_LABEL_MANAGE_AREA: '区域管理',
  COMPANY_AREA_LABEL_MANAGE_AREA_ADD: '新增',
  COMPANY_AREA_LABEL_MANAGE_AREA_DEL: '删除',
  COMPANY_AREA_LABEL_MANAGE_AREA_UPDATE: '编辑',
  COMPANY_AREA_LABEL_MANAGE_AREA_VIEW: '详情',
  COMPANY_AREA_LABEL_MANAGE_AREA_EXCEL_IMPORT: '批量导入',
  COMPANY_AREA_LABEL_MANAGE_LABEL: '标签管理',
  COMPANY_AREA_LABEL_MANAGE_LABEL_ADD: '新增',
  COMPANY_AREA_LABEL_MANAGE_LABEL_DEL: '删除',
  COMPANY_AREA_LABEL_MANAGE_LABEL_UPDATE: '编辑',
  COMPANY_AREA_LABEL_MANAGE_LABEL_VIEW: '详情',
  COMPANY_AREA_LABEL_MANAGE_LABEL_EXCEL_IMPORT: '批量导入',

  PLATFORM_DATA_API_MANAGE: '平台数据接口管理',
  PLATFORM_API_MANAGE: '平台接口管理',
  PLATFORM_API_MANAGE_ADD: '新增',
  PLATFORM_API_MANAGE_DEL: '删除',
  PLATFORM_API_MANAGE_UPDATE: '编辑',
  PLATFORM_API_MANAGE_VIEW: '详情',
  PLATFORM_DATA_MANAGE: '平台数据管理',
  PLATFORM_DATA_MANAGE_ADD: '新增',
  PLATFORM_DATA_MANAGE_DEL: '删除',
  PLATFORM_DATA_MANAGE_UPDATE: '编辑',
  PLATFORM_DATA_MANAGE_VIEW: '详情',

  COMPANY_DATA_API_MANAGE: '企业数据接口管理',
  COMPANY_API_MANAGE: '企业接口管理',
  COMPANY_API_MANAGE_ADD: '新增',
  COMPANY_API_MANAGE_DEL: '删除',
  COMPANY_API_MANAGE_UPDATE: '编辑',
  COMPANY_API_MANAGE_VIEW: '详情',
  COMPANY_DATA_MANAGE: '企业数据管理',
  COMPANY_DATA_MANAGE_ADD: '新增',
  COMPANY_DATA_MANAGE_DEL: '删除',
  COMPANY_DATA_MANAGE_UPDATE: '编辑',
  COMPANY_DATA_MANAGE_VIEW: '详情',

  SENSOR_DEVICE_MANAGE: '传感器设备管理',
  SENSOR_DEVICE_MANAGE_VIEW: '查看',
  SENSOR_DEVICE_MANAGE_ADD:'新增',
  SENSOR_DEVICE_MANAGE_UPDATE:'编辑',
  SENSOR_DEVICE_MANAGE_DEL:'删除',

  WORK_FLOW_STEP_TEMPLATE_MANAGE: '模板管理',
  WORK_FLOW_STEP_TEMPLATE_MANAGE_VIEW: '查看',
  WORK_FLOW_STEP_TEMPLATE_MANAGE_ADD: '新增',
  WORK_FLOW_STEP_TEMPLATE_MANAGE_DEL: '删除',
  WORK_FLOW_STEP_TEMPLATE_MANAGE_UPDATE: '编辑',
  WORK_FLOW_STEP_TEMPLATE_MANAGE_EXPORT: '导出',
  WORK_FLOW_STEP_TEMPLATE_MANAGE_IMPORT: '导入',

  //BI大屏管理
  BI_MANAGE:'BI大屏管理',
  BI_MANAGE_ADD:'新增',
  BI_MANAGE_UPDATE:'更新',
  BI_MANAGE_DELETE:'删除',
  BI_MANAGE_VIEW:'查询',
  BI_MANAGE_DISABLE:'禁用',
  BI_MANAGE_RECOVER:'恢复',

  //
  WORK_FLOW_STEP_TEMPLATE_ASSIGN_MANAGE: '模板分配管理',
  WORK_FLOW_STEP_TEMPLATE_ASSIGN_MANAGE_ASSIGN: '模板分配',

  RECOGNITION_ARITHMETIC_MANAGE:'识别算法管理',
  RECOGNITION_ARITHMETIC_MANAGE_ADD:'新增',
  RECOGNITION_ARITHMETIC_MANAGE_UPDATE:'更新',
  RECOGNITION_ARITHMETIC_MANAGE_VIEW:'查看',
  RECOGNITION_ARITHMETIC_MANAGE_DEL:'删除',
}
