// 省市区数据来源
import 'vue-area-linkage/dist/index.css'
import fakeClickOutSide from '../../utils/fackClickOutSide'

export default {
  mixins: [fakeClickOutSide],
  data() {
    let checkPwd = (rule,value,callback) => {
      if(value.trim().length==0){
        callback(new Error(`${this.$t('reg.pleaseinputpass')}`))
      }else if(value !=this.ruleForm.password){
        callback(new Error(`${this.$t('reg.confirmCheck')}`))
      }else{
        callback()
      }
    }
    return {
      langData: [
      {
        value: 'zhCN',
        label: this.$t('base.zhCN')
      },
      {
        value: 'en',
        label: this.$t('base.en')
      },
      {
        value: 'ja',
        label: this.$t('base.ja')
      }],
      lang: this.$util.getLocalStorage('lang') || 'zhCN',
      ruleForm:{
        phone: '',
        authCode: '',
        authCodeSeqNo: '',
        isAgree: '',
        password:'',
        loginName: '',
        name: '',
        confirmpass: '',
        companyUid: '',
        inviteCode: '',
        companyName: '',
      },
      stepTop:true,
      stepOne: true,
      stepTwo: false,
      stepThree: false,
      stepNum:1,
      rules: {
        confirmpass: [{validator: checkPwd,required: true,trigger: "blur",}],
      },
      loading: false,
      flag: false,
      flagconfirm: false,
      count: '',
      timer:null ,
      codeShow: true,
      listServerData: null,
    }
  },
  watch: {
    'ruleForm.phone'(val) {
      this.ruleForm.phone = val.replace(/[^0-9.]/g,'')
    },
    'ruleForm.openAccountBankAccountForInvoice'(val) {
      this.ruleForm.openAccountBankAccountForInvoice = val.replace(/[^0-9.]/g,'')
    },
    'ruleForm.isAgree'(val) {
      if(val) {
        this.$refs['dataForm'].fields[2].validateMessage = ''
        this.$refs['dataForm'].fields[2].validateState = 'success'
      }
    },
  },
  mounted() {
    // this.goThree()
  },
  methods: {
    changeLang(lang) {
      this.lang = lang
      this.$i18n.locale = lang
      this.$util.setLocalStorage('lang', lang)
    },
    checkLoginName(val){
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.checkLoginName,
        data:{
          loginName: val
        }
      }).then((data) => {
        this.loginNameExist = false
        document.getElementById('loginNameID').textContent = ''
        console.log(data)
      }).catch(err =>{
        this.loginNameExist = true
        document.getElementById('loginNameID').textContent = this.$t(`serverCode.${err.msg}`)
        return false
      })
    },
    getCode() {
      if(!this.ruleForm.phone) {
        this.$element.showMsg(this.$t('base.pleaseEnter') + this.$t('accountReg.mobile'), 'warning')
        return
      }
      this.$ajax({
        url: '/core' + this.$api.coreSendCode,
        data:{
          mobile: this.ruleForm.phone,
          countryCode:''
        }
      }).then((data) => {
        this.ruleForm.authCodeSeqNo = data
      })
      const TIME_COUNT = 60  //倒计时60秒
      if(!this.timer) {
        this.count = TIME_COUNT
        this.codeShow = false
        this.timer = setInterval(() => {
          if(this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.codeShow = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    checkCode(){
      return this.$ajax({
        url: '/core' + this.$api.checkMobile,
        data:{
          mobile: this.ruleForm.phone,
          seqNo:this.ruleForm.authCodeSeqNo,
          code:this.ruleForm.authCode
        }
      })
    },
    getBack() {
      this.$router.push({ path: '/login'})
    },
    // 第一步
    getRegOne() {
      this.$refs.dataForm.validate((valid) => {
        if(! this.ruleForm.isAgree) {
          this.$refs['dataForm'].fields[2].validateMessage = this.$t('base.pleaseSelect') + this.$t('accountReg.isAgree')+ '《' + this.$t('accountReg.serviceAgreement') +'》' + '《' + this.$t('accountReg.privacyPolicy') +'》'
          this.$refs['dataForm'].fields[2].validateState = 'error'
          return false
        }
        if(valid) {
          this.checkCode().then((data) => {
            console.log(data)
            this.stepOne = false
            this.stepTwo = true
            this.stepNum = 2
            this.timer = null
          }).catch((err) => {
            if(err.msg === 'SEND_MESSAGE_AUTH_CODE_000002') {
              this.$refs['dataForm'].fields[1].validateMessage = this.$t(`serverCode.${err.msg}`)
              this.$refs['dataForm'].fields[1].validateState = 'error'
            }
          })
        } else {
          return false
        }
      })
    },
    checkRepeat(fields) {
      console.log(this.$util.getLocalStorage('apiprefix'))
      return this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.checkRepeat,
        data: fields
      })
    },
    goReg() {
      this.$refs.dataFormTwo.validate((valid) => {
        if(valid) {
          this.$ajax({
            url: '/core' + this.$api.userRegister,
            data:{
              mobile: this.ruleForm.phone,
              accountId: this.ruleForm.loginName,
              name: this.ruleForm.name,
              password: this.ruleForm.password,
            }
          }).then((data) => {
            this.$element.showConfirm({content: this.$t('accountReg.successReg')}).then(() => {
              this.goThree()
            }).catch(() => {
              this.$router.push('/login')
            })
          }).catch((err) => {
            if(err.msg === 'REGISTER_ACCOUNT_000002') {
              this.$element.showConfirm({content: this.$t(`serverCode.${err.msg}`)}).then(() => {
                this.goThree()
              }).catch(() => {
                this.$router.push('/login')
              })
            }
          })
        } else {
          return false
        }
      })
    },
    goThree() {
      this.stepOne = false
      this.stepTwo = false
      this.stepThree = true
      this.stepNum = 3
    },
    // 服务商名称
    findServiceName(e,callback) {
      var that = this
      that.ruleForm.companyName = e
      that.$ajax({
        url: '/core' + this.$api.findByNameOrMark,
        data:{
          name: e,
        }
      }).then((data) => {
        console.log(data)
        this.listServerData = data
        if (this.listServerData.length === 0) {
          this.listServerData.push({
            id: '-1',
            value: '无匹配结果'
          })
          callback(this.listServerData)
        } else {
          this.listServerData = this.listServerData.map(item=>{
            return {
              value:`${item.name}`,
              id: `${item.uid}`
            }
          })
          callback(this.listServerData)
        }
      }).catch((error)=>{
        console.log(error)
      })
    },
    handleSelectName(item) {
      console.log(item)
      this.ruleForm.companyUid = item.id + ''
      this.ruleForm.companyName = item.value
    },
    joinCompany() {
      this.$refs.dataFormThree.validate((valid) => {
        if (valid) {
          this.$ajax({
            url: '/core' + this.$api.userLogin,
            data: {loginName: this.ruleForm.loginName, password: this.ruleForm.password},
          }).then(res => {
            this.$ajax({
              url: '/core' + this.$api.applyJoinCompany,
              data: {
                accountUid: res.uid,
                companyUid: this.ruleForm.companyUid,
                inviteCode: this.ruleForm.inviteCode,
              },
              headers: {
                subject_token: res.token,
              },
            }).then(res1 => {
              this.$element.showAlert({content: this.$t('accountReg.applySuccess')})
            })
          })
        }
      })
    },
  }
}
